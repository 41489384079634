import React from 'react';
import PropTypes from 'prop-types';
import { Panel as BasePanel } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import styles from './Panel.module.css';

const cx = classnames.bind(styles);

export default function Panel({ children, gradient, className, padded, ...rest }) {
  return (
    <BasePanel
      className={cx('panel', { gradient, padded }, className)}
      padded={padded}
      {...rest}
    >
      {children}
    </BasePanel>
  );
}

Panel.propTypes = {
  children: PropTypes.node,
  gradient: PropTypes.bool,
  color: PropTypes.oneOf([
    'red',
    'evergreen',
    'white',
    'gold',
    'grey',
    'green',
    'blueLight',
  ]),
  padded: PropTypes.bool,
  raised: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['lg']),
  ]),
  rounded: PropTypes.bool,
  className: PropTypes.string,
};

Panel.defaultProps = {
  children: undefined,
  gradient: false,
  color: 'white',
  padded: true,
  raised: 'lg',
  rounded: false,
  className: undefined,
};
