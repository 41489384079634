import React from 'react';
import { useSelector } from 'react-redux';
import { CreditAppComplete as SuccessModal } from '@gasbuddy/react-consumer-components';
import useTracking from '../../hooks/useTracking';
import { ANALYTICS_SCREENS } from '../../constants/analytics';

function CreditAppComplete() {
  const { consumerHost } = useSelector(state => state.config);
  const { creditStatus } = useSelector(state => state.progress);

  useTracking(ANALYTICS_SCREENS.PAY_ENROLL_CREDIT_COMPLETE);

  return (
    <SuccessModal
      consumerHost={consumerHost}
      creditStatus={creditStatus}
    />
  );
}

export default CreditAppComplete;
