import { Action } from './actions';

const defaultState = {
  isFetching: false,
  error: undefined,
  consumerHost: 'www.gasbuddy.com',
  identityHost: 'iam.gasbuddy.com',
  payHost: 'pay.gasbuddy.com',
  enrollHost: 'enroll.gasbuddy.com',
  gtmId: undefined,
  localyticsKey: undefined,
  iavEnv: undefined,
  iavProviderToken: undefined,
};

export default function configReducer(state = defaultState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case Action.Config.FetchIavProviderDetailsPending:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };

    case Action.Config.FetchIavProviderDetailsCompleted:
      return {
        ...state,
        iavProviderToken: payload.iavProviderToken,
        iavEnv: payload.iavEnv,
      };

    case Action.Config.FetchIavProviderDetailsFailed:
      return {
        ...state,
        error: 'Oops! Something went wrong.',
      };

    default:
      return state;
  }
}
