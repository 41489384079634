import { connect } from 'react-redux';
import ReviewAndSubmitForm from './ReviewAndSubmitForm';
import { shipCard } from '../../reducers/actions';
import getErrorMessage from '../../../lib/utils/getErrorMessage';

function mapStateToProps({ auth, config, instruments, membership, progress }) {
  const { isShippingCard, cardShipError } = instruments;
  const { email } = auth;
  const { offeringId } = progress;
  const { stripeToken } = config;
  const { annual, monthly } = membership.premium;
  const selectedOffer = [annual, monthly].find(offer => offer.plan_offering_id === offeringId);
  const price = selectedOffer && selectedOffer.renewal_price;
  const periodType = selectedOffer && selectedOffer.period_type;

  return {
    isLoading: isShippingCard,
    email,
    error: getErrorMessage(cardShipError),
    offeringId,
    periodType,
    price,
    stripeToken,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (e, body) => {
      if (e) {
        e.preventDefault();
      }

      dispatch(shipCard(body));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewAndSubmitForm);
