export const PlusPoints = [
  'Fuel discounts go into effect once you receive and activate your card.',
  'The monthly gallon limit begins on the day you activate your card and resets the same date the following month.',
  'Your subscription renews automatically and you are free to cancel at anytime.',
];

export const PremiumPoints = [
  'Roadside assistance goes into effect 72 hours after purchase date.',
  ...PlusPoints,
];

export const PassengerVehicleDefinition = 'A passenger vehicle is a vehicle designed to carry no more than nine passengers, including the driver, and does not include motorcycles or RVs.';
export const PassengerVehicleDetail = 'If you do not drive a passenger vehicle, you can not purchase premium membership.';
