import { connect } from 'react-redux';
import CheckingAccountManual from './CheckingAccountManual';

import {
  fetchBankDetailsWithRoutingNumber,
  createBankInstrument,
  resetBankAccountError,
} from '../../reducers/actions';
import getErrorMessage from '../../../lib/utils/getErrorMessage';
import getProgramFromWallet from '../../../lib/utils/getProgramFromWallet';

const defaultErrorMsg = 'There was an error linking your bank account. Please try again later.';

function mapStateToProps({ config, bank, wallet }) {
  return {
    iavProvider: config.iavProvider,
    bankDetails: bank.details,
    detailLoading: bank.detailLoading,
    routingNumberError: getErrorMessage(bank.detailError),
    bankAccountError: getErrorMessage(bank.error, defaultErrorMsg),
    isSaving: bank.isSaving,
    program: getProgramFromWallet(wallet),
  };
}

const mapDispatchToProps = dispatch => ({
  onSubmit: (e, body) => {
    e.preventDefault();
    dispatch(createBankInstrument(body));
  },
  fetchBankDetails: body => dispatch(fetchBankDetailsWithRoutingNumber(body)),
  resetBankAccountError: () => dispatch(resetBankAccountError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckingAccountManual);
