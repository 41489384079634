import React, { useCallback } from 'react';
import { Panel, Radio, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import formatPrice from '../../../lib/utils/formatPrice';
import styles from './MembershipOption.module.css';

const cx = classnames.bind(styles);

const ShortPeriods = {
  month: 'mo',
  year: 'yr',
};

export default function MembershipOption({ id, isSelected, title, onSelect, periodType, price }) {
  const handleChange = useCallback((e) => {
    const { value } = e.target;
    onSelect(parseInt(value, 10));
  }, [onSelect]);

  return (
    <Panel className={cx('option')}>
      <Text bold className={cx('apart')}>
        <Radio
          checked={isSelected}
          className={cx('radio')}
          name="offeringId"
          onChange={handleChange}
          value={id}
        >
          <Text className={cx('label')}>{title}</Text>
        </Radio>
        {!!price && !!periodType && (
          <Text className={cx('price')} size="xl">{formatPrice(price)}/{ShortPeriods[periodType]}</Text>
        )}
      </Text>
    </Panel>
  );
}

MembershipOption.propTypes = {
  id: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  periodType: PropTypes.oneOf([
    'year',
    'month',
  ]),
  price: PropTypes.string,
};

MembershipOption.defaultProps = {
  isSelected: false,
  onSelect: () => {},
  periodType: undefined,
  price: undefined,
};
