import React from 'react';
import { Button, FlexGrid, Header, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import Panel from '../Panel';
import styles from './PremiumPanel.module.css';
import useResponsiveImage from '../../hooks/useResponsiveImage';

const cx = classnames.bind(styles);

function PremiumPanel({ ctaHref, ctaText, description, header, image }) {
  const responsiveImage = useResponsiveImage(image);

  return (
    <Panel padded={false} style={{ marginTop: '1.5em' }}>
      <FlexGrid>
        <FlexGrid.Column tablet={4}>
          <div
            className={cx('image')}
            style={{ backgroundImage: `url(${responsiveImage})` }}
          />
        </FlexGrid.Column>
        <FlexGrid.Column tablet={7}>
          <div className={cx('content')}>
            <Header as="h2" snug style={{ lineHeight: 1 }}>{header}</Header>
            <br />
            <Text as="p">{description}</Text>
            <br />
            <Button as="a" href={ctaHref} primary wide>{ctaText}</Button>
          </div>
        </FlexGrid.Column>
      </FlexGrid>
    </Panel>
  );
}

PremiumPanel.propTypes = {
  ctaHref: PropTypes.string,
  ctaText: PropTypes.string,
  description: PropTypes.string,
  header: PropTypes.string,
  image: PropTypes.string,
};

PremiumPanel.defaultProps = {
  ctaHref: '/premium',
  ctaText: 'Join Premium',
  description: 'You\'ll always save at least 20¢/gal on your first 50 gallons each month (5¢/gal after that) and get 24 hr roadside assistance from Allstate.',
  header: 'Save up to 40¢/gal with a Premium Membership!',
  image: 'https://static.gasbuddy.com/web/pay/panels/fuel-pumping.png',
};

export default PremiumPanel;
