export default function capitalize(str, allWords = false) {
  if (typeof str === 'string') {
    if (allWords) {
      const words = str.split(' ').map(word => capitalize(word));
      return words.join(' ');
    }

    return `${str[0].toUpperCase()}${str.substring(1)}`;
  }
  return str;
}
