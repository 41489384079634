import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GasBackBankTransferForm from './GasBackBankTransferForm';
import {
  fetchBankDetailsWithRoutingNumber,
  updateGasBackTransferDetails,
} from '../../reducers/actions';
import getErrorMessage from '../../../lib/utils/getErrorMessage';

function mapStateToProps({ gasback, bank, auth, config }) {
  const { balance } = gasback.transfer;
  return {
    balance,
    detailLoading: bank.detailLoading,
    routingNumberError: getErrorMessage(bank.detailError, 'There was an error verifying routing number.'),
    bankAccountError: getErrorMessage(bank.error, 'There was an error linking your bank account.'),
    recipientEmail: auth.email,
    isSaving: bank.isSaving,
    consumerHost: config.consumerHost,
  };
}

const mapDispatchToProps = dispatch => ({
  onSubmit: (e, body) => {
    e.preventDefault();
    dispatch(updateGasBackTransferDetails(body));
  },
  fetchBankDetails: body => dispatch(fetchBankDetailsWithRoutingNumber(body)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GasBackBankTransferForm));
