export default function getMessageFromDeliverability(deliverability) {
  const cardDeliveryMessage = 'You can continue using this address for enrollment, but we might not be able to guarantee an accurate card delivery.';
  switch (deliverability) {
    case 'unknown':
    case 'undeliverable':
      return 'This does not appear to be a valid, deliverable shipping address. Please correct or modify the address you have provided.';
    case 'secondary_invalid':
    case 'secondary_unit':
      return `We weren't able to match the unit or floor number to any deliverable addresses. ${cardDeliveryMessage}`;
    case 'secondary_missing':
      return `This address may require a floor, apartment, suite, or unit number. ${cardDeliveryMessage}`;
    default:
      return undefined;
  }
}
