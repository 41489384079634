import { Button, FlexGrid, Header, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styles from './FlowContainer.module.css';

const cx = classnames.bind(styles);

// TODO: Replace this with the generic Actions component
function Actions({
  children,
  ...rest
}) {
  return (
    <FlexGrid container {...rest}>
      {children}
    </FlexGrid>
  );
}

Actions.propTypes = {
  children: PropTypes.node.isRequired,
};

function ActionButton({
  className,
  link,
  children,
  mobile,
  tablet,
  desktop,
  ...rest
}) {
  return (
    <FlexGrid.Column mobile={mobile} tablet={tablet} desktop={desktop}>
      <Button link={link} className={cx('flowButton', { link }, className)} {...rest}>
        {children}
      </Button>
    </FlexGrid.Column>
  );
}

ActionButton.propTypes = {
  link: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  mobile: PropTypes.number,
  tablet: PropTypes.number,
  desktop: PropTypes.number,
};

ActionButton.defaultProps = {
  link: false,
  className: undefined,
  children: 'Submit',
  mobile: undefined,
  tablet: 6,
  desktop: 4,
};

function FlowContainer({
  className,
  backLinkHref,
  backLinkText,
  children,
  history,
  subtitle,
  title,
}) {
  const handleBackClick = useCallback((e) => {
    e.preventDefault();

    if (backLinkHref) {
      history.push(backLinkHref);
    } else {
      history.goBack();
    }
  }, [backLinkHref, history]);

  const shouldRenderHeader = !!(title || subtitle || backLinkText);

  return (
    <React.Fragment>
      {shouldRenderHeader && (
        <FlexGrid className={cx('flowContainer', className)}>
          {!!title && (
            <FlexGrid.Column tablet={8}>
              <Header as="h2" snug>{title}</Header>
              {subtitle && <Text as="p" size="lg">{subtitle}</Text>}
            </FlexGrid.Column>
          )}
          {!!backLinkText && (
            <FlexGrid.Column className={cx('backLinkContainer')} tablet={4}>
              <Button className={cx('backLink')} link onClick={handleBackClick}>
                {`< ${backLinkText}`}
              </Button>
            </FlexGrid.Column>
          )}
        </FlexGrid>
      )}
      {children}
    </React.Fragment>
  );
}

FlowContainer.propTypes = {
  className: PropTypes.string,
  backLinkHref: PropTypes.string,
  backLinkText: PropTypes.node,
  children: PropTypes.node,
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  subtitle: PropTypes.node,
  title: PropTypes.node,
};

FlowContainer.defaultProps = {
  className: undefined,
  backLinkHref: undefined,
  backLinkText: undefined,
  children: undefined,
  history: {
    goBack: () => {},
    push: () => {},
  },
  subtitle: undefined,
  title: undefined,
};

FlowContainer.Actions = Actions;

FlowContainer.Button = ActionButton;

export default FlowContainer;
