import { Form, Header, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { ANALYTICS_EVENTS, ANALYTICS_SCREENS } from '../../constants/analytics';
import GiftCardVendors, { MAX_GB_GIFT_CARD_PURCHASE } from '../../constants/giftCardVendors';
import useTracking from '../../hooks/useTracking';
import CardSelector from '../CardSelector';
import GasBackTransferError from '../GasBackTransferError';
import GasBackTransferStep from '../GasBackTransferStep';

export default function GasBackGiftCardSelect({
  balance,
  confirmationId,
  consumerHost,
  error,
  history,
  onSubmit,
  recipientEmail,
}) {
  const { trackEvent } = useTracking(ANALYTICS_SCREENS.PAY_REDEEM_GIFTCARD_SELECT);

  const [selectedCard, setSelectedCard] = useState();

  const handleSubmit = useCallback((e) => {
    trackEvent(ANALYTICS_EVENTS.PAY_REDEEM_GIFTCARD_SELECT_SUBMIT_CLICKED);

    const maxAmount = GiftCardVendors.find(v => v.id === selectedCard)?.max;

    onSubmit(e, {
      vendorId: selectedCard,
      amount: (maxAmount ? Math.min(parseFloat(balance), maxAmount, MAX_GB_GIFT_CARD_PURCHASE) : balance).toString(),
      recipientEmail,
    });
    history.push('/gasback/transfer/giftcard/details');
  }, [balance, history, onSubmit, recipientEmail, selectedCard, trackEvent]);

  const handleCancel = useCallback(() => {
    trackEvent(ANALYTICS_EVENTS.PAY_REDEEM_GIFTCARD_SELECT_CANCEL_CLICKED);
  }, [trackEvent]);

  if (confirmationId && error) {
    // There was an error processing gift card purchase
    return (
      <GasBackTransferError />
    );
  }

  if (!recipientEmail) {
    return (
      <Redirect to="/gasback/transfer" />
    );
  }

  return (
    <GasBackTransferStep
      backLinkHref="/gasback/transfer"
      backLinkText="Choose Transfer Type"
      context={{ screen: ANALYTICS_SCREENS.PAY_REDEEM_GIFTCARD_COMPLETE }}
      fluid
    >
      <Form method="post" action="/gasback/transfer/giftcard" onSubmit={handleSubmit}>
        <Header as="h3" snug>Choose your digital gift card</Header>
        <Text as="p">
          We&apos;ll send you an email with the digital gift card code.
        </Text>
        <br />
        <CardSelector>
          {GiftCardVendors.map((vendor) => {
            const disabled = vendor.min && vendor.min > parseFloat(balance);

            return (
              <CardSelector.Card
                key={vendor.id}
                disabled={disabled}
                id={vendor.id}
                text={`${vendor.displayName}`}
                subText={`($${vendor.min}-$${vendor.max})`}
                imageUrl={vendor.imageUrl}
                selected={vendor.id === selectedCard}
                onClick={setSelectedCard}
                mobile={6}
                tablet={3}
              />
            );
          })}
        </CardSelector>
        <GasBackTransferStep.Actions>
          <GasBackTransferStep.Button
            primary
            fluid
            disabled={!selectedCard}
            type="submit"
          >
            Continue
          </GasBackTransferStep.Button>
          <GasBackTransferStep.Button
            link
            as="a"
            href={`//${consumerHost}/account/savings`}
            onClick={handleCancel}
          >
            Cancel
          </GasBackTransferStep.Button>
        </GasBackTransferStep.Actions>
      </Form>
    </GasBackTransferStep>
  );
}

GasBackGiftCardSelect.propTypes = {
  balance: PropTypes.string,
  confirmationId: PropTypes.string,
  consumerHost: PropTypes.string,
  error: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  onSubmit: PropTypes.func,
  recipientEmail: PropTypes.string,
};

GasBackGiftCardSelect.defaultProps = {
  balance: '0',
  confirmationId: undefined,
  consumerHost: 'www.gasbuddy.com',
  error: undefined,
  history: {
    push: () => {},
  },
  onSubmit: () => {},
  recipientEmail: undefined,
};
