import { Constraint, Header, Image, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React from 'react';
import { ANALYTICS_SCREENS } from '../../constants/analytics';
import useTracking from '../../hooks/useTracking';
import Panel from '../Panel';

export default function NewCardSuccess({ header, renewed, subheader }) {
  useTracking(ANALYTICS_SCREENS.PAY_EXPIRED_CARD_ADDRESS_CONFIRMED);

  const nextStepsPoints = [
    'Your new card will ship in 3-5 business days.',
    'It should arrive in about 7-10 business days.',
  ];

  if (renewed) {
    nextStepsPoints.push(
      'You can start using your new card right away. No activation is necessary',
      'Your current Driver ID (PIN) will work with your new card.',
    );
  }

  return (
    <Panel>
      <Constraint desktop={10}>
        <Image
          alt="GasBuddy Pay Card"
          src="https://static.gasbuddy.com/web/pay/svg/card-tilted-shadow-v2.svg"
          width={80}
        />
        <Header as="h2">{header}</Header>
        <Text as="p" elongated size="lg" uppercase>{subheader}</Text>
        <Text as="p" bold>Here’s what happens next:</Text>
        <ul>
          {nextStepsPoints.map(text => (
            <li key={text}>{text}</li>
          ))}
        </ul>
      </Constraint>
    </Panel>
  );
}

NewCardSuccess.propTypes = {
  header: PropTypes.string,
  renewed: PropTypes.bool,
  subheader: PropTypes.string,
};

NewCardSuccess.defaultProps = {
  header: 'Success!',
  renewed: false,
  subheader: 'Your new card request is processing.',
};
