import React from 'react';
import PropTypes from 'prop-types';
import { Container, FlexGrid, Text, Radio, Header, Actions } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import styles from './DeliverableAddressConfirmation.module.css';
import AddressPropType from '../../prop-types/address';

const cx = classnames.bind(styles);
export default function DeliverableAddressConfirmation({
  providedAddress,
  suggestedAddress,
  onEditAddress,
  onContinue,
}) {
  const [shouldUseSuggestedAddress, setShouldUseSuggestedAddress] = React.useState(true);

  const handleAddressChange = ({ target }) => {
    setShouldUseSuggestedAddress(target.value === 'suggested');
  };

  const handlePrimaryButtonClick = (e) => {
    onContinue(e, shouldUseSuggestedAddress);
  };

  return (
    <Container className={cx('addressConfirmation')}>
      <Header as="h3">
        Confirm Address
      </Header>
      <FlexGrid className={cx('addressesContainer')}>
        <FlexGrid.Column tablet={6} className={cx('suggestedAddressContainer')}>
          {suggestedAddress && (
            <React.Fragment>
              <Radio name="confirmAddress" value="suggested" onChange={handleAddressChange} checked={shouldUseSuggestedAddress} />
              <Text className={cx('addressType')}>
                Recommended address:
              </Text>
              <div className={cx('address')}>
                <Text bold>
                  {suggestedAddress.line_1}<br />
                  {!!suggestedAddress.line_2 && (
                    <React.Fragment>
                      {suggestedAddress.line_2}<br />
                    </React.Fragment>
                  )}
                  {suggestedAddress.locality}, {suggestedAddress.region} {suggestedAddress.postal_code}
                </Text>
              </div>
            </React.Fragment>
          )}
        </FlexGrid.Column>
        <FlexGrid.Column tablet={6} className={cx('providedAddressContainer')}>
          <Radio name="confirmAddress" value="provided" onChange={handleAddressChange} />
          <Text className={cx('addressType')}>
            Provided address:
          </Text>
          <div className={cx('address')}>
            <Text bold>
              {providedAddress.line_1}<br />
              {!!providedAddress.line_2 && (
                <React.Fragment>
                  {providedAddress.line_2}<br />
                </React.Fragment>
              )}
              {providedAddress.locality}, {providedAddress.region} {providedAddress.postal_code}
            </Text>
          </div>
        </FlexGrid.Column>
      </FlexGrid>
      <div className={cx('disclaimer')}>
        <Text>We&apos;re recommending an address for what we think most closely matches the information you provided us.</Text>
        &nbsp;
        <Text>You can complete enrollment using your provided address, or go back and edit the address.</Text>
      </div>
      <div className={cx('ctaContainer')}>
        <Actions>
          <Actions.Button
            primary
            fluid
            onClick={handlePrimaryButtonClick}
            desktop={6}
          >
            Continue
          </Actions.Button>
          <Actions.Button
            secondary
            fluid
            onClick={onEditAddress}
            desktop={6}
          >
            Edit Address
          </Actions.Button>
        </Actions>
      </div>
    </Container>
  );
}

DeliverableAddressConfirmation.propTypes = {
  providedAddress: AddressPropType.isRequired,
  suggestedAddress: AddressPropType,
  onEditAddress: PropTypes.func,
  onContinue: PropTypes.func,
};

DeliverableAddressConfirmation.defaultProps = {
  suggestedAddress: undefined,
  onEditAddress: () => {},
  onContinue: () => {},
};
