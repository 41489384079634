import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Divider } from '@gasbuddy/react-components';
import Indicator from './Indicator';
import ProgressBar from './ProgressBar';
import styles from './StatusBar.module.css';

const cx = classnames.bind(styles);

const STEP_HEIGHT = 80;
const getElementHeight = numberOfSteps => numberOfSteps * STEP_HEIGHT;

export default function StatusBar({ steps, activeIndex, progress }) {
  const dynamicBarHeight = getElementHeight(steps.length);
  const progressHeight = ((activeIndex + 1) / steps.length) * dynamicBarHeight;

  return (
    <div className={cx('statusBarContainer')}>
      <div
        style={progress ? { height: dynamicBarHeight } : undefined}
        className={cx(
          'withLineMobile',
          {
            withProgressBar: progress,
            noShow: progress,
          },
        )}
      >
        {
          steps.map((item, index) => {
            const isActiveGreaterThanCurr = activeIndex > index;
            const isIndicatorActive = activeIndex === index || isActiveGreaterThanCurr;
            const isLast = (index + 1) === steps.length;

            return (
              <div
                key={item}
                className={cx('withLineAndIndicatorMobile')}
              >
                <div>
                  <Indicator
                    progress={progress}
                    copy={item}
                    isActive={isIndicatorActive}
                    done={isActiveGreaterThanCurr}
                  >
                    <span className={cx('indicatorWithNumber')}>{index + 1}</span>
                  </Indicator>
                </div>
                {
                  !isLast && !progress
                  && (
                    <div className={cx('linesContainer')}>
                      <div
                        className={cx(
                          'progressStepBar',
                          'line',
                          {
                            filled: isActiveGreaterThanCurr,
                          },
                        )}
                      />
                      <Divider
                        vertical
                        className={cx('fixedBar')}
                      />
                    </div>
                  )
                }
              </div>
            );
          })
        }
      </div>
      {progress
        && (
          <ProgressBar height={dynamicBarHeight} progressHeight={progressHeight} />
        )}
    </div>
  );
}

StatusBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string),
  activeIndex: PropTypes.number,
  progress: PropTypes.bool,
};

StatusBar.defaultProps = {
  steps: [],
  activeIndex: 0,
  progress: false,
};
