import { Button, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import usePlaid from '../../hooks/usePlaid';

export function withPlaid(WrappedComponent) {
  function WithPlaid({
    bankAccountError,
    iavEnv,
    iavProviderToken,
    onReject,
    createBankInstrument,
    program,
    ...rest
  }) {
    const onSuccess = useCallback((publicToken, metadata) => {
      createBankInstrument({
        instrumentType: metadata.account.subtype === 'checking' ? 'checking_account' : 'savings_account',
        iavProviderToken: publicToken,
        plaidAccountId: metadata.account_id || undefined,
        program,
      });
    }, [createBankInstrument, program]);

    const { error: plaidError, open, ready } = usePlaid({
      env: iavEnv,
      onReject,
      onSuccess,
      token: iavProviderToken,
    });

    const error = plaidError || bankAccountError;

    return (
      <React.Fragment>
        {error && (
          <Text as="p" color="orange">{error}</Text>
        )}
        <WrappedComponent onClick={open} loading={!ready} {...rest} />
      </React.Fragment>
    );
  }

  WithPlaid.propTypes = {
    bankAccountError: PropTypes.string,
    iavProviderToken: PropTypes.string.isRequired,
    iavEnv: PropTypes.string,
    onReject: PropTypes.func,
    createBankInstrument: PropTypes.func.isRequired,
    program: PropTypes.string,
  };

  WithPlaid.defaultProps = {
    bankAccountError: undefined,
    iavEnv: 'sandbox',
    onReject: () => { },
    program: undefined,
  };

  WithPlaid.displayName = `WithPlaid(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return WithPlaid;
}

function PlaidButton(props) {
  return (
    <Button primary {...props} />
  );
}

PlaidButton.propTypes = {
  children: PropTypes.node,
};

PlaidButton.defaultProps = {
  children: 'Link Checking Account',
};

export default withPlaid(PlaidButton);
