import PropTypes from 'prop-types';

const CMSImagePropType = PropTypes.oneOf([
  PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    url: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  PropTypes.string, // An image that was added then removed in the CMS returns an empty string
]);

export default CMSImagePropType;
