import { createMiddleware } from 'redux-api-middleware';
import Router from '../common/components/Router';
import ReactGTM from '../lib/utils/gtm';
import hot from './hot-loader';
import entryPoint from './renderEntryPoint';

// https://webpack.js.org/guides/public-path/#on-the-fly
// eslint-disable-next-line babel/camelcase, no-undef
__webpack_public_path__ = window.PublicPath;

const {
  config = {},
  auth = {},
} = window.PreloadedState;
const { gtmId } = config;
const { account_id: accountId } = auth;

if (gtmId) {
  ReactGTM.initialize({ id: gtmId }, accountId);
}

const render = () => entryPoint({
  router: hot(Router),
  initialState: window.PreloadedState,
  apiMiddleware: createMiddleware(),
});

render();
