import React from 'react';
import { Text } from '@gasbuddy/react-components';
import AddressPropType from '../../prop-types/address';
import isValidAddress from '../../../lib/utils/isValidAddress';

export default function ShippingAddressSummary({ address }) {
  if (isValidAddress(address)) {
    const {
      line_1: streetAddress,
      line_2: aptOrSuite,
      locality: city,
      postal_code: postalCode,
      region: state,
    } = address;

    const addressLine1 = aptOrSuite?.length ? `${streetAddress}, ${aptOrSuite}` : streetAddress;
    const addressLine2 = `${city}, ${state} ${postalCode}`;

    return (
      <Text as="p">
        {addressLine1}<br />
        {addressLine2}
      </Text>
    );
  }

  return null;
}

ShippingAddressSummary.propTypes = {
  address: AddressPropType,
};

ShippingAddressSummary.defaultProps = {
  address: undefined,
};
