import { connect } from 'react-redux';
import getErrorMessage from '../../../lib/utils/getErrorMessage';
import {
  linkSocialAccount,
  loginWithFacebook,
  loginWithGoogle,
  requestSocialConnectMagicLink,
  resetSocialStatus,
  signup,
} from '../../reducers/actions';
import SocialAuthButtons from './SocialAuthButtons';

const mapStateToProps = ({ auth, config, social, magiclinks }) => ({
  destination: auth.destination,
  facebookAppId: config.facebookAppId,
  googleAppId: config.googleAppId,
  magicLinkReturnUrl: `//${config.enrollHost}`,
  signedRequest: social.signedRequest,
  errorMessage: getErrorMessage(social.error),
  socialEmail: social.email,
  socialIsLinked: social.isLinked,
  socialNetwork: social.socialNetwork,
  socialStatus: social.status,
  isSendingMagicLink: magiclinks.isLoading,
  magicLinkStatus: magiclinks.modalVariant,
  magicLinkError: magiclinks.magicLinkError,
  isSigningUp: auth.isSigningUp,
});

function mapDispatchToProps(dispatch, { identityHost, returnUrl }) {
  return {
    onFacebookResponse: ({ signedRequest }, email, analyticsContext) => (
      dispatch(loginWithFacebook({ signedRequest, email, return_url: returnUrl }, identityHost, analyticsContext))
    ),
    onGoogleResponse: (signedRequest, analyticsContext) => (
      dispatch(loginWithGoogle({ return_url: returnUrl, signedRequest }, identityHost, analyticsContext))
    ),
    onCancel: () => {
      dispatch(resetSocialStatus());
    },
    requestSocialConnectMagicLink: (body) => {
      dispatch(requestSocialConnectMagicLink(body, identityHost));
    },
    linkAccounts: body => dispatch(linkSocialAccount(body, identityHost)),
    signup: (body, analyticsContext) => dispatch(signup(body, identityHost, analyticsContext)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialAuthButtons);
