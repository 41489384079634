import PropTypes from 'prop-types';

const InstrumentPropType = PropTypes.shape({
  instrument_id: PropTypes.string,
  instrument_name: PropTypes.string,
  instrument_type: PropTypes.oneOf([
    'network_card',
    'private_card',
    'checking_account',
    'savings_account',
    'line_of_credit',
  ]),
  provider_name: PropTypes.string,
  friendly_name: PropTypes.string,
  expiration: PropTypes.string,
  last_digits: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  bank_status: PropTypes.oneOf([
    'in_use',
    'nsf',
    'waiting_for_microdeposits',
    'waiting_for_microdeposit_confirmation',
    'waiting_for_secondary_verification',
  ]),
  logo_url: PropTypes.string,
});

export default InstrumentPropType;
