import { connect } from 'react-redux';
import Screen from './CardActivationComplete';

function mapStateToProps({ config }) {
  return {
    consumerHost: config.consumerHost,
  };
}

const CardActivationComplete = connect(mapStateToProps)(Screen);
export default CardActivationComplete;
