import { connect } from 'react-redux';
import GasBackBankTransferComplete from './GasBackBankTransferComplete';
import getBankAccount from '../../../lib/utils/getBankAccount';

function mapStateToProps({ auth, config, gasback, wallet }) {
  const bankAccount = getBankAccount(wallet.instruments);

  return {
    consumerHost: config.consumerHost,
    email: auth.email,
    lastFour: bankAccount.last_digits,
    transferAmount: gasback.transfer.balance,
  };
}

export default connect(mapStateToProps)(GasBackBankTransferComplete);
