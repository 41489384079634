import { connect } from 'react-redux';
import GasBackExportList from './GasBackExportList';

function mapStateToProps({ gasback }) {
  const { balance } = gasback.transfer;

  return {
    balance,
  };
}

export default connect(mapStateToProps)(GasBackExportList);
