import { CREDIT_APPLICATION_STATUS } from '@gasbuddy/react-consumer-components';
import { differenceInCalendarDays } from 'date-fns';
import { getCreditCard } from './utils/cardInstruments';

const isCreditTag = tag => tag.tag_type === 'credit_app_result';
const INDEFINITIVE_STATUSES = [CREDIT_APPLICATION_STATUS.Pending];
const DEFINITIVE_STATUSES = [CREDIT_APPLICATION_STATUS.Approved, CREDIT_APPLICATION_STATUS.Declined];
const APPLIED_STATUSES = INDEFINITIVE_STATUSES.concat(DEFINITIVE_STATUSES);

export const APP_EXPIRATION = 90;

export const SUCCESS_DISPLAY_DURATION = 1;

export const PENDING_DISPLAY_DURATION = 14;

export function didCompleteApplication(creditStatus) {
  return APPLIED_STATUSES.includes(creditStatus);
}

const isValidTag = tag => (
  tag.value === CREDIT_APPLICATION_STATUS.Approved
  || differenceInCalendarDays(new Date(), new Date(tag.updated_at)) < APP_EXPIRATION
);

export function getCreditStatus(tags, wallet) {
  const validTags = tags.filter(isValidTag);
  const tag = validTags.find(isCreditTag);
  let creditStatus;
  let daysOld;

  if (getCreditCard(wallet?.instruments)) {
    creditStatus = CREDIT_APPLICATION_STATUS.Approved;
  } else if (tag) {
    creditStatus = tag.value;
  }

  if (tag) {
    daysOld = differenceInCalendarDays(new Date(), new Date(tag.updated_at));
  }

  // Show the user the result if they were declined, pending, or recently applied and approved
  const shouldShowStatus = (INDEFINITIVE_STATUSES.includes(creditStatus) && daysOld < PENDING_DISPLAY_DURATION)
    || (DEFINITIVE_STATUSES.includes(creditStatus) && daysOld < SUCCESS_DISPLAY_DURATION);

  return {
    creditStatus,
    shouldShowStatus,
    shouldPrescreen: !validTags.length || !APPLIED_STATUSES.includes(creditStatus),
  };
}
