import { Action } from './actions';
import SocialStatus from '../constants/socialStatus';

export const defaultState = {
  isChecking: false,
  isLinking: false,
  isLinked: false,
  status: SocialStatus.Unknown,
  email: undefined,
  memberId: undefined,
  network: undefined,
  error: undefined,
};

export default function socialReducer(state = defaultState, action) {
  const { payload, meta } = action;

  switch (action.type) {
    case Action.Auth.SocialAuthPending:
      return {
        ...state,
        signedRequest: meta.signedRequest,
      };

    case Action.Auth.SocialAuthCompleted:
      return {
        ...state,
        status: payload.code,
        email: payload.socialEmail,
        memberId: payload.socialMemberId,
        error: payload.message && {
          message: payload.message,
        },
        network: payload.socialNetwork,
      };

    case Action.Auth.SocialStatusReset:
      return {
        isChecking: false,
        status: SocialStatus.Unknown,
        email: undefined,
        memberId: undefined,
        network: undefined,
      };

    case Action.Auth.SocialLinkPending:
      return {
        ...state,
        isLinking: true,
      };

    case Action.Auth.SocialLinkDone:
      return {
        ...state,
        isLinking: false,
        isLinked: true,
      };

    case Action.Auth.SocialLinkFailed:
      return {
        ...state,
        isLinking: false,
        isLinked: false,
        error: {
          message: payload.message,
        },
      };

    default:
      return { ...state };
  }
}
