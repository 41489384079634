import { combineReducers } from 'redux';
import auth from './auth';
import bank from './bank';
import config from './config';
import features from './features';
import gasback from './gasback';
import geo from './geo';
import instruments from './instruments';
import magiclinks from './magiclinks';
import membership from './membership';
import payCard from './payCard';
import profile from './profile';
import progress from './progress';
import shippingAddress from './shipping-address';
import social from './social';
import wallet from './wallet';

export default combineReducers({
  auth,
  bank,
  config,
  features,
  gasback,
  geo,
  instruments,
  magiclinks,
  membership,
  payCard,
  profile,
  progress,
  shippingAddress,
  social,
  wallet,
});
