import { connect } from 'react-redux';
import ReturnToSenderFlow from './ReturnToSenderFlow';

function mapStateToProps({ payCard, wallet }) {
  const { isOverdue, wasReshipped } = payCard;
  const { shipping_address: address } = wallet.status;

  return {
    address,
    isOverdue,
    wasReshipped,
  };
}

export default connect(mapStateToProps)(ReturnToSenderFlow);
