import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { Checkbox, Text, Tooltip } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import React from 'react';
import PropTypes from 'prop-types';
import { PassengerVehicleDefinition, PassengerVehicleDetail } from '../../constants/membership';
import styles from './PassengerVehicleCheckbox.module.css';

const cx = classnames.bind(styles);

export default function PassengerVehicleCheckbox({ onChange }) {
  return (
    <div className={cx('container')}>
      <Checkbox
        className={cx('checkbox')}
        id="passengerVehicle"
        label={(
          <Text>
            I drive a passenger vehicle&nbsp;&nbsp;
          </Text>
        )}
        name="passengerVehicle"
        onChange={onChange}
      />
      <Tooltip // We don't want clicking the tooltip to trigger the checkbox
        content={(
          <Text>
            {PassengerVehicleDefinition}
            <br />
            <br />
            {PassengerVehicleDetail}
          </Text>
        )}
        icon={faQuestion}
      />
    </div>
  );
}

PassengerVehicleCheckbox.propTypes = {
  onChange: PropTypes.func,
};

PassengerVehicleCheckbox.defaultProps = {
  onChange: () => {},
};
