import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import styles from './LineThrough.module.css';

const cx = classnames.bind(styles);

export default function LineThrough({ children, uppercase, className, ...rest }) {
  return (
    <Text className={cx('container', className)} {...rest}>
      <Divider className={cx('divider')} />
      <Text className={cx('text')} uppercase={uppercase}>{children}</Text>
      <Divider className={cx('divider')} />
    </Text>
  );
}

LineThrough.propTypes = {
  className: PropTypes.string,
  uppercase: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
};

LineThrough.defaultProps = {
  className: '',
  uppercase: false,
  children: undefined,
};
