import { connect } from 'react-redux';
import MembershipUpgrade from './MembershipUpgrade';
import { upgradeMembership } from '../../reducers/actions';
import getErrorMessage from '../../../lib/utils/getErrorMessage';
import PayPrograms from '../../constants/payPrograms';

function mapStateToProps({ auth, config, membership, progress }, { program }) {
  const { email } = auth;
  const { stripeToken } = config;
  const { isUpgrading, upgradeError } = membership;
  const { offeringId } = progress;
  const {
    plus: {
      annual: annualPlusOffer,
      monthly: monthlyPlusOffer,
    },
    premium: {
      annual: annualPremiumOffer,
      monthly: monthlyPremiumOffer,
    },
  } = membership;
  let options = [];

  if (program === PayPrograms.Plus) {
    options = [
      monthlyPlusOffer,
      annualPlusOffer,
    ];
  } else if (program === PayPrograms.Premium) {
    options = [
      monthlyPremiumOffer,
      annualPremiumOffer,
    ];
  }

  return {
    email,
    error: getErrorMessage(upgradeError),
    offeringId: offeringId || options[0]?.plan_offering_id,
    options,
    stripeToken,
    isLoading: isUpgrading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (e, body) => {
      if (e) {
        e.preventDefault();
      }
      dispatch(upgradeMembership(body));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipUpgrade);
