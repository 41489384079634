import PropTypes from 'prop-types';
import AddressPropType from './address';

export const DeliverabilityPropType = PropTypes.oneOf([
  'deliverable',
  'unknown',
  'undeliverable',
  'secondary_missing',
  'secondary_invalid',
  'secondary_unit',
]);

export const ShippingAddressPropType = PropTypes.shape({
  deliverability: DeliverabilityPropType,
  address: AddressPropType,
  original_address_deliverability_token: PropTypes.string,
});
