import { useCallback, useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { ANALYTICS_EVENTS, ANALYTICS_SCREENS } from '../constants/analytics';
import useQueryParam from './useQueryParam';
import useTracking from './useTracking';

function useCurrentHref() {
  // TODO This should build an href server-side too, which will require context
  return typeof window !== 'undefined' ? window.location.href : null;
}

export default function usePlaid({ env, onReject, onSuccess, token } = {}) {
  const { trackEvent, trackPageView } = useTracking();
  const isOAuthRedirect = useQueryParam('oauth_state_id');
  const currentHref = useCurrentHref();
  const storedToken = typeof window !== 'undefined' && localStorage.getItem('link_token');

  useEffect(() => {
    // If this is a new session, store the token in local storage for return from OAuth
    if (!isOAuthRedirect) {
      localStorage.setItem('link_token', token);
    }
  }, [isOAuthRedirect, token]);

  const onPlaidEvent = useCallback((eventName, metadata) => {
    switch (eventName) {
      case 'OPEN': {
        return trackPageView(ANALYTICS_SCREENS.PAY_ENROLL_BANK_SEARCH, metadata);
      }
      case 'HANDOFF': {
        return trackEvent(ANALYTICS_EVENTS.PAY_ENROLL_BANK_SEARCH_COMPLETE, metadata);
      }
      case 'EXIT': {
        return trackEvent(ANALYTICS_EVENTS.PAY_ENROLL_BANK_SEARCH_EXIT, metadata);
      }
      case 'ERROR': {
        return trackEvent(ANALYTICS_EVENTS.PAY_ENROLL_BANK_SEARCH_ERROR, metadata);
      }
      default: {
        return null;
      }
    }
  }, [trackEvent, trackPageView]);

  const onPlaidLeave = useCallback((err, meta) => {
    if (meta?.status === 'institution_not_found' && onReject) {
      onReject();
    }
  }, [onReject]);

  const plaidConfig = {
    clientName: 'Pay with GasBuddy',
    env,
    onExit: onPlaidLeave,
    onEvent: onPlaidEvent,
    onSuccess,
    product: ['auth'],
    token: isOAuthRedirect ? storedToken : token,
  };

  if (isOAuthRedirect) {
    // receivedRedirectUri must include the query params
    plaidConfig.receivedRedirectUri = currentHref;
  }

  const { error, open, ready } = usePlaidLink(plaidConfig);

  useEffect(() => {
    // If the user returned from OAuth, open the Plaid interface automatically
    if (isOAuthRedirect && ready) {
      open();
    }
  }, [isOAuthRedirect, open, ready]);

  return { error, open, ready };
}
