/**
 * Extracts the optimal message from a server error response. The `response` object is the
 * stringified Error, and the outer `message` is the general message based on http status
 * @param {Object} error
 */
export default function getErrorMessage(error, defaultMessage) {
  if (error?.code === 'NotExpiringSoon') {
    return 'Your Pay with GasBuddy card is still active and not ready to renew at this time. We\'ll be in touch when it\'s time to renew.';
  }

  const message = error?.response?.message // Server error message
    || defaultMessage // Optionally provided default error message
    || error?.message // Generic error based on http status code
    || 'An error occurred'; // Fallback error message

  // Message will always have a value, so only return if error is truthy.
  return error && message;
}
