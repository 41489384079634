import { connect } from 'react-redux';
import ProgramSwitch from './ProgramSwitch';
import { clearPartnerProgram, switchToPartnerProgram } from '../../reducers/actions';

function mapStateToProps({ progress, wallet }) {
  return {
    partner: progress.partner,
    program: wallet.programs?.[0],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    performSwitch: () => dispatch(switchToPartnerProgram()),
    skipSwitch: () => dispatch(clearPartnerProgram()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgramSwitch);
