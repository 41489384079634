import React from 'react';
import { Text } from '@gasbuddy/react-components';

function UnderMaintenance() {
  return (
    <React.Fragment>
      <Text as="h1">Down for Maintenance</Text>
      <Text as="p">You have caught us during a maintenance window. Please come back in a bit.</Text>
    </React.Fragment>
  );
}

export default UnderMaintenance;
