import { connect } from 'react-redux';
import ProcessingGasBackGiftCard from './ProcessingGasBackGiftCard';

function mapStateToProps({ gasback, config }) {
  const gasbackTransferDetails = gasback.transfer.details || {};
  const {
    amount: transferAmount,
    vendorId,
    recipientEmail,
    firstName: cardHolderFirstName,
    lastName: cardHolderLastName,
  } = gasbackTransferDetails;

  return {
    consumerHost: config.consumerHost,
    vendorId,
    transferAmount,
    recipientEmail,
    cardHolderFirstName,
    cardHolderLastName,
  };
}

export default connect(mapStateToProps)(ProcessingGasBackGiftCard);
