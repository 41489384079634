const CharityOptions = [
  {
    id: 'feeding_america',
    name: 'Feeding America',
    url: 'https://www.feedingamerica.org/',
    urlText: 'FEEDINGAMERICA.ORG',
    imageUrl: '//static.gasbuddy.com/web/pay/svg/vendors/feeding-america.svg',
    description: 'For more than 40 years, Feeding America has responded to the needs of individuals struggling with food insecurity in this country. In times of uncertainty, we have not wavered from our mission to end the fight against hunger.',
    donationDescription: 'Every dollar you give can provide at least 10 meals to families in need through the Feeding America network of food banks. Donations made through this appeal support Feeding America\'s entire mission and will not be designated to a specific program or location. This donation is not applicable to claim on taxes for either you or GasBuddy',
    enabled: true,
  },
];

export default CharityOptions;

export const CharityIds = CharityOptions.map(charity => charity.id);
