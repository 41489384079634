import { Form, Message, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import formatPrice from '../../../lib/utils/formatPrice';
import { ANALYTICS_EVENTS, ANALYTICS_SCREENS } from '../../constants/analytics';
import GiftCardVendors from '../../constants/giftCardVendors';
import useTracking from '../../hooks/useTracking';
import { GiftCardVendorPropType } from '../../prop-types/gasback';
import CardChoiceConfirmation from '../CardChoiceConfirmation';
import GasBackTransferStep from '../GasBackTransferStep';
import styles from './ConfirmGasBackGiftCardDetails.module.css';

const cx = classnames.bind(styles);

export default function ConfirmGasBackGiftCardDetails({
  cardHolderFirstName,
  cardHolderLastName,
  consumerHost,
  error,
  history,
  idempotencyId,
  pendingEmailChange,
  recipientEmail,
  transferAmount,
  transferGasBackToGiftCard,
  transferInitiated,
  transferPending,
  vendorId: chosenGiftCardVendorId,
}) {
  const { trackEvent } = useTracking(ANALYTICS_SCREENS.PAY_REDEEM_GIFTCARD_REVIEW);
  const chosenVendor = useMemo(() => GiftCardVendors.find(vendor => vendor.id === chosenGiftCardVendorId), [chosenGiftCardVendorId]);

  const hasInitiatedTransfer = !transferPending && !error && transferInitiated;

  const handleTransfer = useCallback((e) => {
    trackEvent(ANALYTICS_EVENTS.PAY_REDEEM_GIFTCARD_REVIEW_SUBMIT_CLICKED);

    transferGasBackToGiftCard(e, {
      idempotencyId,
      vendorId: chosenGiftCardVendorId,
      amount: transferAmount,
      recipientEmail,
      firstName: cardHolderFirstName,
      lastName: cardHolderLastName,
    });
  }, [
    cardHolderFirstName,
    cardHolderLastName,
    chosenGiftCardVendorId,
    idempotencyId,
    recipientEmail,
    trackEvent,
    transferAmount,
    transferGasBackToGiftCard,
  ]);

  const handleCancel = useCallback(() => {
    trackEvent(ANALYTICS_EVENTS.PAY_REDEEM_GIFTCARD_REVIEW_CANCEL_CLICKED);
  }, [trackEvent]);

  useEffect(() => {
    if (hasInitiatedTransfer) {
      history.push('/gasback/transfer/giftcard/processing');
    }
  }, [history, hasInitiatedTransfer]);

  if (!chosenVendor) {
    return (
      <Redirect to="/gasback/transfer/giftcard" />
    );
  }

  return (
    <GasBackTransferStep
      backLinkText="Enter Your Name"
      context={{ screen: ANALYTICS_SCREENS.PAY_REDEEM_GIFTCARD_REVIEW }}
    >
      {/* TODO: Confirm proper copy below for error message */}
      {pendingEmailChange && (
        <Message color="red" header="Pending Hold on Email Address">
          Your email address associated with your account has been updated recently.
          You will need to wait a few hours before you can issue a gift card for your GasBack balance.
        </Message>
      )}
      <Form action="/gasback/transfer/giftcard" method="post" loading={transferPending} onSubmit={handleTransfer}>
        <CardChoiceConfirmation
          title="Confirm your digital gift card purchase"
          description="Review and confirm your digital gift card and email."
          imageUrl={chosenVendor.imageUrl}
          cardVendorName={chosenVendor.displayName}
        >
          <input type="hidden" name="idempotencyId" value={idempotencyId} />
          <input type="hidden" name="vendorId" value={chosenGiftCardVendorId} />
          <input type="hidden" name="amount" value={transferAmount} />
          <input type="hidden" name="recipientEmail" value={recipientEmail} />
          <input type="hidden" name="firstName" value={cardHolderFirstName} />
          <input type="hidden" name="lastName" value={cardHolderLastName} />
          <Text as="p">Gift Card Amount</Text>
          <Text as="p" bold>{formatPrice(transferAmount, 2)}</Text>
          <br />
          <Text as="p">Your Name</Text>
          <Text as="p" bold>{cardHolderFirstName} {cardHolderLastName}</Text>
          <br />
          <Text as="p">Your confirmation and gift code will be sent to</Text>
          <Text as="p" bold>{recipientEmail}</Text>
          <Text as="p" size="sm">
            Not the right email address?
            &nbsp;
            <Text
              as="a"
              bold
              className={cx('blueLink')}
              href="https://help.gasbuddy.com/hc/en-us/articles/360022898273-Editing-your-GasBuddy-profile#h_3f59581c-2616-4ddb-993d-8c99d2ecb67a"
              target="_blank"
            >
              Contact Support
            </Text>
          </Text>
          <br />
          <CardChoiceConfirmation.Terms
            title="Gift card details"
            content={chosenVendor.terms}
            detailsUrl={chosenVendor.detailsUrl}
            withTermsLink
          />
          {!!error && (
            <Text as="p" color="orange">
              {error}
            </Text>
          )}
          <GasBackTransferStep.Actions>
            <GasBackTransferStep.Button
              primary
              fluid
              type="submit"
              disabled={pendingEmailChange}
            >
              Confirm Purchase
            </GasBackTransferStep.Button>
            <GasBackTransferStep.Button
              as="a"
              link
              href={`//${consumerHost}/account/savings`}
              onClick={handleCancel}
            >
              Cancel
            </GasBackTransferStep.Button>
          </GasBackTransferStep.Actions>
        </CardChoiceConfirmation>
      </Form>
    </GasBackTransferStep>
  );
}

ConfirmGasBackGiftCardDetails.propTypes = {
  cardHolderFirstName: PropTypes.string,
  cardHolderLastName: PropTypes.string,
  consumerHost: PropTypes.string,
  error: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  idempotencyId: PropTypes.string.isRequired,
  pendingEmailChange: PropTypes.bool,
  recipientEmail: PropTypes.string,
  transferAmount: PropTypes.string,
  transferGasBackToGiftCard: PropTypes.func,
  transferInitiated: PropTypes.bool,
  transferPending: PropTypes.bool,
  vendorId: GiftCardVendorPropType,
};

ConfirmGasBackGiftCardDetails.defaultProps = {
  cardHolderFirstName: undefined,
  cardHolderLastName: undefined,
  consumerHost: 'www.gasbuddy.com',
  error: undefined,
  history: {
    push: () => {},
  },
  pendingEmailChange: false,
  recipientEmail: undefined,
  transferAmount: undefined,
  transferGasBackToGiftCard: () => {},
  transferInitiated: false,
  transferPending: false,
  vendorId: undefined,
};
