import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import styles from './ProgressBar.module.css';

const cx = classnames.bind(styles);

export default function ProgressBar({ height, progressHeight }) {
  return (
    <div
      className={cx('progressBarContainer')}
    >
      <div
        className={cx(
          'progressBar',
          'line',
          'filled',
        )}
        style={{ height: progressHeight }}
      />
      <div
        className={cx('progressBar', 'border', 'margin', 'base')}
        style={{ height }}
      />
    </div>
  );
}

ProgressBar.propTypes = {
  height: PropTypes.number,
  progressHeight: PropTypes.number,
};

ProgressBar.defaultProps = {
  height: 0,
  progressHeight: 0,
};
