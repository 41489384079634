import { Actions, Constraint, Header, Image, Text } from '@gasbuddy/react-components';
import { AppBadges } from '@gasbuddy/react-consumer-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import { ANALYTICS_SCREENS } from '../../constants/analytics';
import PayPrograms from '../../constants/payPrograms';
import PayProgramContext from '../../context/payProgram';
import useTracking from '../../hooks/useTracking';
import LineThrough from '../LineThrough';
import styles from './Enrolled.module.css';

const cx = classnames.bind(styles);

const appStoreData = {
  name: 'iOSAppRating',
  rating: 4.5,
  reviewCount: '380,000+',
};

const playStoreData = {
  name: 'androidAppRating',
  rating: 4.5,
  reviewCount: '770,000+',
};

export default function Enrolled({
  hasRedirect,
  withIAV,
}) {
  const payProgram = useContext(PayProgramContext);
  const isPlus = payProgram === PayPrograms.Plus;
  const isPremium = payProgram === PayPrograms.Premium;

  useTracking(ANALYTICS_SCREENS.PAY_ENROLL_COMPLETE);

  let nextSteps = (
    <Fragment>
      <ol className={cx('steps')} id="nextSteps">
        {!withIAV && (
          <li>
            <Text bold>Verify small deposits</Text>
            <Text as="p">
              In <b>1-3 business days</b> you will receive two small deposits in the account you linked.
              Once you receive the deposits return to the GasBuddy app where you will be prompted to enter the two small deposit amounts.
              Once your account has been verified, the small deposits will be withdrawn from your account. Your card
              will arrive in 5-10 business days from the day you confirm your small deposits.
            </Text>
          </li>
        )}
        <li>
          <Text bold>Activate your card</Text>
          <Text as="p">
            When your card arrives, open the GasBuddy App where you will be prompted to activate your card.
            On the web, visit enroll.gasbuddy.com and you&apos;ll be prompted to activate your card.
          </Text>
        </li>
        <li>
          <Text bold>Create your Driver ID (PIN)</Text>
          <Text as="p">Once your card is activated you&apos;ll be prompted to create a 4-digit Driver ID (PIN) that you&apos;ll enter at the pump to securely use your Pay with GasBuddy card. So make sure it&apos;s a PIN you will remember.</Text>
        </li>
      </ol>
    </Fragment>
  );

  if (isPlus || isPremium) {
    const cardPoints = [
      'Your Pay with GasBuddy card will be mailed to you shortly and should arrive within 7-10 business days. Be on the look out!',
    ];

    const perksPoints = [];

    if (isPremium) {
      perksPoints.push('Roadside service goes into effect 72 hours from your purchase date (today).');
    }

    perksPoints.push(
      'Your fuel discounts begin as soon as you receive and activate your card in the GasBuddy app.',
      'Your monthly gallon limit begins on the day you activate your card and restarts the same date the following month.',
    );

    const accountAndBillingPoints = [];

    if (withIAV) {
      accountAndBillingPoints.push('You will see a charge from GasBuddy on your account for your membership purchase. Your monthly billing date will be the day you activate your card in the GasBuddy app.');
    } else {
      accountAndBillingPoints.push((
        <Fragment>
          <Text>IMPORTANT: GasBuddy will make two small deposits in your checking account to ensure that your account is connected properly.</Text>
          <Text bold> You will need to verify these deposits through GasBuddy to complete enrollment.</Text>
          <Text> We will send an email with instructions when they are ready for you to verify.</Text>
        </Fragment>
      ));
      accountAndBillingPoints.push('Once you verify the deposits, you will see a charge from GasBuddy on your account for your membership purchase. Your monthly billing date will be the day you activate your card in the GasBuddy app.');
    }

    nextSteps = (
      <Fragment>
        <Text bold>Pay with GasBuddy card:</Text>
        <ul>
          {cardPoints.map(text => (
            <li key={text}>{text}</li>
          ))}
        </ul>
        <Text bold>Account and billing:</Text>
        <ul>
          {accountAndBillingPoints.map(text => (
            <li key={text}>{text}</li>
          ))}
        </ul>
        <Text bold>Fuel discounts{isPremium && ' & roadside assistance:'}</Text>
        <ul>
          {perksPoints.map(text => (
            <li key={text}>{text}</li>
          ))}
        </ul>
      </Fragment>
    );
  }

  return (
    <Constraint desktop={12}>
      <div className={cx('topContainer')}>
        <div className={cx('imageContainer')}>
          <Image
            alt="GasBuddy Phone Alerts"
            src="https://static.gasbuddy.com/web/consumer/phone-card-deals.png"
          />
        </div>
        <div className={cx('content')}>
          <Header as="h2" className={cx('title')}>
            Congratulations! Your GasBuddy card is on its way.
          </Header>
          <Text
            as="p"
            className={cx('subtitle')}
            color="midnight"
          >
            You&apos;re just a few steps away from saving at stations nationwide and never overpaying for gas again. <a href="#nextSteps">Complete the steps below</a> to start saving using Pay with GasBuddy, but first:
          </Text>
          {hasRedirect ? (
            <Actions>
              <Actions.Button
                as="a"
                className={cx('cta')}
                fluid
                href="/action-completed/enrolled"
                primary
                tablet={6}
                desktop={6}
              >
                Continue
              </Actions.Button>
            </Actions>
          ) : (
            <Fragment>
              <div className={cx('qrCodeContainer')}>
                <Image
                  alt="Download App QR code"
                  className={cx('qrCode')}
                  src="https://static.gasbuddy.com/web/download-app-qr-code.svg"
                />
              </div>
              <LineThrough className={cx('orLineThrough')}>OR</LineThrough>
              <AppBadges className={cx('appBadges')} showReviewSections appStoreData={appStoreData} playStoreData={playStoreData} />
            </Fragment>
          )}
        </div>
      </div>
      <div className={cx('tealContainer')}>
        <Text size="xl" bold>What you&apos;ll do next:</Text>
        <Text size="md" as="p" className={cx('pleaseCheckCopy')}>
          Please check the GasBuddy App and your email to follow the steps outlined below which need to be completed
          in the coming days. For future reference, these steps will be emailed to you.
        </Text>
        {nextSteps}
      </div>
    </Constraint>
  );
}

Enrolled.propTypes = {
  hasRedirect: PropTypes.bool,
  withIAV: PropTypes.bool,
};

Enrolled.defaultProps = {
  hasRedirect: false,
  withIAV: true,
};
