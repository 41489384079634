import { useMemo } from 'react';

// TODO Check if image exists with density
function withDensity(url, density) {
  const urlParts = url.split('.');
  const ext = urlParts.splice(-1, 1);
  return `${urlParts.join('.')}@${density}x.${ext}`;
}

export default function useResponsiveImage(url) {
  const devicePixelRatio = typeof window === 'undefined' ? 1 : window.devicePixelRatio;

  return useMemo(() => {
    if (devicePixelRatio < 1.3) {
      return url;
    }

    if (devicePixelRatio < 2.25) {
      return withDensity(url, 2);
    }

    return withDensity(url, 3);
  }, [url, devicePixelRatio]);
}
