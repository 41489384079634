import { connect } from 'react-redux';
import getErrorMessage from '../../../lib/utils/getErrorMessage';
import getProgramFromWallet from '../../../lib/utils/getProgramFromWallet';
import { createBankInstrumentWithValidiFi } from '../../reducers/actions';
import IavButton from './IavButton';

const DEFAULT_ERROR_MESSAGE = 'There was an error linking your bank account. Please try again later.';

function mapStateToProps({ bank, config, wallet }) {
  return {
    env: config.env,
    bankAccountError: getErrorMessage(bank.error, DEFAULT_ERROR_MESSAGE),
    iavSessionToken: config.iavProviderToken,
    iavEnv: config.iavEnv,
    isCreatingInstrument: bank.isSaving,
    program: getProgramFromWallet(wallet),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createBankInstrument: body => dispatch(createBankInstrumentWithValidiFi(body)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IavButton);
export { withIav } from './IavButton';
