const MAX_GU_DISCOUNT = 0.25;

export default function formatOfferDetails(offer) {
  let addOnAmount;
  let maxVolume;
  let extraData;

  if (offer.fuel_discount) {
    ({ gallons: maxVolume, extra_discount: addOnAmount } = offer.fuel_discount);
  } else if (offer.coupon_details) { // coupon-based programs have different structure
    ({ amount: addOnAmount = 0, extra_data: extraData } = offer.coupon_details);
    ({ quantity: maxVolume } = extraData);
  } else if (offer.program_details) {
    ({ extra_data: extraData } = offer.program_details);
    ({ add_on: { amount: addOnAmount = 0, qty: maxVolume } } = extraData);
  }


  const maxCentsOff = Math.round((parseFloat(addOnAmount) + MAX_GU_DISCOUNT) * 100);
  const price = parseFloat(offer.renewal_price);
  const initialPrice = parseFloat(offer.initial_price);

  return {
    initialPrice,
    maxCentsOff,
    maxVolume,
    price,
  };
}
