import { connect } from 'react-redux';
import MicrodepositForm from './MicrodepositForm';
import { confirmMicrodeposits } from '../../reducers/actions';
import getErrorMessage from '../../../lib/utils/getErrorMessage';

const defaultErrorMessage = 'An error occurred verifying your micro deposits. Please try again later.';

function mapStateToProps({ wallet, instruments }) {
  const { isConfirmingDeposits, depositConfirmError } = instruments;
  return {
    wallet,
    isSaving: isConfirmingDeposits,
    error: getErrorMessage(depositConfirmError, defaultErrorMessage),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (e, body) => {
      e.preventDefault();
      dispatch(confirmMicrodeposits(body));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MicrodepositForm);
