import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { Button, Header, Text } from '@gasbuddy/react-components';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import styles from './StatusBanner.module.css';
import Panel from '../Panel';

const cx = classnames.bind(styles);

export default function StatusBanner({ accessory, body, buttons, clickUrl, titles, type }) {
  const warningIcon = (
    <span className={cx('warningIcon')}>
      <FontAwesomeIcon icon={faExclamationTriangle} size="sm" />&nbsp;
    </span>
  );

  const textComponentProps = clickUrl ? { as: 'a', href: clickUrl } : { as: 'span' };
  const [mainTitle, ...remainingTitles] = titles;

  return (
    <Text {...textComponentProps}>
      <Panel className={cx('bannerContainer')} gradient={type === 'promo'}>
        {!!mainTitle && ( // Use the first title in the array as the main title
          <Header as="h2">
            {accessory === 'warning' && warningIcon}
            {mainTitle}
          </Header>
        )}
        {!!remainingTitles?.length && (
          <Header as="h3">
            {remainingTitles.join(' ')}
          </Header>
        )}
        {!!body.length && (
          <div className={cx('bannerBody')}>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              remarkPlugins={[remarkGfm]}
            >
              {body.join(' ')}
            </ReactMarkdown>
          </div>
        )}
        {!!buttons?.length && (
          <div className={cx('bannerButtons')}>
            {buttons.map(button => (
              <Button
                key={button.text}
                as="a"
                href={button.url}
                className={cx('bannerButton')}
                primary
                fluid
              >
                {button.text}
              </Button>
            ))}
          </div>
        )}
      </Panel>
    </Text>
  );
}

StatusBanner.propTypes = {
  accessory: PropTypes.oneOf(['warning', 'error']),
  body: PropTypes.arrayOf(PropTypes.string),
  buttons: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
  })),
  clickUrl: PropTypes.string,
  titles: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.oneOf(['normal', 'promo']),
};

StatusBanner.defaultProps = {
  accessory: undefined,
  body: [],
  buttons: undefined,
  clickUrl: undefined,
  titles: [],
  type: 'normal',
};
