import { connect } from 'react-redux';
import { updateGasBackTransferDetails } from '../../reducers/actions';
import GasBackGiftCardSelect from './GasBackGiftCardSelect';
import getQueryParamsFromLocation from '../../../lib/utils/getQueryParamsFromLocation';

function mapStateToProps({ config, gasback, auth }, { location }) {
  const { balance, error } = gasback.transfer;
  const { confirmationId } = getQueryParamsFromLocation(location);

  return {
    consumerHost: config.consumerHost,
    balance,
    recipientEmail: auth.email,
    error,
    confirmationId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (e, body) => {
      e.preventDefault();
      dispatch(updateGasBackTransferDetails(body));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GasBackGiftCardSelect);
