const isPayCard = instrument => instrument.instrument_type === 'private_card' && instrument.provider_name === 'gasbuddy';
const isCreditCard = instrument => instrument.instrument_type === 'network_card' && instrument.provider_name === 'gasbuddy';

export const getPayCard = (instruments = []) => instruments.find(isPayCard);

export const hasPayCard = (instruments = []) => instruments.some(isPayCard);

export const getCreditCard = (instruments = []) => instruments.find(isCreditCard);

export const hasCreditCard = (instruments = []) => instruments.some(isCreditCard);
