import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ConfirmGasBackCharityTransfer from './ConfirmGasBackCharityTransfer';
import { transferGasBackToCharity } from '../../reducers/actions';
import getErrorMessage from '../../../lib/utils/getErrorMessage';

function mapStateToProps({ gasback, config }) {
  const gasbackTransferDetails = gasback.transfer.details || {};
  const {
    idempotencyId,
    isTransferring: transferPending,
    error,
    success: transferInitiated,
  } = gasback.transfer;
  const {
    charityId,
    donationAmount,
    recipientEmail,
  } = gasbackTransferDetails;

  return {
    idempotencyId,
    consumerHost: config.consumerHost,
    charityId,
    donationAmount,
    recipientEmail,
    transferPending,
    error: getErrorMessage(error, 'There was an error processing the transfer request. Please try again later.'),
    transferInitiated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (e, body) => {
      e.preventDefault();
      dispatch(transferGasBackToCharity(body));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmGasBackCharityTransfer));
