import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { Button, FlexGrid, Header, Image, Input, Link, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useSimpleInput from '../../hooks/useSimpleInput';
import GradientContainer from '../GradientContainer';
import styles from './GasBackRedeem.module.css';
import redeemFAQs from '../../constants/redeemFAQs';
import useTracking from '../../hooks/useTracking';
import { ANALYTICS_EVENTS, ANALYTICS_SCREENS } from '../../constants/analytics';

const cx = classnames.bind(styles);

function QuestionListItem({ children: answer, question }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleClick = useCallback(() => {
    setIsOpen(isCurrentlyOpen => !isCurrentlyOpen);
  }, []);

  if (!answer || !question) {
    return null;
  }

  // See below link for reasoning behind button element
  // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/no-noninteractive-element-interactions.md#case-this-is-a-heading-that-expandscollapses-content-on-the-package
  return (
    <div className={cx('item', { active: isOpen })}>
      <button className={cx('reset')} onClick={handleToggleClick} type="button">
        <Text as="strong" className={cx('question')}>{question}</Text>
        <FontAwesomeIcon data-testid="questionChevron" icon={isOpen ? faChevronUp : faChevronDown} />
      </button>
      <div className={cx('answer')}>
        <Text>{answer}</Text>
      </div>
    </div>
  );
}

QuestionListItem.propTypes = {
  /** The answer to the question */
  children: PropTypes.node,
  /** The question part from an FAQ */
  question: PropTypes.string,
};

QuestionListItem.defaultProps = {
  children: undefined,
  question: undefined,
};

export default function GasBackRedeem({
  amountRedeemed,
  isRedeeming,
  onSubmitCode,
  redeemedOfferId,
  redeemedPromotionId,
  redemptionError,
  resetForm,
  shortLinkPartner,
}) {
  const { trackEvent, trackPageView } = useTracking();
  const [redeemCode, onSetRedeemCode] = useSimpleInput();

  const handleRedeemCode = useCallback((e) => {
    if (redeemCode) {
      trackEvent(ANALYTICS_EVENTS.PAY_REWARD_CODE_REDEEM_CODE_SUBMITTED, {
        partner: shortLinkPartner,
        platform: 'web',
      });
      onSubmitCode(e, { redeemCode });
    }
  }, [onSubmitCode, redeemCode, shortLinkPartner, trackEvent]);

  const handleRedeemAnotherCode = useCallback(() => {
    trackEvent(ANALYTICS_EVENTS.PAY_REWARD_CODE_REDEEM_SUBMIT_ANOTHER_CODE_CLICKED, {
      partner: shortLinkPartner,
      platform: 'web',
    });
    resetForm();
  }, [resetForm, shortLinkPartner, trackEvent]);

  useEffect(() => {
    if (amountRedeemed) {
      trackEvent(ANALYTICS_EVENTS.PAY_REWARD_CODE_REDEEM_SUBMISSION_SUCCEEDED, {
        partner: shortLinkPartner,
        platform: 'web',
        promotionId: redeemedPromotionId,
        offerId: redeemedOfferId,
      });
      // Reset the code to prep for a user redeeming another one
      onSetRedeemCode({ target: { value: '' } });
    } else if (redemptionError) {
      trackEvent(ANALYTICS_EVENTS.PAY_REWARD_CODE_REDEEM_SUBMISSION_FAILED, {
        partner: shortLinkPartner,
        platform: 'web',
      });
    }
  }, [amountRedeemed, onSetRedeemCode, redeemedOfferId, redeemedPromotionId, redemptionError, shortLinkPartner, trackEvent]);

  useEffect(() => {
    trackPageView(ANALYTICS_SCREENS.PAY_REWARD_CODE_REDEEM, {
      partner: shortLinkPartner,
      platform: 'web',
    });
  }, [shortLinkPartner, trackPageView]);

  return (
    <GradientContainer
      logo={(
        <Image src="https://static.gasbuddy.com/web/gas-buddy-logo-midnight-txt.svg" alt="GasBuddy Logo" />
      )}
      shouldShowFooter={false}
    >
      <FlexGrid className={cx('top-section')}>
        <FlexGrid.Column tablet={6} className={cx('code-redemption-wrapper')}>
          <Header as="h1" className={cx('top-header')}>
            {amountRedeemed
              ? `You redeemed ${amountRedeemed} GasBack savings!`
              : (
                <Fragment>
                  Enter your code for GasBack<sup>&reg;</sup> Rewards
                </Fragment>
              )
            }
          </Header>
          <Text as="p" data-testid="belowHeaderIntro">
            {amountRedeemed
              ? `Your reward code just scored you ${amountRedeemed} in GasBack Savings! ${amountRedeemed} GasBack will be awarded to your account within 24 hours.`
              : (
                <Fragment>
                  GasBuddy<sup>&reg;</sup> offers users another way to earn GasBack<sup>&reg;</sup> with codes.
                  Enter your unique code below to receive GasBack Rewards, which will be added to your GasBuddy account.
                  While a single code can only be redeemed for GasBack once, you can enter as many unique GasBack codes as you&apos;d like.
                </Fragment>
              )
            }
          </Text>
          <br />
          {!amountRedeemed && (
            <Fragment>
              <Input
                caption="Codes are case-sensitive"
                data-testid="codeRedemptionInput"
                disabled={isRedeeming}
                error={redemptionError}
                id="codeRedemptionInput"
                label="Enter Code Here"
                onChange={onSetRedeemCode}
                value={redeemCode}
              />
              <br />
            </Fragment>
          )}
          <Button
            fluid
            primary
            data-testid="codeRedemptionButton"
            disabled={(!redeemCode || isRedeeming) && !amountRedeemed}
            loading={isRedeeming}
            onClick={amountRedeemed ? handleRedeemAnotherCode : handleRedeemCode}
          >
            {amountRedeemed ? 'Redeem Another Code' : 'Redeem Code'}
          </Button>
          <br /><br />
          <Text as="p" bold>
            Terms and Conditions
          </Text>
          <Text as="p" size="sm">
            GasBack Rewards expire 12 months after issuance.
            Join Pay with GasBuddy to automatically redeem your GasBack at the pump and save up to 25¢/gal.
            GasBack redeemed as part of this promotion is subject to the Pay with GasBuddy&nbsp;
            <a
              href="https://help.gasbuddy.com/hc/en-us/articles/115005755528-Payments-Terms-of-Service"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>.
            If user is not enrolled in Pay with GasBuddy, GasBack can be redeemed for e-gift cards or donated to charity.
          </Text>
          <br />
        </FlexGrid.Column>
        <FlexGrid.Column tablet={6} className={cx('auto-redeem-wrapper')}>
          <div className={cx('auto-redeem-border')}>
            <Image
              alt="Stack of simple bills with stars around it"
              src="//static.gasbuddy.com/web/icons/redeem-gasback.svg"
            />
            <br /><br />
            <Text as="p" className={cx('top-image-blurb')}>
              Automatically redeem your GasBack savings at the pump when you pay with your Pay with GasBuddy card.
            </Text>
          </div>
        </FlexGrid.Column>
      </FlexGrid>
      <FlexGrid className={cx('faq-section')}>
        <FlexGrid.Column tablet={6}>
          <Header as="h2" className={cx('faq-header')}>Frequently asked questions</Header>
          <Text as="p" className={cx('faq-intro')}>
            For other questions, visit our&nbsp;
            <Link
              target="_blank"
              to="https://help.gasbuddy.com/hc/en-us"
            >
              help center
            </Link>
            .
          </Text>
        </FlexGrid.Column>
        <FlexGrid.Column tablet={6} className={cx('faq-questions-wrapper')} data-testid="faqList">
          {redeemFAQs.map(faq => (
            <QuestionListItem key={faq.question} question={faq.question}>
              {faq.answer}
            </QuestionListItem>
          ))}
        </FlexGrid.Column>
      </FlexGrid>
    </GradientContainer>
  );
}

GasBackRedeem.propTypes = {
  amountRedeemed: PropTypes.string,
  isRedeeming: PropTypes.bool,
  onSubmitCode: PropTypes.func,
  redeemedOfferId: PropTypes.number,
  redeemedPromotionId: PropTypes.number,
  redemptionError: PropTypes.string,
  resetForm: PropTypes.func,
  shortLinkPartner: PropTypes.string,
};

GasBackRedeem.defaultProps = {
  amountRedeemed: undefined,
  isRedeeming: false,
  onSubmitCode: () => { },
  redeemedOfferId: undefined,
  redeemedPromotionId: undefined,
  redemptionError: undefined,
  resetForm: () => { },
  shortLinkPartner: 'n/a',
};
