import { connect } from 'react-redux';
import { Router } from './Router';

function mapStateToProps({ auth, config }) {
  const { localyticsId, recaptchaSiteKey } = config;
  const { account_id: accountId, membername } = auth;

  return {
    accountId,
    localyticsId,
    memberName: membername,
    recaptchaKey: recaptchaSiteKey,
  };
}

export default connect(mapStateToProps)(Router);
