import { Action } from './actions';

export const MagicLinkStatus = {
  Sent: 1,
  Throttled: 2,
  InvalidDevice: 3,
  InvalidCode: 4,
};

export const defaultState = {
  isLoading: false,
  modalVariant: undefined,
  magicLinkError: undefined,
};

export default function magiclinksReducer(state = defaultState, action) {
  const { response } = action.payload || {};

  switch (action.type) {
    case Action.Auth.SocialConnectMagicLinkPending:
    case Action.Auth.LoginMagicLinkPending:
      return {
        ...state,
        isLoading: true,
        modalVariant: undefined,
        magicLinkError: undefined,
      };

    case Action.Auth.SocialConnectMagicLinkCompleted:
    case Action.Auth.LoginMagicLinkCompleted:
      return {
        ...state,
        isLoading: false,
        modalVariant: MagicLinkStatus.Sent,
        magicLinkError: undefined,
      };

    case Action.Auth.SocialConnectMagicLinkFailed:
    case Action.Auth.LoginMagicLinkFailed:
      return {
        ...state,
        isLoading: false,
        magicLinkError: response && response.message,
        modalVariant: undefined,
      };

    default: {
      return state;
    }
  }
}
