import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Constraint, Text, Actions } from '@gasbuddy/react-components';
import CardActivationHeader from '../CardActivationHeader';
import styles from './BankVerified.module.css';

const cx = classnames.bind(styles);

export default function BankVerified({ consumerHost, subtitle }) {
  const message = 'You\'ll be saving on gas before you know it. Keep an eye out for your card in the mail within the next 5-7 business days, and then you will need to activate it on the GasBuddy app or website.';

  return (
    <Constraint desktop={10}>
      <CardActivationHeader title="All set!" subtitle={subtitle} />
      <br />
      <Text>{message}</Text>
      <br />
      <br />
      <Actions className={cx('bankVerifiedButtonGrid')}>
        <Actions.Button
          as="a"
          href="https://routing.gasbuddy.com/app"
          target="_blank"
          primary
          type="submit"
          tablet={6}
          desktop={5}
        >
          Get The App
        </Actions.Button>
        <Actions.Button
          as="a"
          href={`//${consumerHost}`}
          secondary
          tablet={6}
          desktop={5}
        >
          Exit
        </Actions.Button>
      </Actions>
    </Constraint>
  );
}

BankVerified.propTypes = {
  consumerHost: PropTypes.string,
  subtitle: PropTypes.string,
};

BankVerified.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
  subtitle: '',
};
