import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Header, Text, Form, Message } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import GasBackTransferStep from '../GasBackTransferStep';
import formatPrice from '../../../lib/utils/formatPrice';
import styles from './ConfirmGasBackBankTransfer.module.css';

const cx = classnames.bind(styles);

export default function ConfirmGasBackBankTransfer({
  idempotencyId,
  consumerHost,
  routingNumber,
  accountNumber,
  transferAmount,
  recipientEmail,
  onSubmit,
  transferPending,
  error,
  transferInitiated,
  history,
}) {
  const handleSubmit = useCallback((e) => {
    onSubmit(e, {
      idempotencyId,
      routingNumber,
      accountNumber,
      amount: transferAmount,
    });
  }, [onSubmit, idempotencyId, routingNumber, accountNumber, transferAmount]);

  const hasInitiatedTransfer = !transferPending && !error && transferInitiated;

  useEffect(() => {
    if (hasInitiatedTransfer) {
      history.push('/gasback/transfer/bank/processing');
    }
  }, [hasInitiatedTransfer, history]);

  const lastFourAccountDigits = accountNumber?.substring(accountNumber.length - 4);

  return (
    <GasBackTransferStep backLinkText="Enter Bank Account">
      <Form aria-label="GasBack Transfer Confirmation Form" method="post" action="/gasback/transfer/bank" onSubmit={handleSubmit} loading={transferPending}>
        <input type="hidden" name="idempotencyId" value={idempotencyId} />
        <input type="hidden" name="routingNumber" value={routingNumber} />
        <input type="hidden" name="accountNumber" value={accountNumber} />
        <input type="hidden" name="amount" value={transferAmount} />
        <input type="hidden" name="recipientEmail" value={recipientEmail} />
        <Header as="h3" snug>Confirm and begin your transfer</Header>
        <Text as="p">Review and confirm your transfer information.</Text>
        <br />
        <Text as="p">Transfer amount:</Text>
        <Text as="p" bold>{formatPrice(transferAmount)}</Text>
        <br />
        <Text as="p">Transfer to:</Text>
        <Text as="p" bold>Account ending in *{lastFourAccountDigits}</Text>
        <br />
        <Message className={cx('information')}>
          <Text as="p" bold>You will need to confirm two small microdeposits</Text>
          To verify your bank account, we&apos;ll make two small deposits of {formatPrice(0.10)} or less in the next 3-4 business days.
          Once you confirm the deposits, the remaining amount will be transferred to your account.
        </Message>
        <br />
        <Text as="p">Your microdeposits and confirmation email will be sent to</Text>
        <Text as="p" bold>{recipientEmail}</Text>
        {/* TODO: Update support link here after confirmation */}
        <Text as="p" size="sm">
          Not the right email address?
          &nbsp;
          <Text
            bold
            as="a"
            href="https://help.gasbuddy.com/hc/en-us/articles/360022898273-Editing-your-GasBuddy-profile#h_3f59581c-2616-4ddb-993d-8c99d2ecb67a"
            target="_blank"
          >
            Contact Support
          </Text>
        </Text>
        <br />
        <Text as="p">
          By clicking confirm below you agree to
          &nbsp;
          <Text
            as="a"
            bold
            href="https://help.gasbuddy.com/hc/en-us/articles/360047975493"
            role="link"
            target="_blank"
          >
            GasBuddy terms & conditions
          </Text>
        </Text>
        <br />
        {!!error && (
          <Text as="p" color="orange">
            {error}
          </Text>
        )}
        <GasBackTransferStep.Actions>
          <GasBackTransferStep.Button
            primary
            fluid
            type="submit"
          >
            Begin Transfer
          </GasBackTransferStep.Button>
          <GasBackTransferStep.Button link as="a" href={`//${consumerHost}/account/savings`}>
            Cancel
          </GasBackTransferStep.Button>
        </GasBackTransferStep.Actions>
      </Form>
    </GasBackTransferStep>
  );
}

ConfirmGasBackBankTransfer.propTypes = {
  idempotencyId: PropTypes.string.isRequired,
  consumerHost: PropTypes.string,
  routingNumber: PropTypes.string,
  accountNumber: PropTypes.string,
  transferAmount: PropTypes.string,
  recipientEmail: PropTypes.string,
  onSubmit: PropTypes.func,
  transferPending: PropTypes.bool,
  error: PropTypes.string,
  transferInitiated: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

ConfirmGasBackBankTransfer.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
  transferAmount: undefined,
  routingNumber: undefined,
  accountNumber: undefined,
  recipientEmail: undefined,
  onSubmit: () => {},
  transferPending: false,
  error: undefined,
  transferInitiated: false,
  history: {
    push: () => {},
  },
};
