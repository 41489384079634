import { Container, FlexGrid, Header, Image, Panel, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styles from './CardSelector.module.css';

const cx = classnames.bind(styles);

function Card({
  className,
  disabled,
  id,
  imageUrl,
  onClick,
  selected,
  subText,
  text,
  ...rest
}) {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(id);
    }
  }, [onClick, id]);

  return (
    <FlexGrid.Column
      aria-checked={selected}
      aria-disabled={disabled}
      className={cx('cardContainer', { disabled }, className)}
      role="radio"
      {...rest}
    >
      <Panel
        color="white"
        className={cx('card', { selected, clickable: typeof onClick === 'function' })}
        onClick={handleClick}
        padded={false}
      >
        <Image src={imageUrl} alt={text} />
      </Panel>
      <Text
        as="p"
        bold
      >
        {text}
        {subText && (
          <React.Fragment>
            <br />
            {subText}
          </React.Fragment>
        )}
      </Text>
    </FlexGrid.Column>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  subText: PropTypes.string,
  text: PropTypes.string.isRequired,
};

Card.defaultProps = {
  className: undefined,
  disabled: false,
  onClick: () => {},
  selected: false,
  subText: undefined,
};

function CardSelector({
  children,
  className,
  title,
  ...rest
}) {
  return (
    <Container>
      {!!title && (
        <Header as="h4" snug>{title}</Header>
      )}
      <FlexGrid className={cx(className)} container role="radiogroup" {...rest}>
        {children}
      </FlexGrid>
    </Container>
  );
}

CardSelector.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
};

CardSelector.defaultProps = {
  children: undefined,
  className: undefined,
  title: undefined,
};

CardSelector.Card = Card;

export default CardSelector;
