import React from 'react';
import { Text, Link, Checkbox } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import styles from './TermsOfService.module.css';

const cx = classnames.bind(styles);

export const LINKS = {
  GB_TERMS: 'https://help.gasbuddy.com/hc/en-us/articles/115005755528-Terms-of-Service',
  GB_PRIVACY_POLICY: 'https://help.gasbuddy.com/hc/en-us/articles/115005591687-Privacy-Policy/',
  DWOLLA_TERMS: 'https://www.dwolla.com/legal/tos/',
  DWOLLA_PRIVACY_POLICY: 'https://www.dwolla.com/legal/privacy/',
};

export default function TermsOfService({
  buttonText,
  onCheck,
}) {
  const handleTermsChecked = (e) => {
    if (onCheck) {
      onCheck(e.target.checked);
    }
  };

  return (
    <Checkbox
      id="termsAcceptance"
      name="acceptedTerms"
      onClick={handleTermsChecked}
      label={(
        <Text>
          By checking this box and clicking &quot;{buttonText}&quot; you are verifying that the information entered above is correct,
          and you are agreeing to GasBuddy’s
          <Link bold href={LINKS.GB_TERMS} target="_blank" rel="noopener noreferrer"> Terms &amp; Conditions </Link>
          and
          <Link bold href={LINKS.GB_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer"> Privacy Policy </Link>
          as well as our partner Dwolla&apos;s
          <Link bold href={LINKS.DWOLLA_TERMS} target="_blank" rel="noopener noreferrer"> Terms of Service </Link>
          and
          <Link bold href={LINKS.DWOLLA_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer"> Privacy Policy</Link>.
        </Text>
      )}
      className={cx('checkbox')}
    />
  );
}

TermsOfService.propTypes = {
  buttonText: PropTypes.string,
  onCheck: PropTypes.func,
};

TermsOfService.defaultProps = {
  buttonText: 'Submit',
  onCheck: undefined,
};
