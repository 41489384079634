import React from 'react';
import PropTypes from 'prop-types';
import { Container, Header, Text, Actions } from '@gasbuddy/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import classnames from 'classnames/bind';
import AddressPropType from '../../prop-types/address';
import getTitleFromDeliverability from '../../../lib/utils/getTitleFromDeliverability';
import getMessageFromDeliverability from '../../../lib/utils/getMessageFromDeliverability';
import DeliverabilityStatuses from '../../constants/deliverabilityStatuses';
import styles from './UndeliverableAddressConfirmation.module.css';

const cx = classnames.bind(styles);

export default function UndeliverableAddressConfirmation({
  deliverability,
  address,
  onContinue,
  onEditAddress,
}) {
  return (
    <Container>
      <Header as="h3">
        <Text className={cx('exclamation')}>
          <FontAwesomeIcon icon={faExclamationCircle} />
        </Text>
        &nbsp;
        {getTitleFromDeliverability(deliverability)}
      </Header>
      <br />
      <Text>Provided address:</Text>
      <br />
      <Text bold>
        {address.line_1}
        {!!address.line_2 && ` ${address.line_2}`}
        <br />
        {address.locality}, {address.region} {address.postal_code}
      </Text>
      <br />
      <br />
      <Text>{getMessageFromDeliverability(deliverability)}</Text>
      <br />
      <br />
      <br />
      <Actions>
        <Actions.Button primary tablet={6} desktop={6} onClick={onEditAddress}>
          Edit Address
        </Actions.Button>
        <Actions.Button secondary tablet={6} desktop={6} onClick={onContinue}>
          Continue Anyway
        </Actions.Button>
      </Actions>
    </Container>
  );
}

UndeliverableAddressConfirmation.propTypes = {
  deliverability: PropTypes.oneOf(
    Object.values(DeliverabilityStatuses),
  ).isRequired,
  address: AddressPropType.isRequired,
  onContinue: PropTypes.func,
  onEditAddress: PropTypes.func,
};

UndeliverableAddressConfirmation.defaultProps = {
  onContinue: () => {},
  onEditAddress: () => {},
};
