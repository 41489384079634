import React from 'react';
import { Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';

export default function MembershipBillingSummary({ title, price }) {
  return (
    <Text as="p">
      <Text>{title}</Text>
      {price && (
        <React.Fragment>
          <br />
          <Text size="lg">{price}</Text>
        </React.Fragment>
      )}
    </Text>
  );
}

MembershipBillingSummary.propTypes = {
  title: PropTypes.string,
  price: PropTypes.string,
};

MembershipBillingSummary.defaultProps = {
  title: undefined,
  price: undefined,
};
