import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import GasBackTransferOptions from './GasBackTransferOptions';
import { setGasBackTransferType } from '../../reducers/actions';

function mapStateToProps({ config }) {
  return {
    consumerHost: config.consumerHost,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSelectTransferOption: transferOption => dispatch(setGasBackTransferType(transferOption.type)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GasBackTransferOptions));
