import { SocialAuthButtons as AuthButtons } from '@gasbuddy/react-consumer-components';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import getCountryFromPostal from '../../../lib/utils/getCountryFromPostal';
import useEffectWithDeepCompare from '../../../lib/utils/useEffectWithDeepCompare';
import SocialStatus from '../../constants/socialStatus';
import useTracking from '../../hooks/useTracking';

export default function SocialAuthButtons(props) {
  const {
    isSigningUp,
    onFacebookResponse,
    onGoogleResponse,
    postalCode,
    signedRequest,
    signup,
    socialEmail,
    socialNetwork,
    socialStatus,
  } = props;
  const { analytics } = useTracking();

  useEffectWithDeepCompare(() => {
    if (!isSigningUp && socialStatus === SocialStatus.AccountNotFound) {
      signup(
        {
          country: getCountryFromPostal(postalCode),
          email: socialEmail,
          postalCode,
          signedRequest,
          socialNetwork,
          subscribeChecked: true,
        },
        analytics,
      );
    }
  }, [isSigningUp, postalCode, signedRequest, signup, socialEmail, socialNetwork, socialStatus]);

  const handleFacebookResponse = useCallback((response) => {
    onFacebookResponse(response, analytics);
  }, [analytics, onFacebookResponse]);

  const handleGoogleResponse = useCallback((response, email) => {
    onGoogleResponse(response, email, analytics);
  }, [analytics, onGoogleResponse]);

  // PostalCode is not available if user directly lands on /start page
  // It would be available in session if user came through enrollment form (either on consumer-web or on new enrollment)
  return (
    <AuthButtons
      {...props}
      onFacebookResponse={handleFacebookResponse}
      onGoogleResponse={handleGoogleResponse}
    />
  );
}

SocialAuthButtons.propTypes = {
  accountsConnectedButtonUrl: PropTypes.string,
  destination: PropTypes.string,
  errorMessage: PropTypes.string,
  facebookAppId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  googleAppId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isSendingMagicLink: PropTypes.bool,
  isSigningUp: PropTypes.bool,
  linkAccounts: PropTypes.func,
  magicLinkError: PropTypes.bool,
  magicLinkReturnUrl: PropTypes.string,
  magicLinkStatus: PropTypes.number,
  onCancel: PropTypes.func,
  onFacebookResponse: PropTypes.func,
  onGoogleResponse: PropTypes.func,
  postalCode: PropTypes.string,
  requestSocialConnectMagicLink: PropTypes.func,
  signedRequest: PropTypes.shape({}),
  signup: PropTypes.func,
  socialEmail: PropTypes.string,
  socialIsLinked: PropTypes.bool,
  socialNetwork: PropTypes.number,
  socialStatus: PropTypes.oneOf(Object.values(SocialStatus)),
};

SocialAuthButtons.defaultProps = {
  accountsConnectedButtonUrl: undefined,
  destination: undefined,
  errorMessage: undefined,
  facebookAppId: undefined,
  googleAppId: undefined,
  isSendingMagicLink: undefined,
  isSigningUp: undefined,
  linkAccounts: () => {},
  magicLinkError: undefined,
  magicLinkReturnUrl: undefined,
  magicLinkStatus: undefined,
  onCancel: () => {},
  onFacebookResponse: () => {},
  onGoogleResponse: () => {},
  postalCode: undefined,
  requestSocialConnectMagicLink: () => {},
  signedRequest: undefined,
  signup: () => {},
  socialEmail: undefined,
  socialIsLinked: undefined,
  socialNetwork: undefined,
  socialStatus: undefined,
};
