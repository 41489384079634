const DeliverabilityStatuses = {
  Deliverable: 'deliverable',
  SecondaryMissing: 'secondary_missing',
  SecondaryUnit: 'secondary_unit',
  SecondaryInvalid: 'secondary_invalid',
  Undeliverable: 'undeliverable',
  Unknown: 'unknown',
};

export default DeliverabilityStatuses;
