import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import GasBackGiftCardPurchaseComplete from './GasBackGiftCardPurchaseComplete';
import getErrorMessage from '../../../lib/utils/getErrorMessage';


function mapStateToProps({ gasback, auth, profile, config }) {
  const { email } = auth;

  const {
    error,
    details: { vendorId, amount } = {},
  } = gasback.transfer;

  const { firstName, lastName } = profile;

  return {
    firstName,
    lastName,
    vendorId,
    amount,
    email,
    error: !!error && getErrorMessage(error, 'Your digital gift card purchase could not be completed. Please try again later.'),
    consumerHost: config.consumerHost,
  };
}

export default withRouter(connect(mapStateToProps)(GasBackGiftCardPurchaseComplete));
