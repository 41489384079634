import { Action } from './actions';

const defaultState = {
  detailLoading: false,
  detailError: undefined,
  details: undefined,
  error: undefined,
  isSaving: false,
  result: undefined,
};

export default function bankReducer(state = defaultState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case Action.Bank.DetailPending: {
      return {
        ...state,
        detailError: undefined,
        detailLoading: true,
      };
    }
    case Action.Bank.DetailCompleted: {
      return {
        ...state,
        detailLoading: false,
        details: payload.details,
      };
    }
    case Action.Bank.DetailFailed: {
      return {
        ...state,
        detailError: payload,
        detailLoading: false,
        details: null,
      };
    }

    case Action.Bank.ResetBankError:
      return {
        ...state,
        error: undefined,
      };

    case Action.Bank.InstrumentCreatePending:
      return {
        ...state,
        isSaving: true,
        error: undefined,
      };

    case Action.Bank.InstrumentCreateCompleted:
      return {
        ...state,
        isSaving: false,
        result: payload,
      };

    case Action.Bank.InstrumentCreateFailed:
      return {
        ...state,
        isSaving: false,
        error: 'There was an error linking your account, please try again.',
      };

    default:
      return state;
  }
}
