import { connect } from 'react-redux';
import PremiumPanel from './PremiumPanel';

function mapStateToProps({ wallet }) {
  const upsellFeature = wallet.features.features.find(f => f.n === 'premium_upsell_panel');

  return {
    ...upsellFeature?.p,
  };
}

export default connect(mapStateToProps)(PremiumPanel);
