import { Divider, Image } from '@gasbuddy/react-components';
import { useWindowSize } from '@gasbuddy/react-hooks';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, useHistory } from 'react-router-dom';
import canonicalizeString from '../../../lib/utils/canonicalizeString';
// import { hasCreditCard, hasPayCard } from '../../../lib/utils/cardInstruments';
import dedupe from '../../../lib/utils/dedupe';
import getEnrollmentConfig from '../../../lib/utils/getEnrollmentConfig';
import isUnavailable from '../../../lib/utils/isUnavailable';
import scrollToTop from '../../../lib/utils/scrollToTop';
import useMemoState from '../../../lib/utils/useMemoState';
import EnrollmentSteps from '../../constants/enrollmentSteps';
import PayPrograms from '../../constants/payPrograms';
import PayProgramContext from '../../context/payProgram';
import usePrescreen from '../../hooks/usePrescreen';
import useWatch from '../../hooks/useWatch';
import AddressPropType from '../../prop-types/address';
import CMSPartnerPropType from '../../prop-types/cmsPartner';
import WalletPropType from '../../prop-types/wallet';
import CreditAppComplete from '../CreditAppComplete';
import GradientContainer from '../GradientContainer';
import StatusBar from '../StatusBar';
import styles from './Enrollment.module.css';

const cx = classnames.bind(styles);

function getLastMilestone(steps, activeStep) {
  const activeStepIndex = steps.indexOf(activeStep);
  const milestones = steps.slice(0, activeStepIndex + 1).filter(step => step.label);
  return milestones.length - 1;
}

function buildCMSImageSrc(cmsImage) {
  const { height, url, width } = cmsImage;
  return `https://images.gasbuddy.io/${width || 'auto'}x${height || 'auto'}/${url}`;
}

export default function Enrollment({
  address,
  basePath,
  declinedMembership,
  email,
  firstName,
  isMobile,
  isProduction,
  goToStep,
  lastName,
  location,
  login,
  loyaltyId,
  match,
  offeringId,
  onMastercardApply,
  partner,
  promo,
  shouldPrescreen,
  shouldShowCreditStatus,
  stepId,
  upgraded,
  wallet,
  welcome,
}) {
  const history = useHistory();
  const { step: routerStepId } = match.params;
  const payProgram = useContext(PayProgramContext);
  const isPrescreenEligible = payProgram === PayPrograms.Free && !partner && shouldPrescreen;
  const { isOpen: creditModalOpen, isPreApproving, outcome, triggerPrescreen } = usePrescreen(isPrescreenEligible, {
    address,
    email,
    firstName,
    isMobile,
    isProduction,
    lastName,
    loyaltyId,
  });
  const memoWallet = useMemoState(wallet);
  const steps = useMemo(() => {
    const hasPremium = !!memoWallet.membership?.roadside_member_id;
    const hasPlus = !!memoWallet.membership && !hasPremium;

    return getEnrollmentConfig(memoWallet, {
      wantsPlus: payProgram === PayPrograms.Plus,
      wantsPremium: payProgram === PayPrograms.Premium,
      hasPlus,
      hasPremium,
      completedBilling: typeof offeringId !== 'undefined',
      partner,
      showLogin: login,
      showPromoEntry: promo,
      showUpgraded: upgraded,
      showWelcome: welcome,
    });
  }, [login, offeringId, partner, payProgram, promo, upgraded, memoWallet, welcome]);
  // This is the most advanced step that the user can access
  const latestStep = steps.find(step => step.requiresAttention);
  // This is a programmatic override in case a user requests a previously completed step
  const activeStep = steps.find(step => step.id === stepId);
  // This is the step that we show the user
  const currentStep = activeStep || latestStep;
  const isValidRoute = useCallback((id) => {
    const index = steps.findIndex(step => step.id === id);
    const latestIndex = steps.indexOf(latestStep);
    return index > -1 && index <= latestIndex;
  }, [latestStep, steps]);
  const routerStepChanged = useWatch(routerStepId);
  const currentStepId = currentStep.id;
  const currentStepChanged = useWatch(currentStepId, isValidRoute(routerStepId)); // Prevent initial history update if router loads with state
  const { mobile: isMobileWindow } = useWindowSize();
  const isEnrolling = !login && !promo && !welcome;
  let status;

  // HOLD: will comment back in once we have approval from ADS to trigger prescreen on other screens
  // useEffect(() => {
  //   // If the user does not have a pay card nor credit card, but has a shipping address, trigger prescreen
  //   if (!hasPayCard(memoWallet.instruments) && !hasCreditCard(memoWallet.instruments) && !isUnavailable(address)) {
  //     triggerPrescreen();
  //   }
  // }, [address, memoWallet.instruments, triggerPrescreen]);

  useEffect(() => {
    if (!isUnavailable(outcome)) {
      onMastercardApply(outcome);
    }
  }, [onMastercardApply, outcome]);

  const canonicalPath = canonicalizeString(location?.pathname);

  const updateEditStep = useCallback((id) => {
    goToStep(isValidRoute(id) && latestStep.id !== id ? id : undefined);
  }, [goToStep, isValidRoute, latestStep.id]);

  const updateHistory = useCallback((id) => {
    const path = `${basePath}/enroll/${id}`;

    if (isEnrolling && !isValidRoute(routerStepId)) {
      // Ensure that this moment in history is associated with a screen
      history.replace(path);
    } else if (routerStepId && id !== routerStepId) {
      history.push(path);
    }
  }, [basePath, history, isEnrolling, isValidRoute, routerStepId]);

  // If the history changes, make sure that it's reflected in Redux
  useEffect(() => {
    if (routerStepChanged) {
      updateEditStep(routerStepId);
    }
  }, [routerStepChanged, routerStepId, updateEditStep]);

  // If the current step changes, make sure that it's reflected in history
  useEffect(() => {
    if (currentStepChanged) {
      updateHistory(currentStepId);
    }
  }, [currentStepChanged, currentStepId, updateHistory]);

  const handleBackClick = useCallback((e) => {
    e.preventDefault();
    const previousIndex = steps.indexOf(currentStep) - 1;
    updateEditStep(steps[previousIndex].id);
  }, [currentStep, steps, updateEditStep]);

  const handleSkipClicked = useCallback((e) => {
    e.preventDefault();
    const nextIndex = steps.indexOf(currentStep) + 1;
    updateEditStep(steps[nextIndex].id);
  }, [currentStep, steps, updateEditStep]);

  // Scroll to top of window when the user advances
  useEffect(() => {
    scrollToTop();
  }, [currentStep.progressBar]);

  // If the user is on a premium screen and declined membership, bring them to free screen
  if ([PayPrograms.Plus, PayPrograms.Premium].includes(payProgram) && declinedMembership) {
    return (
      <Redirect to="/enroll" />
    );
  }

  // All enrollment steps have a progress bar except for maybe an account error page
  if (currentStep.progressBar) {
    const progressBarSteps = steps.filter(step => step.progressBar === currentStep.progressBar);
    const progressBarMilestones = progressBarSteps.filter(step => step.label);
    const labels = progressBarMilestones.map(form => form.label);

    status = (
      <StatusBar
        steps={dedupe(labels)}
        activeIndex={getLastMilestone(progressBarSteps, currentStep)}
        progress={currentStep.progressBar === 'activation'}
      />
    );
  }

  const Component = currentStep.component;

  const canGoBack = [
    EnrollmentSteps.DriversLicense,
    EnrollmentSteps.BankAccount,
    EnrollmentSteps.Billing,
    EnrollmentSteps.Review,
  ].includes(currentStep.id);

  const canGoForward = [
    EnrollmentSteps.ShippingAddress,
    EnrollmentSteps.DriversLicense,
    EnrollmentSteps.BankAccount,
    EnrollmentSteps.Billing,
  ].includes(currentStep.id) && (currentStepId !== latestStep.id);

  let logo;

  if (partner?.priorityLogo) {
    logo = (
      <Image
        alt={`${partner.name} Logo`}
        className={cx('brand', 'partner')}
        src={buildCMSImageSrc(partner.priorityLogo)}
      />
    );
  } else {
    const gasBuddyLogo = (
      <Image
        alt="GasBuddy Logo"
        src="https://static.gasbuddy.com/web/pay/svg/gb-blue-horizontal-rgb.svg"
      />
    );

    if (partner) {
      const partnerLogo = (isMobileWindow && partner.mobileLogo) || partner.logo;
      logo = (
        <div className={cx('cobrand')}>
          <div className={cx('brand')}>
            {gasBuddyLogo}
          </div>
          <Divider flex thick vertical />
          <div className={cx('brand', 'partner')}>
            <Image
              alt={`${partner.name} Logo`}
              src={buildCMSImageSrc(partnerLogo)}
            />
          </div>
        </div>
      );
    } else {
      logo = gasBuddyLogo;
    }
  }

  const showCreditModal = payProgram === PayPrograms.Free && shouldShowCreditStatus && !creditModalOpen && !promo;

  const mainContent = (
    <Component
      basePath={basePath}
      goToStep={updateEditStep}
      isPreApproving={isPreApproving}
      program={payProgram}
      triggerPrescreen={triggerPrescreen}
    />
  );

  return (
    <React.Fragment>
      <Helmet>
        <link rel="canonical" href={`https://enroll.gasbuddy.com${canonicalPath}`} />
      </Helmet>
      {showCreditModal && (
        <CreditAppComplete />
      )}
      {currentStep.fullscreen !== true ? (
        <GradientContainer
          extra={currentStep.extra && React.createElement(currentStep.extra)}
          logo={logo}
          onBackClick={canGoBack ? handleBackClick : undefined}
          onSkipClick={canGoForward ? handleSkipClicked : undefined}
          sidebar={status}
          shouldShowFooter={!currentStep.hideFootnote}
          title={currentStep.title}
        >
          {mainContent}
        </GradientContainer>
      ) : mainContent }
    </React.Fragment>
  );
}

Enrollment.propTypes = {
  address: AddressPropType,
  basePath: PropTypes.string,
  declinedMembership: PropTypes.bool,
  email: PropTypes.string,
  firstName: PropTypes.string,
  goToStep: PropTypes.func,
  isMobile: PropTypes.bool,
  isProduction: PropTypes.bool,
  lastName: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.shape(),
  }),
  login: PropTypes.bool,
  loyaltyId: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      step: PropTypes.string,
    }),
  }),
  offeringId: PropTypes.number,
  onMastercardApply: PropTypes.func,
  partner: CMSPartnerPropType,
  promo: PropTypes.bool,
  shouldPrescreen: PropTypes.bool,
  shouldShowCreditStatus: PropTypes.bool,
  stepId: PropTypes.string,
  upgraded: PropTypes.bool,
  wallet: WalletPropType,
  welcome: PropTypes.bool,
};

Enrollment.defaultProps = {
  address: undefined,
  basePath: '',
  declinedMembership: false,
  email: '',
  firstName: undefined,
  goToStep: () => { },
  isMobile: false,
  isProduction: false,
  lastName: undefined,
  location: {
    pathname: '',
  },
  login: false,
  loyaltyId: undefined,
  match: {
    params: {},
  },
  offeringId: undefined,
  onMastercardApply: () => { },
  partner: undefined,
  promo: false,
  shouldPrescreen: false,
  shouldShowCreditStatus: false,
  stepId: undefined,
  upgraded: false,
  wallet: {},
  welcome: false,
};
