/* global BreadPayments */
import { CREDIT_APPLICATION_STATUS } from '@gasbuddy/react-consumer-components';
import { useCallback, useEffect, useState } from 'react';
import logEvent from '../../lib/utils/logEvent';

const ADS_LOCATION = {
  Homepage: 'homepage',
  Landing: 'landing',
  Search: 'search',
  Product: 'product',
  Category: 'category',
  Banner: 'banner',
  Checkout: 'checkout',
  Cart: 'cart',
};

const BreadEvents = [
  'CARD:RECEIVE_APPLICATION_RESULT',
  'INSTALLMENT:APPLICATION_DECISIONED',
  'INSTALLMENT:APPLICATION_CHECKOUT',
  'CARD:SUBMIT_APPLICATION',
  'CARD:SUBMIT_PRESCREEN_APPLICATION',
  'CARD:SHOW_OVERLAY',
  'CARD:CLOSE_OVERLAY',
  'CARD:VIEW_PAGE',
  'RECEIVE_APPLICATION_RESULT',
];

export default function usePrescreen(isPrescreenEligible, info = {}) {
  const [{ isOpen, isPreApproving, outcome }, setStatus] = useState({
    isPreApproving: false,
    isOpen: false,
    outcome: undefined,
  });
  const updateStatus = useCallback(newStatus => setStatus(oldStatus => ({ ...oldStatus, ...newStatus })), []);

  const triggerPrescreen = useCallback(async (
    stepAddress,
    stepFirstName,
    stepLastName,
  ) => {
    if (isPrescreenEligible) {
      updateStatus({ isPreApproving: true });

      const address = info?.address || stepAddress;
      const firstName = info?.firstName || stepFirstName;
      const lastName = info?.lastName || stepLastName;
      const { email, isMobile, isProduction, loyaltyId: loyaltyID } = info;

      BreadPayments.setup({
        storeNumber: isMobile ? 9993 : 9991,
        loyaltyID,
        env: isProduction ? 'PROD' : 'STAGE',
        existingCardHolder: false,
        buyer: {
          givenName: firstName,
          familyName: lastName,
          billingAddress: {
            address1: address.line_1,
            country: address.country,
            locality: address.locality,
            region: address.region,
            postalCode: address.postal_code.slice(0, 5),
          },
          email,
        },
      });

      const listen = eventName => BreadPayments.on(eventName, response => logEvent(eventName, response));
      BreadEvents.forEach(listen);

      BreadPayments.on('CARD:RECEIVE_APPLICATION_RESULT', (response) => {
        updateStatus({
          outcome: {
            phone: response.mobilePhone,
            result: response.result,
          },
        });
      });

      BreadPayments.on('CARD:SHOW_OVERLAY', () => {
        updateStatus({ isOpen: true, isPreApproving: false });
      });

      // if they exit early, we assume they are declined and can return an undefined result
      // if not, then we've already resolved their action in RECEIVE_APPLICATION_RESULT or OFFER_RESPONSE
      // when they close the overlay
      BreadPayments.on('CARD:CLOSE_OVERLAY', () => {
        setStatus(oldStatus => ({
          ...oldStatus,
          isOpen: false,
          outcome: oldStatus.outcome || {
            result: CREDIT_APPLICATION_STATUS.Declined,
          },
        }));
      });

      BreadPayments.on('CARD:OFFER_RESPONSE', (response) => {
        // Only resolve if offer was dismissed/declined by user, ignore 'NOT_ME'
        if (['NO', 'ABANDONED'].includes(response)) {
          updateStatus({
            outcome: {
              result: CREDIT_APPLICATION_STATUS.Dismissed,
            },
          });
        }
      });

      BreadPayments.submitRtps({
        financingType: 'card',
        location: ADS_LOCATION.Product,
        customerNumber: loyaltyID,
        country: 'US',
      });
    }
  }, [info, isPrescreenEligible, updateStatus]);

  // If the user isn't shown the application within 5 seconds
  useEffect(() => {
    if (isPreApproving && !isOpen) {
      const timeout = setTimeout(() => {
        updateStatus({
          isPreApproving: false,
          outcome: {
            result: CREDIT_APPLICATION_STATUS.Ineligible,
          },
        });
      }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    }

    return undefined;
  }, [isOpen, isPreApproving, updateStatus]);

  return { isOpen, isPreApproving, outcome, triggerPrescreen };
}
