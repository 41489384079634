import { isValidUSPostal, isValidCAPostal, isValidAUPostal } from './isValidPostal';

export default function getCountryCodeFromPostal(postalCode) {
  if (isValidUSPostal(postalCode)) {
    return 'US';
  }

  if (isValidCAPostal(postalCode)) {
    return 'CA';
  }

  if (isValidAUPostal(postalCode)) {
    return 'AU';
  }

  return undefined;
}
