const AccountStatus = {
  Unenrolled: 'unenrolled',
  PartiallyEnrolled: 'partially_enrolled',
  WaitingForMicrodeposits: 'waiting_for_microdeposits',
  WaitingForSecondaryVerification: 'waiting_for_secondary_verification',
  WaitingForCardActivation: 'waiting_for_card_activation',
  WaitingForMicrodepositConfirmation: 'waiting_for_microdeposit_confirmation',
  WaitingForMicrodepositConfirmationWithOutstandingBalance: 'waiting_for_microdeposit_confirmation_with_outstanding_balance',
  FailedMicrodeposit: 'failed_microdeposit',
  ReadyToTransact: 'ready_to_transact',
  CardDeactivatedGeneric: 'card_deactivated_generic',
  CardDeactivatedNSF: 'card_deactivated_nsf',
  CardDeactivatedManual: 'card_deactivated_manual',
  WaitingForNewCardActivation: 'waiting_for_new_card_activation',
  WaitingForManualReactivation: 'waiting_for_manual_reactivation',
  WaitingForMicrodepositConfirmationForReactivation: 'waiting_for_microdeposit_confirmation_for_reactivation',
  BankDeletedRelinkNeeded: 'bank_deleted_relink_needed',
  NSFDuringSecondaryVerification: 'nsf_during_secondary_verification',
  BalanceSettlementPending: 'balance_settlement_pending',
  Collections: 'collections',
  Dynamic: 'dynamic',
  Error: 'error',
};

export default AccountStatus;
