import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import { RIBBITConnect as ValidiFiWidget } from 'react-ribbit-connect';
import styles from './IavButton.module.css';
import capitalize from '../../../lib/utils/capitalize';
import { ANALYTICS_EVENTS, ANALYTICS_SCREENS } from '../../constants/analytics';
import useTracking from '../../hooks/useTracking';

const cx = classnames.bind(styles);

export function withIav(WrappedComponent) {
  function WithValidifi({
    env,
    bankAccountError,
    isCreatingInstrument,
    createBankInstrument,
    iavEnv,
    iavSessionToken,
    program,
    switchToManual,
    ...rest
  }) {
    const [shouldShowWidget, setShouldShowWidget] = useState(false);
    const { trackEvent, trackPageView } = useTracking();

    const openValidiFiWidget = useCallback(() => {
      setShouldShowWidget(true);
    }, []);

    const closeValidiFiWidget = useCallback(() => {
      setShouldShowWidget(false);
    }, []);

    const handleOnManualEnrolmentSwitch = useCallback(() => {
      setShouldShowWidget(false);
      switchToManual();
    }, [switchToManual]);

    const handleLaunch = useCallback((metadata) => {
      trackPageView(ANALYTICS_SCREENS.PAY_ENROLL_BANK_SEARCH, {
        ...metadata,
        provider: 'ValidiFi',
      });
    }, [trackPageView]);

    const handleBankLoginSelected = useCallback((metadata) => {
      trackEvent(ANALYTICS_EVENTS.PAY_ENROLL_BANK_SEARCH_COMPLETE, {
        ...metadata,
        provider: 'ValidiFi',
      });
    }, [trackEvent]);

    const handleNoAccountsFound = useCallback((metadata) => {
      trackEvent(ANALYTICS_EVENTS.PAY_ENROLL_BANK_SEARCH_NO_ACCOUNTS_FOUND, {
        ...metadata,
        provider: 'ValidiFi',
      });
    }, [trackEvent]);

    const handleBankNotFound = useCallback((metadata) => {
      trackEvent(ANALYTICS_EVENTS.PAY_ENROLL_BANK_NOT_FOUND, {
        ...metadata,
        provider: 'ValidiFi',
      });
    }, [trackEvent]);

    const handleBankLogin = useCallback((metadata) => {
      trackEvent(ANALYTICS_EVENTS.PAY_ENROLL_BANK_LOGIN, {
        ...metadata,
        provider: 'ValidiFi',
      });
    }, [trackEvent]);

    const handleLinkOpen = useCallback((metadata) => {
      trackEvent(ANALYTICS_EVENTS.PAY_ENROLL_BANK_SEARCH_COMPLETE, {
        ...metadata,
        provider: 'ValidiFi',
      });
    }, [trackEvent]);

    const onSuccess = useCallback((validifiResponse) => {
      const { accountToken, sessionToken } = validifiResponse;
      createBankInstrument({
        iavProviderToken: sessionToken,
        validifiAccountToken: accountToken || undefined,
        program,
      });
    }, [createBankInstrument, program]);
    //
    // Uncomment below block to turn on debugging messages for onMessage events from ValidiFi widget
    //
    // const onMessage = useCallback((event, data) => {
    //   if (['development', 'staging'].includes(env)) {
    //     // eslint-disable-next-line no-console
    //     console.info(event, data);
    //   }
    // }, [env]);

    // NOTE: This should never practically render as the IAV details are eagerly fetched by parent
    // before IavButton can be rendered, however this is a safeguard to ensure we don't present ValidiFi
    // provided unstyled popup from our end with missing environment or session token detail
    if (!iavEnv || !iavSessionToken) {
      return (
        <Text as="p" color="orange">
          Instant account verification is unavailable, please link account manually.
        </Text>
      );
    }

    return (
      <Fragment>
        {!!bankAccountError && (
          <Text as="p" color="orange">{bankAccountError}</Text>
        )}
        <ValidiFiWidget
          className={cx('validiFiWidget')}
          environment={capitalize(iavEnv)}
          onComplete={onSuccess}
          onExit={closeValidiFiWidget}
          onLaunch={handleLaunch}
          onBankLoginSelected={handleBankLoginSelected}
          onNoAccountsFound={handleNoAccountsFound}
          onBankNotFound={handleBankNotFound}
          onBankLogin={handleBankLogin}
          onLinkOpen={handleLinkOpen}
          onManualEnrollmentSelected={handleOnManualEnrolmentSwitch}
          // onMessage={onMessage}
          open={shouldShowWidget}
          settings={{
            curtainColor: 'rgba(0,0,0,0.5)',
            canOpenNewWindows: true,
            closeButton: true,
          }}
          token={iavSessionToken}
        />
        <WrappedComponent {...rest} onClick={openValidiFiWidget} />
      </Fragment>
    );
  }

  WithValidifi.propTypes = {
    env: PropTypes.string,
    bankAccountError: PropTypes.string,
    isCreatingInstrument: PropTypes.bool,
    createBankInstrument: PropTypes.func.isRequired,
    iavEnv: PropTypes.string,
    iavSessionToken: PropTypes.string,
    program: PropTypes.string,
    switchToManual: PropTypes.func,
  };

  WithValidifi.defaultProps = {
    env: 'production',
    bankAccountError: undefined,
    isCreatingInstrument: false,
    iavEnv: 'production',
    iavSessionToken: undefined,
    program: undefined,
    switchToManual: () => { },
  };

  WithValidifi.displayName = `WithValidifi(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return WithValidifi;
}

function IavButton(props) {
  return (
    <Button primary {...props} />
  );
}

IavButton.propTypes = {
  children: PropTypes.node,
};

IavButton.defaultProps = {
  children: 'Link Checking Account',
};

export default withIav(IavButton);
