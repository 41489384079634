import { Action } from './actions';

export const defaultState = {
  canRenew: false,
  instrumentId: undefined,
  isOverdue: false,
  isRenewing: false,
  reshipped: false,
  shipDate: undefined,
  wasRenewed: false,
  wasReshipped: false,
  wasReturned: false,
};

export default function payCardReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case Action.Renew.RenewCardPending:
      return {
        ...state,
        isRenewing: true,
      };

    case Action.Renew.RenewCardFailed:
      return {
        ...state,
        error: action.payload,
        isRenewing: false,
      };

    case Action.Renew.RenewCardCompleted:
      return {
        ...state,
        isOverdue: false,
        isRenewing: false,
        wasRenewed: true,
      };

    case Action.Reship.ReshipCardCompleted:
      return {
        ...state,
        isOverdue: false,
        wasReshipped: true,
      };

    default:
      return state;
  }
}
