const redeemFAQs = [
  {
    question: 'What can a code be redeemed for?',
    answer: 'Codes may be redeemed for GasBack, which is added directly to your GasBuddy account. GasBack Rewards expire 12 months after issuance.',
  },
  {
    question: 'Where can I find my code?',
    answer: 'Codes are typically issued from GasBuddy affiliates. Watch for communications from us alerting you of more ways to receive codes.',
  },
  {
    question: 'What if I haven\'t received a code?',
    answer: 'If you were issued a code but haven\'t received it, contact the affiliate that issued it.',
  },
  {
    question: 'How long does it take to receive a code?',
    answer: 'Timing can vary, but typically you will receive codes within 24 hours.',
  },
  {
    question: 'How many times can I enter the code?',
    answer: 'Each code can only be redeemed once but you can enter as many unique codes as you\'d like.',
  },
  {
    question: 'Once I\'ve entered my code, how long will it take to get my GasBack Rewards?',
    answer: 'It usually takes about 24 hours for GasBack Rewards to appear in your GasBuddy account.',
  },
];

export default redeemFAQs;
