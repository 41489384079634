import { connect } from 'react-redux';
import GasBackPanel from './GasBackPanel';

function isFeatured(offer) {
  return offer.tags?.includes('featured_web');
}

function byFeatured(a, b) {
  if (!isFeatured(a)) {
    return 1;
  }
  return isFeatured(b) ? 0 : -1;
}

function mapStateToProps({ gasback }) {
  return {
    offers: gasback.offers.sort(byFeatured).slice(0, 3),
  };
}

export default connect(mapStateToProps)(GasBackPanel);
