import {
  Button,
  Constraint,
  Container,
  FlexGrid,
  Form,
  Header,
  LoadingMask,
  Text,
} from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import formatPrice from '../../../lib/utils/formatPrice';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import useTracking from '../../hooks/useTracking';
import styles from './ProgramSwitch.module.css';

const cx = classnames.bind(styles);

function formatProgramDiscount(program, { add_on: extraAddOn } = {}) {
  const addOn = extraAddOn || program.extra_data?.add_on;

  if (addOn) {
    const baseDiscount = parseFloat(program.per_gallon_discount);
    const addonDiscount = parseFloat(addOn.amount);
    // Use toFixed to avoid issues with adding parseFloat values
    const totalDiscount = (baseDiscount + addonDiscount).toFixed(2);
    return `${formatPrice(totalDiscount)} per gallon discount up to ${addOn.qty} gallons per month`;
  }

  const originalDiscount = formatPrice(program.per_gallon_discount);
  return `${originalDiscount} per gallon base discount`;
}

function ProgramSwitch({
  className,
  partner,
  performSwitch,
  program,
  skipSwitch,
}) {
  const { trackEvent } = useTracking();
  const { consumerHost } = useSelector(state => state.config);
  const { switchedProgram, switchingProgram } = useSelector(state => state.progress);
  const partnerName = partner.name;

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    trackEvent(ANALYTICS_EVENTS.PARTNER_OFFER_ACCEPT_CLICKED, {
      partner: partnerName,
    });

    performSwitch();
  }, [partnerName, performSwitch, trackEvent]);

  const handleNoClick = useCallback((e) => {
    e.preventDefault();

    trackEvent(ANALYTICS_EVENTS.PARTNER_OFFER_DECLINE_CLICKED);

    skipSwitch();
  }, [skipSwitch, trackEvent]);

  if (switchedProgram) {
    return (
      <Constraint centered className={cx(className)} tablet={8} desktop={7}>
        <Header size="sm">Congratulations!</Header>
        <Text as="p" size="lg">You are now in the {partnerName} Program.</Text>
        <br />
        <Button as="a" href={`//${consumerHost}/account/savings`} fluid primary>Continue</Button>
      </Constraint>
    );
  }

  if (partner.error) {
    return (
      <Container className={cx(className)}>
        <Constraint centered tablet={8} desktop={7}>
          <Header size="sm">{partnerName} Program</Header>
          <Text as="p" size="lg">{partner.error}</Text>
          <br />
          <Button as="a" href="/partner/clear" fluid onClick={handleNoClick} primary>Continue</Button>
        </Constraint>
      </Container>
    );
  }

  const currentDiscount = formatProgramDiscount(program);
  const futureDiscount = formatProgramDiscount(partner.program, program.extra_data);

  return (
    <LoadingMask className={cx(className)} isLoading={switchingProgram}>
      <Constraint centered tablet={8} desktop={7}>
        <Text as="p" size="lg">You are currently enrolled in Pay with GasBuddy with a {currentDiscount}.</Text>
        <Text as="p" size="lg">
          Would you like to switch your account to the <Text bold>{partnerName}</Text> GasBuddy program with a {futureDiscount}?
        </Text>
        <br />
        <FlexGrid container>
          <FlexGrid.Column mobile={7}>
            <Form
              aria-label="Program Switch Form"
              action="/partner"
              method="POST"
              onSubmit={handleSubmit}
            >
              <Button fluid primary type="submit">
                Yes, Switch
              </Button>
            </Form>
          </FlexGrid.Column>
          <FlexGrid.Column mobile={5}>
            <Button as="a" href="/partner/clear" fluid onClick={handleNoClick} secondary>
              Not Now
            </Button>
          </FlexGrid.Column>
        </FlexGrid>
      </Constraint>
    </LoadingMask>
  );
}

ProgramSwitch.propTypes = {
  className: PropTypes.string,
  partner: PropTypes.shape({
    error: PropTypes.string,
    name: PropTypes.string.isRequired,
    program: PropTypes.shape({
      per_gallon_discount: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  performSwitch: PropTypes.func,
  program: PropTypes.shape({
    extra_data: PropTypes.shape({
      add_on: PropTypes.shape({
        amount: PropTypes.string,
        qty: PropTypes.number,
      }),
    }),
    name: PropTypes.string.isRequired,
    per_gallon_discount: PropTypes.string.isRequired,
  }).isRequired,
  skipSwitch: PropTypes.func,
};

ProgramSwitch.defaultProps = {
  className: undefined,
  performSwitch: () => {},
  skipSwitch: () => {},
};

export default ProgramSwitch;
