import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './Indicator.module.css';

const cx = classnames.bind(styles);

const InnerCircle = () => (
  <div
    className={cx('filled')}
  />
);

export default function Indicator({
  isActive,
  children,
  copy,
  progress,
  done,
}) {
  return (
    <div className={cx('container', { progress })}>
      <div
        className={cx(
          {
            indicator: !progress,
            active: !progress && isActive,
          },
        )}
      >
        <div className={cx('innerCircle')}>
          {!progress && !done ? children : <InnerCircle />}
        </div>
      </div>
      <span className={cx('copy')}>
        {copy}
      </span>
    </div>
  );
}

Indicator.propTypes = {
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.element]),
  copy: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  progress: PropTypes.bool,
  done: PropTypes.bool,
};

Indicator.defaultProps = {
  children: <InnerCircle />,
  progress: false,
  done: false,
};
