import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Input, CheckMarkIcon, CloseIcon } from '@gasbuddy/react-components';
import getCountryCodeFromPostalCode from '../../../lib/utils/getCountryFromPostal';
import styles from './PostalCodeInput.module.css';

const cx = classnames.bind(styles);

export default function PostalCodeInput({
  name,
  label,
  error,
  className,
  onChange,
  onBlur,
  inline,
  countries,
  required,
  value,
  ...rest
}) {
  const [inputValue, setInputValue] = React.useState(value);
  const [countryCode, setCountryCode] = React.useState(() => getCountryCodeFromPostalCode(value));
  const [isValid, setIsValid] = React.useState(countryCode !== undefined ? true : undefined);

  React.useEffect(() => {
    let newCountryCode;
    let newIsValid;

    if (inputValue !== '') {
      newCountryCode = getCountryCodeFromPostalCode(inputValue);
      newIsValid = newCountryCode !== undefined && countries.includes(newCountryCode);
    }

    setCountryCode(newCountryCode);
    setIsValid(newIsValid);
  }, [inputValue, countries]);

  const handleChange = React.useCallback((e) => {
    e.preventDefault();

    setInputValue(e.target.value);

    onChange(e);
  }, [onChange]);

  let validityIcon;

  if (isValid === true) {
    validityIcon = (
      <CheckMarkIcon
        bordered
        className={cx('icon', 'valid')}
      />
    );
  } else if (isValid === false) {
    validityIcon = (
      <CloseIcon
        bordered
        className={cx('icon', 'invalid')}
      />
    );
  }

  return (
    <Input
      className={cx('postalCodeInput', className, { hasError: !!error })}
      innerComponent={isValid !== undefined
        && (
          validityIcon
        )
      }
      inline={inline}
      name={name}
      onChange={handleChange}
      onBlur={onBlur}
      autoComplete="postal-code"
      label={label}
      error={error}
      required={required}
      value={value}
      {...rest}
    />
  );
}

PostalCodeInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  inline: PropTypes.bool,
  value: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.oneOf(['US', 'CA', 'AU'])),
};

PostalCodeInput.defaultProps = {
  name: undefined,
  label: undefined,
  error: undefined,
  className: undefined,
  required: false,
  onChange: () => { },
  onBlur: () => { },
  inline: false,
  value: '',
  countries: ['US', 'CA', 'AU'],
};
