import { didCompleteApplication } from '../../lib/mastercard';
import { Action } from './actions';

const defaultState = {
  creditStatus: undefined,
  shouldShowStatus: false,
  shouldPrescreen: true,
  stepId: undefined,
  email: undefined,
  skipWelcome: false,
  postal: undefined,
  offeringId: undefined,
  switchingProgram: false,
  switchedProgram: false,
};

export default function progressReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case Action.Progress.GoToStep:
      return {
        ...state,
        stepId: action.payload,
      };

    case Action.ShippingAddress.UpdateCompleted:
    case Action.License.LicenseSaveCompleted:
    case Action.Profile.ProfileUpdateCompleted:
    case Action.Microdeposits.ConfirmCompleted:
    case Action.Activation.ActivateCardCompleted:
      return {
        ...state,
        stepId: undefined,
      };

    case Action.Mastercard.SubmitResultCompleted:
      return {
        ...state,
        creditStatus: action.meta.result,
        shouldShowStatus: didCompleteApplication(action.meta.result),
        shouldPrescreen: false,
      };

    case Action.Bank.InstrumentCreateCompleted:
      return {
        ...state,
        editIndex: undefined,
        partner: undefined,
        stepId: undefined,
      };

    case Action.Program.SwitchProgram:
      return {
        ...state,
        switchingProgram: true,
      };

    case Action.Program.SwitchProgramFailed:
      return {
        ...state,
        switchingProgram: false,
      };

    case Action.Program.SwitchProgramCompleted:
      return {
        ...state,
        switchingProgram: false,
        switchedProgram: true,
      };

    case Action.Program.ClearProgramCompleted:
      return {
        ...state,
        partner: undefined,
        switchedProgram: false,
      };

    case Action.Billing.SetOfferingId:
      return {
        ...state,
        offeringId: action.payload,
        stepId: undefined,
      };

    default:
      return state;
  }
}
