import { connect } from 'react-redux';
import VerifyGasBackBankTransfer from './VerifyGasBackBankTransfer';
import { confirmMicrodeposits } from '../../reducers/actions';
import getMDReadyAccount from '../../../lib/utils/getMDReadyAccount';

function mapStateToProps({ config, gasback, instruments, wallet }) {
  const bankAccount = getMDReadyAccount(wallet.instruments);

  return {
    consumerHost: config.consumerHost,
    instrumentId: bankAccount.instrument_id,
    isLoading: instruments.isConfirmingDeposits,
    error: instruments.depositConfirmError,
    lastFour: bankAccount.last_digits,
    success: instruments.didConfirmMicrodeposits,
    transferAmount: gasback.transfer.balance,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (e, body) => {
      e.preventDefault();
      dispatch(confirmMicrodeposits(body));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyGasBackBankTransfer);
