import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Form, FlexGrid, Text, Input, Loader, Header } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import GasBackTransferStep from '../GasBackTransferStep';
import ChequeHelper from '../ChequeHelper';
import styles from './GasBackBankTransferForm.module.css';
import { VALID_ROUTING_NUMBER_LENGTH, MINIMUM_ACCOUNT_NUMBER_LENGTH } from '../../constants';
import { VALID_INTEGER_REGEX } from '../../../lib/utils/isValidInteger';

const cx = classnames.bind(styles);

export default function GasBackBankTransferForm({
  consumerHost,
  balance,
  recipientEmail,
  bankAccountError,
  detailLoading,
  fetchBankDetails,
  isSaving,
  onSubmit,
  routingNumberError,
  history,
}) {
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [focused, setFocused] = useState();

  const routingNumberFormatValid = routingNumber.length === VALID_ROUTING_NUMBER_LENGTH;
  const accountNumberFormatValid = accountNumber.length > MINIMUM_ACCOUNT_NUMBER_LENGTH;
  const canSubmit = routingNumberFormatValid && accountNumberFormatValid && !routingNumberError && !bankAccountError;

  const onAccountFocus = useCallback(() => setFocused('account'), []);

  const onRoutingFocus = useCallback(() => setFocused('routing'), []);

  const onAccountBlur = useCallback(() => setFocused(focused === 'account' ? undefined : focused), [focused]);

  const onRoutingBlur = useCallback(() => {
    if (routingNumberFormatValid) {
      fetchBankDetails(routingNumber);
    }

    setFocused(focused === 'routing' ? undefined : focused);
  }, [focused, routingNumber, fetchBankDetails, routingNumberFormatValid]);

  const handleRoutingNumberChange = useCallback(({ target }) => {
    setRoutingNumber(target.value);
  }, []);

  const handleAccountNumberChange = useCallback(({ target }) => {
    setAccountNumber(target.value);
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    let isValid = true;

    if (!routingNumberFormatValid) {
      setErrorMessage('Routing number must be 9 characters');
      isValid = false;
    }

    if (!isValid) {
      e.preventDefault();
    } else {
      setErrorMessage(undefined);
      onSubmit(e, {
        routingNumber,
        accountNumber,
        amount: balance,
        recipientEmail,
      });
      history.push('/gasback/transfer/bank/confirm');
    }
  }, [routingNumber, accountNumber, onSubmit, routingNumberFormatValid, history, balance, recipientEmail]);

  if (!balance || !recipientEmail) {
    return (
      <Redirect to="/gasback/transfer" />
    );
  }

  return (
    <GasBackTransferStep backLinkText="Choose Transfer Type">
      <Header as="h3" snug>What bank account do you want to transfer funds to?</Header>
      <Text as="p">Enter the account and routing number you want the money deposited into.</Text>
      <br />
      <Form aria-label="GasBack Bank Transfer Form" method="post" action="/gasback/transfer/bank" onSubmit={handleSubmit} loading={isSaving}>
        <FlexGrid className={cx('bankAccountFormContainer')}>
          <FlexGrid.Column tablet={6}>
            <Text bold as="label" htmlFor="routingNumber">Routing Number: </Text>
            <Input
              id="routingNumber"
              mask={VALID_INTEGER_REGEX}
              name="routingNumber"
              value={routingNumber}
              placeholder="Routing number"
              isValid={routingNumberFormatValid && !routingNumberError}
              onChange={handleRoutingNumberChange}
              onFocus={onRoutingFocus}
              onBlur={onRoutingBlur}
              required
              innerComponent={
                !!detailLoading && (
                  <Loader size="sm" />
                )
              }
            />
            <Text bold as="label" htmlFor="accountNumber">Account Number:</Text>
            <Input
              id="accountNumber"
              mask={VALID_INTEGER_REGEX}
              name="accountNumber"
              value={accountNumber}
              placeholder="Account number"
              onChange={handleAccountNumberChange}
              onFocus={onAccountFocus}
              onBlur={onAccountBlur}
              required
            />
          </FlexGrid.Column>
          <FlexGrid.Column tablet={6} className={cx('chequeHelperContainer')}>
            <ChequeHelper focus={focused} />
          </FlexGrid.Column>
        </FlexGrid>
        <input type="hidden" name="amount" value={balance} />
        <input type="hidden" name="recipientEmail" value={recipientEmail} />
        <br />
        <br />
        {!!(routingNumberError || bankAccountError || errorMessage) && (
          <Text as="p" color="orange">
            {routingNumberError || bankAccountError || errorMessage}
          </Text>
        )}
        <GasBackTransferStep.Actions>
          <GasBackTransferStep.Button
            primary
            fluid
            type="submit"
            disabled={!canSubmit}
          >
            Continue
          </GasBackTransferStep.Button>
          <GasBackTransferStep.Button link as="a" href={`//${consumerHost}/account/savings`}>
            Cancel
          </GasBackTransferStep.Button>
        </GasBackTransferStep.Actions>
      </Form>
    </GasBackTransferStep>
  );
}

GasBackBankTransferForm.propTypes = {
  consumerHost: PropTypes.string,
  balance: PropTypes.string,
  recipientEmail: PropTypes.string,
  bankAccountError: PropTypes.string,
  detailLoading: PropTypes.bool,
  fetchBankDetails: PropTypes.func,
  isSaving: PropTypes.bool,
  onSubmit: PropTypes.func,
  routingNumberError: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

GasBackBankTransferForm.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
  balance: undefined,
  recipientEmail: undefined,
  bankAccountError: undefined,
  detailLoading: false,
  fetchBankDetails: () => { },
  isSaving: false,
  onSubmit: () => { },
  routingNumberError: undefined,
  history: {
    push: () => { },
  },
};
