import { useCallback, useContext, useEffect } from 'react';
import AnalyticsContext from '../context/analytics';
import PayProgramContext from '../context/payProgram';
import GoogleTagManager from '../../lib/utils/gtm';

// TODO: Once GTM lives within useAnalytics, trackEvent and trackPageView will not need to be re-specified here
export default function useTracking(autoTrackScreenName) {
  const analytics = useContext(AnalyticsContext);
  const payProgram = useContext(PayProgramContext);

  const trackEvent = useCallback((eventName, attributes) => {
    analytics.tagEvent({ name: eventName, attributes });
    GoogleTagManager.event({
      event: eventName,
      ...attributes,
    });
  }, [analytics]);

  const trackPageView = useCallback((screenName, attributes) => {
    analytics.tagPageView({ name: screenName, attributes });
    GoogleTagManager.event({
      event: `${screenName}_Opened`,
      ...attributes,
    });
  }, [analytics]);

  useEffect(() => {
    if (autoTrackScreenName) {
      const attributes = {};

      if (payProgram) {
        attributes.Pay_Program = payProgram;
      }

      trackPageView(autoTrackScreenName, attributes);
    }
  }, [autoTrackScreenName, payProgram, trackPageView]);

  return { analytics, trackEvent, trackPageView };
}
