import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { transferGasBackToGiftCard } from '../../reducers/actions';
import getErrorMessage from '../../../lib/utils/getErrorMessage';
import ConfirmGasBackGiftCardDetails from './ConfirmGasBackGiftCardDetails';

function mapStateToProps({ gasback, config }) {
  const {
    idempotencyId,
    isTransferring: transferPending,
    error,
    success: transferInitiated,
  } = gasback.transfer;
  const gasBackTransferDetails = gasback.transfer.details || {};
  const {
    vendorId,
    amount: transferAmount,
    recipientEmail,
    firstName: cardHolderFirstName,
    lastName: cardHolderLastName,
  } = gasBackTransferDetails;

  return {
    idempotencyId,
    consumerHost: config.consumerHost,
    vendorId,
    transferAmount,
    recipientEmail,
    cardHolderFirstName,
    cardHolderLastName,
    error: getErrorMessage(error, 'There was an error processing the transfer request. Please try again later.'),
    transferPending,
    transferInitiated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transferGasBackToGiftCard: (e, body) => {
      e.preventDefault();
      dispatch(transferGasBackToGiftCard(body));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmGasBackGiftCardDetails));
