import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import Enrollment from '../Enrollment';
import DefaultHelmet from '../DefaultHelmet';
import PayProgramContext from '../../context/payProgram';
import PayPrograms from '../../constants/payPrograms';
import RenewCardFlow from '../RenewCardFlow';
import RequireLogin from '../RequireLogin';
import ReturnToSenderFlow from '../ReturnToSenderFlow';
import ProgramPropType from '../../prop-types/program';
import '../../styles/variables.css';

const removeTrailingSlash = str => str.replace(/\/$/, '');

// TODO: Refactor this component to use GradientContainer
export default function Application({ match, program }) {
  const basePath = removeTrailingSlash(match.url);

  return (
    <PayProgramContext.Provider value={program}>
      <DefaultHelmet program={program} />
      <Switch>
        <Route
          exact
          path={`${basePath}/`}
          render={routerProps => (
            <Enrollment
              {...routerProps}
              basePath={basePath}
              welcome
            />
          )}
        />
        <Route
          exact
          path={`${basePath}/promo`}
          render={routerProps => (
            <Enrollment
              {...routerProps}
              basePath={basePath}
              promo
            />
          )}
        />
        <Route
          exact
          path={`${basePath}/start`}
          render={routerProps => (
            <Enrollment
              {...routerProps}
              basePath={basePath}
              login
            />
          )}
        />
        <Route
          exact
          path="/renew"
          render={() => (
            <RequireLogin returnPath="/renew">
              <RenewCardFlow />
            </RequireLogin>
          )}
        />
        <Route
          exact
          path="/renew/confirm"
          render={({ location }) => (
            <RequireLogin allowGuid location={location} returnPath="/renew/confirm">
              <RenewCardFlow autoConfirm />
            </RequireLogin>
          )}
        />
        <Route
          exact
          path="/reship"
          render={() => (
            <RequireLogin returnPath="/reship">
              <ReturnToSenderFlow />
            </RequireLogin>
          )}
        />
        <Route
          path={`${basePath}/enroll/:step?`}
          render={routerProps => (
            <Enrollment
              {...routerProps}
              basePath={basePath}
            />
          )}
        />
        <Redirect to="/" />
      </Switch>
    </PayProgramContext.Provider>
  );
}

Application.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  program: ProgramPropType,
};

Application.defaultProps = {
  match: {
    url: '',
  },
  program: PayPrograms.Free,
};
