import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Input, CloseIcon, CheckMarkIcon } from '@gasbuddy/react-components';
import styles from './InputWithValidation.module.css';

const cx = classnames.bind(styles);

export default function InputWithValidation({ className, isValid, ...rest }) {
  const InputIcon = useMemo(() => (
    isValid
      ? <CheckMarkIcon bordered className={cx('valid')} width={28} />
      : <CloseIcon bordered className={cx('invalid')} width={28} />
  ), [isValid]);

  return (
    <Input
      autoComplete="off" // https://stackoverflow.com/questions/7377301/firefox-keeps-form-data-on-reload
      className={cx('input', className)}
      innerComponent={typeof isValid === 'boolean' && (
        InputIcon
      )}
      {...rest}
    />
  );
}

InputWithValidation.propTypes = {
  className: PropTypes.string,
  isValid: PropTypes.bool,
};

InputWithValidation.defaultProps = {
  className: undefined,
  isValid: undefined,
};
