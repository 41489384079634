const US_POSTAL_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
const CA_POSTAL_REGEX = /(^[ABCEGHJKLMNPRSTVXY][\d][A-Z]\s?[\d][A-Z][\d]$)/i;
const AU_POSTAL_REGEX = /^\d{4}$/; // only four digits

export function isValidUSPostal(postalCode) {
  return US_POSTAL_REGEX.test(postalCode);
}

export function isValidCAPostal(postalCode) {
  return CA_POSTAL_REGEX.test(postalCode);
}

export function isValidAUPostal(postalCode) {
  return AU_POSTAL_REGEX.test(postalCode);
}

export default function isValidPostal(postalCode) {
  return isValidUSPostal(postalCode) || isValidCAPostal(postalCode) || isValidAUPostal(postalCode);
}
