import { Action } from './actions';

export const defaultState = {
  offers: [],
  redeem: {
    amount: undefined,
    error: undefined,
    isRedeeming: false,
    offerId: undefined,
    promotionId: undefined,
    success: false,
  },
  transfer: {
    balance: undefined,
    details: {},
    error: undefined,
    idempotencyId: undefined,
    isTransferring: false,
    success: false,
    type: undefined,
  },
};

export default function gasbackReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case Action.GasBackTransfer.UpdateGasBackTransferType:
      return {
        ...state,
        transfer: {
          ...state.transfer,
          type: action.payload,
        },
      };

    case Action.GasBackTransfer.UpdateGasBackTransferDetails:
      return {
        ...state,
        transfer: {
          ...state.transfer,
          details: {
            ...state.transfer.details,
            ...action.payload,
          },
        },
      };

    case Action.GasBackTransfer.TransferPending:
      return {
        ...state,
        transfer: {
          ...state.transfer,
          isTransferring: true,
          error: undefined,
        },
      };

    case Action.GasBackTransfer.TransferCompleted:
      return {
        ...state,
        transfer: {
          ...state.transfer,
          isTransferring: false,
          success: true,
        },
      };

    case Action.GasBackTransfer.TransferFailed:
      return {
        ...state,
        transfer: {
          ...state.transfer,
          isTransferring: false,
          error: action.payload,
        },
      };

    case Action.GasBackTransfer.ResetGasBackTransfer:
      return {
        ...state,
        transfer: defaultState.transfer,
      };

    case Action.GasBackRedemption.RedeemPending:
      return {
        ...state,
        redeem: {
          ...state.redeem,
          isRedeeming: true,
          error: undefined,
          success: false,
        },
      };

    case Action.GasBackRedemption.RedeemFailed:
      return {
        ...state,
        redeem: {
          ...state.redeem,
          error: 'Invalid Code - Please try again',
          isRedeeming: false,
        },
      };

    case Action.GasBackRedemption.RedeemCompleted:
      return {
        ...state,
        redeem: {
          ...state.redeem,
          amount: action.payload?.amount,
          isRedeeming: false,
          offerId: action.payload?.offerId,
          promotionId: action.payload?.promotionId,
          success: true,
        },
      };

    case Action.GasBackRedemption.ResetGasBackRedemption:
      return {
        ...state,
        redeem: defaultState.redeem,
      };

    default:
      return state;
  }
}
