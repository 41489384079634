import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Constraint, Form, Input, Label, Text } from '@gasbuddy/react-components';
import formatPrice from '../../../lib/utils/formatPrice';
import GasBackTransferStep from '../GasBackTransferStep';

function VerifyGasBackBankTransfer({
  consumerHost,
  error,
  history,
  instrumentId,
  isLoading,
  lastFour,
  onSubmit,
  success,
  transferAmount,
}) {
  const [amount1, setAmount1] = useState('');
  const [amount2, setAmount2] = useState('');

  const handleAmount1Change = useCallback((e) => {
    const { value } = e.target;
    setAmount1(value);
  }, []);

  const handleAmount2Change = useCallback((e) => {
    const { value } = e.target;
    setAmount2(value);
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    onSubmit(e, { amount1, amount2, instrumentId });
  }, [amount1, amount2, instrumentId, onSubmit]);

  useEffect(() => {
    if (success) {
      history.push('/gasback/transfer/bank/success');
    }
  }, [history, success]);

  const subtitle = `Enter the two small microdeposit amounts as seen in your account below to complete the transfer of ${formatPrice(transferAmount)} to your account ending in *${lastFour}`;

  return (
    <GasBackTransferStep
      title="Confirm microdeposits"
      subtitle={subtitle}
    >
      <Form
        aria-label="Microdeposit confirmation Form"
        loading={isLoading}
        method="post"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="instrumentId" value={instrumentId} />
        <Constraint tablet={4}>
          <Label htmlFor="amount1">Microdeposit 1</Label>
          <Input
            id="amount1"
            min={0.01}
            max={0.09}
            name="amount1"
            onChange={handleAmount1Change}
            placeholder="Enter amount ($0.00)"
            type="number"
          />
        </Constraint>
        <Constraint tablet={4}>
          <Label htmlFor="amount2">Microdeposit 2</Label>
          <Input
            id="amount2"
            min={0.01}
            max={0.09}
            name="amount2"
            onChange={handleAmount2Change}
            placeholder="Enter amount ($0.00)"
            type="number"
          />
        </Constraint>
        <br />
        {!!error && (
          <Text as="p" color="orange">
            {error}
          </Text>
        )}
        <GasBackTransferStep.Actions>
          <GasBackTransferStep.Button fluid primary type="submit">
            Confirm Microdeposits
          </GasBackTransferStep.Button>
          <GasBackTransferStep.Button
            as="a"
            href={`//${consumerHost}/account/savings`}
            link
            wide
          >
            Cancel
          </GasBackTransferStep.Button>
        </GasBackTransferStep.Actions>
      </Form>
    </GasBackTransferStep>
  );
}

VerifyGasBackBankTransfer.propTypes = {
  consumerHost: PropTypes.string,
  error: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  instrumentId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  lastFour: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  success: PropTypes.bool,
  transferAmount: PropTypes.number.isRequired,
};

VerifyGasBackBankTransfer.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
  error: undefined,
  isLoading: false,
  onSubmit: () => {},
  success: false,
};

export default VerifyGasBackBankTransfer;
