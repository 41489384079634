import { Constraint, FlexGrid, Form, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React from 'react';
import { VALID_INTEGER_REGEX } from '../../../lib/utils/isValidInteger';
import InstrumentPropType from '../../prop-types/instrument';
import CardActivationHeader from '../CardActivationHeader';
import FormActionButtons from '../FormActionButtons';
import InputWithValidation from '../InputWithValidation/InputWithValidation';

export default function CardActivationForm({ card, isSaving, error, onSubmit }) {
  const defaultFormProperties = {
    driverId: '',
    driverId2: '',
    last4: '',
  };
  const [formValues, setFormValues] = React.useState(defaultFormProperties);
  const [formErrors, setFormErrors] = React.useState(defaultFormProperties);

  const handleFieldChange = React.useCallback((e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: '',
    });
  }, [formErrors, formValues]);

  const handleSubmit = React.useCallback((e) => {
    let isValid = true;
    const newFormErrors = { ...defaultFormProperties };

    if (formValues.driverId.length !== 4) {
      newFormErrors.driverId = 'Driver ID must be 4 digits';
      isValid = false;
    }

    if (formValues.driverId2.length !== 4) {
      newFormErrors.driverId2 = 'Driver ID must be 4 digits';
      isValid = false;
    }

    if (formValues.last4.length !== 4) {
      newFormErrors.last4 = 'Please enter 4 digits';
      isValid = false;
    }

    // Validate license information if given
    if (isValid && formValues.driverId !== formValues.driverId2) {
      newFormErrors.driverId2 = 'Driver IDs do not match';
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(newFormErrors);
      e.preventDefault();
    } else {
      setFormErrors(defaultFormProperties);
      onSubmit(e, {
        ...formValues,
        instrumentId: card.instrument_id,
      });
    }
  }, [defaultFormProperties, onSubmit, formValues, card.instrument_id]);

  return (
    <Form aria-label="Card Activation Form" action="/activate" method="post" loading={isSaving} onSubmit={handleSubmit}>
      <Constraint desktop={10}>
        <InputWithValidation type="hidden" name="instrumentId" value={card.instrument_id} />
        <CardActivationHeader title="Activate your card" />
        <br />
        <Text as="p">Before you can start using your card, please enter the last 4 digits on your card below. You will also need to create a 4 digit driver ID that you will need to enter at the pump prior to filling up. </Text>
        <br />
        {!!error && (
          <Text as="p" color="orange">
            {error}
          </Text>
        )}
        <FlexGrid container>
          <FlexGrid.Column tablet={6}>
            <InputWithValidation
              mask={VALID_INTEGER_REGEX}
              name="driverId"
              label="Create driver ID (XXXX)"
              onChange={handleFieldChange}
              isValid={formErrors.driverId ? false : undefined}
              error={formErrors.driverId}
              required
            />
          </FlexGrid.Column>
          <FlexGrid.Column tablet={6}>
            <InputWithValidation
              mask={VALID_INTEGER_REGEX}
              name="driverId2"
              label="Confirm driver ID (XXXX)"
              onChange={handleFieldChange}
              isValid={formErrors.driverId2 ? false : undefined}
              error={formErrors.driverId2}
              required
            />
          </FlexGrid.Column>
          <FlexGrid.Column tablet={6}>
            <InputWithValidation
              mask={VALID_INTEGER_REGEX}
              name="last4"
              label="Last 4 digits on card"
              onChange={handleFieldChange}
              isValid={formErrors.last4 ? false : undefined}
              error={formErrors.last4}
              required
            />
          </FlexGrid.Column>
        </FlexGrid>
        <br />
        <FormActionButtons
          canSubmit={!isSaving}
          primaryButtonText="Activate Now"
          stepName="CardActivate"
        />
      </Constraint>
    </Form>
  );
}

CardActivationForm.propTypes = {
  card: InstrumentPropType.isRequired,
  error: PropTypes.string,
  isSaving: PropTypes.bool,
  onSubmit: PropTypes.func,
};

CardActivationForm.defaultProps = {
  error: undefined,
  isSaving: false,
  onSubmit: () => {},
};
