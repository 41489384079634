import { connect } from 'react-redux';
import SavingsPage from './SavingsPage';

function mapStateToProps({ config }) {
  const { consumerHost } = config;

  return {
    consumerHost,
  };
}

export default connect(mapStateToProps)(SavingsPage);
