import { connect } from 'react-redux';
import CheckingAccountWelcome from './CheckingAccountWelcome';
import { fetchIavProviderDetails } from '../../reducers/actions';

function mapStateToProps({ config, bank }) {
  return {
    iavProvider: config.iavProvider,
    iavSessionToken: config.iavProviderToken,
    iavEnv: config.iavEnv,
    isCreatingInstrument: bank.isSaving,
    isFetchingIavProviderDetails: config.isFetching,
    errorFetchingIavProviderDetails: config.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchIavProviderDetails: () => dispatch(fetchIavProviderDetails()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckingAccountWelcome);
