import { connect } from 'react-redux';
import PlaidButton from './PlaidButton';
import { createBankInstrument } from '../../reducers/actions';
import getProgramFromWallet from '../../../lib/utils/getProgramFromWallet';
import getErrorMessage from '../../../lib/utils/getErrorMessage';

const defaultErrorMsg = 'There was an error linking your bank account. Please try again later.';

function mapStateToProps({ bank, config, wallet }) {
  return {
    bankAccountError: getErrorMessage(bank.error, defaultErrorMsg),
    iavEnv: config.iavEnv,
    iavProviderToken: config.iavProviderToken,
    program: getProgramFromWallet(wallet),
  };
}

const mapDispatchToProps = dispatch => ({
  createBankInstrument: body => dispatch(createBankInstrument(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaidButton);
