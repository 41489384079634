import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GasBackGiftCardDetails from './GasBackGiftCardDetails';
import { updateGasBackTransferDetails } from '../../reducers/actions';

function mapStateToProps({ config, gasback, profile }) {
  const { idempotencyId } = gasback.transfer;
  const {
    vendorId,
    amount: transferAmount,
    recipientEmail,
  } = gasback.transfer.details || {};

  const { firstName: cardHolderFirstName, lastName: cardHolderLastName } = profile;

  return {
    consumerHost: config.consumerHost,
    cardHolderFirstName,
    cardHolderLastName,
    idempotencyId,
    vendorId,
    transferAmount,
    recipientEmail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateGasBackTransferDetails: (e, body) => {
      e.preventDefault();
      dispatch(updateGasBackTransferDetails(body));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GasBackGiftCardDetails));
