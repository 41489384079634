/**
 * Cleans a string to canonicalize it. This removes any leading & trailing whitespaces, replaces remaining whitespaces
 * with dashes and ensures the string is lowercase.
 * @param {String} str
 */
export default function canonicalizeString(str) {
  const trimmedStr = str?.trim().replace(/\s+/g, '-');
  return trimmedStr.endsWith('/')
    ? trimmedStr.slice(0, -1)
    : trimmedStr;
}
