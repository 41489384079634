import { connect } from 'react-redux';
import ProcessingGasBackBankTransfer from './ProcessingGasBackBankTransfer';

function mapStateToProps({ config }) {
  const { consumerHost } = config;

  return {
    consumerHost,
  };
}

export default connect(mapStateToProps)(ProcessingGasBackBankTransfer);
