import React from 'react';
import { FlexGrid, Image, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import styles from './GasBackPanel.module.css';

const cx = classnames.bind(styles);

const GasBackOfferPropType = PropTypes.shape({
  details: PropTypes.string,
  partner: PropTypes.shape({
    image_url: PropTypes.string,
    name: PropTypes.string,
  }),
});

function GasBackOffer({ host, offer }) {
  if (!offer) {
    return null;
  }

  return (
    <div className={cx('logoCircle')}>
      <Image
        alt={offer.details || offer.partner.name}
        src={`//${host}/${offer.partner.image_url}`}
      />
    </div>
  );
}

GasBackOffer.propTypes = {
  host: PropTypes.string,
  offer: GasBackOfferPropType,
};

GasBackOffer.defaultProps = {
  host: 'images.gasbuddy.io/autox100',
  offer: undefined,
};

function GasBackPanel({ host, offers }) {
  const gasBackText = 'Turn everyday purchases into more free money for gas. When you take advantage of deals at nationwide retailers you already trust, earn additional GasBack to spend at the pump.';

  return (
    <Panel style={{ marginTop: '1.5em' }}>
      <FlexGrid>
        <FlexGrid.Column tablet={7}>
          <Text as="p" elongated size="lg" uppercase>Earn GasBack Automatically.</Text>
          <Text>{gasBackText}</Text>
          <br />
          <br />
          <Text uppercase>
            <a href="https://www.gasbuddy.com/gasback">Learn More</a>
          </Text>
        </FlexGrid.Column>
        <FlexGrid.Column tablet={5}>
          <div className={cx('logos')}>
            <div className={cx('logoColumn')}>
              <GasBackOffer host={host} offer={offers[0]} />
            </div>
            <div className={cx('logoColumn')}>
              <GasBackOffer host={host} offer={offers[1]} />
              <br />
              <GasBackOffer host={host} offer={offers[2]} />
            </div>
          </div>
        </FlexGrid.Column>
      </FlexGrid>
    </Panel>
  );
}

GasBackPanel.propTypes = {
  host: PropTypes.string,
  offers: PropTypes.arrayOf(GasBackOfferPropType),
};

GasBackPanel.defaultProps = {
  host: undefined,
  offers: [],
};

export default GasBackPanel;
