import { Constraint, FlexGrid, Form, Input, Modal, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Redirect } from 'react-router-dom';
import checkEmailAddress from '../../../lib/utils/checkEmailAddress';
import getErrorMessage from '../../../lib/utils/getErrorMessage';
import isPostalCodeFromPayCountry from '../../../lib/utils/isPostalCodeFromPayCountry';
import isValidEmail from '../../../lib/utils/isValidEmail';
import useEffectWithDeepCompare from '../../../lib/utils/useEffectWithDeepCompare';
import { ANALYTICS_SCREENS } from '../../constants/analytics';
import PayCountries from '../../constants/payCountries';
import PayPrograms from '../../constants/payPrograms';
import useTracking from '../../hooks/useTracking';
import AddressPropType from '../../prop-types/address';
import ProgramPropType from '../../prop-types/program';
import { ShippingAddressPropType } from '../../prop-types/shipping-address';
import DeliverableAddressConfirmation from '../DeliverableAddressConfirmation';
import FormActionButtons from '../FormActionButtons';
import PostalCodeInput from '../PostalCodeInput';
import StatesDropdown from '../StatesDropdown';
import UndeliverableAddressConfirmation from '../UndeliverableAddressConfirmation';
import styles from './ShippingAddressForm.module.css';

const cx = classnames.bind(styles);

function isEqual(a, b) {
  return (a || '').toUpperCase() === (b || '').toUpperCase();
}

function addressesMatch(addressA = {}, addressB = {}) {
  return isEqual(addressA.line_1, addressB.line_1)
      && isEqual(addressA.line_2, addressB.line_2)
      && isEqual(addressA.locality, addressB.locality)
      && isEqual(addressA.region, addressB.region)
      && isEqual(addressA.postal_code, addressB.postal_code);
}

export default function ShippingAddressForm({
  address: initialAddress,
  basePath,
  defaultPostalCode,
  email: defaultEmail,
  error,
  errorFetchingLocationInfo,
  fetchLocationInfo,
  firstName: initialFirstName,
  isFetchingLocationInfo,
  isLoggedIn,
  isPreApproving,
  isUpdating,
  isValidating,
  lastName: initialLastName,
  locationInfo,
  primaryButtonText,
  program,
  resetAddressError,
  result,
  saveShippingAddress,
  token,
  triggerPrescreen,
  validateShippingAddress,
  ...rest
}) {
  const [recaptchaResponse, setRecaptchaResponse] = useState(null);
  const [email, setEmail] = useState(defaultEmail || '');
  const [emailError, setEmailError] = useState('');
  const [firstName, setFirstName] = useState(initialFirstName || '');
  const [lastName, setLastName] = useState(initialLastName || '');
  const [line1, setLine1] = useState(initialAddress?.line_1 || '');
  const [line2, setLine2] = useState(initialAddress?.line_2 || '');
  const [locality, setLocality] = useState(initialAddress?.locality || locationInfo?.city || '');
  const [region, setRegion] = useState(initialAddress?.region || locationInfo?.state || '');
  const [postalCode, setPostalCode] = useState(initialAddress?.postal_code || defaultPostalCode || '');
  const [postalCodeError, setPostalCodeError] = useState('');
  const { analytics } = useTracking(ANALYTICS_SCREENS.PAY_ENROLL_SHIPPING);

  const fullAddress = useMemo(() => ({
    line_1: line1,
    line_2: line2,
    locality,
    region,
    postal_code: postalCode,
    country: 'US',
  }), [line1, line2, locality, region, postalCode]);

  const [suggestedAddress, setSuggestedAddress] = useState(result?.address);
  const [deliverability, setDeliverability] = useState(undefined);
  const [originalAddressDeliverabilityToken, setOriginalAddressDeliverabilityToken] = useState(undefined);
  const isDeliverableAddress = deliverability === 'deliverable';
  const addressIsPerfect = useMemo(
    () => isDeliverableAddress && addressesMatch(fullAddress, suggestedAddress),
    [fullAddress, isDeliverableAddress, suggestedAddress],
  );

  const handleEmailBlur = useCallback(async () => {
    const emailSuggestion = await checkEmailAddress(email);

    if (emailSuggestion) {
      setEmailError(`Did you mean ${emailSuggestion}?`);
    }
  }, [email]);

  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handleFirstNameChange = useCallback((e) => {
    setFirstName(e.target.value);
  }, []);

  const handleLastNameChange = useCallback((e) => {
    setLastName(e.target.value);
  }, []);

  const handleLine1Change = useCallback((e) => {
    setLine1(e.target.value);
  }, []);

  const handleLine2Change = useCallback((e) => {
    setLine2(e.target.value);
  }, []);

  const handleLocalityChange = useCallback((e) => {
    setLocality(e.target.value);
  }, []);

  const handleRegionChange = useCallback((e) => {
    setRegion(e.target.value);
  }, []);

  const handlePostalCodeChange = useCallback((e) => {
    setPostalCode(e.target.value);
  }, []);

  const resetErrors = useCallback(() => {
    setEmailError('');
    setPostalCodeError('');
  }, []);

  const handleEditAddress = useCallback(() => {
    setDeliverability(undefined);
    setSuggestedAddress(undefined);
  }, []);

  const handleSubmit = useCallback((e) => {
    let isValid = true;

    if (!isLoggedIn) {
      if (!isValidEmail(email)) {
        setEmailError('Invalid email address');
        isValid = false;
      }

      const [prefix, fullDomain] = email.split('@');
      if (prefix.length > 30 || prefix.length < 1) {
        setEmailError('Your email prefix must be between 1 and 30 characters long.');
        isValid = false;
      }

      if (fullDomain) {
        const [domain, extension] = fullDomain.split('.');
        if (fullDomain.length > 50 || domain.length < 1) {
          setEmailError('Your email domain must be between 1 and 50 characters long.');
          isValid = false;
        }

        if (!extension || extension?.length < 1) {
          setEmailError('Your email domain must have an extension.');
          isValid = false;
        }
      }
    }

    if (!postalCode || !isPostalCodeFromPayCountry(postalCode)) {
      setPostalCodeError('Please enter a valid postal code');
      isValid = false;
    }

    e.preventDefault();

    if (isValid) {
      resetErrors();

      validateShippingAddress({
        address: fullAddress,
        email: !isLoggedIn ? email : undefined, // only pass email if the user is registering on this step
        program,
        token,
      }, analytics);
    }
  }, [analytics, email, fullAddress, isLoggedIn, postalCode, program, resetErrors, token, validateShippingAddress]);

  const continueWithAddress = useCallback(async (address) => {
    // triggerPrescreen handles whether the user is eligible to get pre-screened
    await triggerPrescreen(address, firstName, lastName);

    saveShippingAddress({
      address: {
        ...address,
        deliverability_token: originalAddressDeliverabilityToken,
      },
      'g-recaptcha-response': recaptchaResponse,
      firstName,
      lastName,
    });
  }, [saveShippingAddress, originalAddressDeliverabilityToken, firstName, lastName, recaptchaResponse, triggerPrescreen]);

  const applySuggestedAddress = useCallback(() => {
    continueWithAddress(suggestedAddress);
  }, [continueWithAddress, suggestedAddress]);

  const applyProvidedAddress = useCallback(() => {
    continueWithAddress(fullAddress);
  }, [continueWithAddress, fullAddress]);

  const shouldUpdateLocationInfo = useMemo(
    () => !locality && !region && locationInfo?.city && locationInfo?.state,
    [locality, locationInfo, region],
  );

  const handleRecaptchaVerified = useCallback((response) => {
    setRecaptchaResponse(response);
  }, []);

  // try to resolve wallet postal code to city/state
  useEffect(() => {
    if (defaultPostalCode && !isFetchingLocationInfo && !errorFetchingLocationInfo) {
      if (!locationInfo) {
        fetchLocationInfo(defaultPostalCode);
      } else if (shouldUpdateLocationInfo) {
        setLocality(locationInfo.city);
        setRegion(locationInfo.state);
      }
    }
  }, [locationInfo, isFetchingLocationInfo, errorFetchingLocationInfo, fetchLocationInfo, defaultPostalCode, shouldUpdateLocationInfo]);

  // Each time we get the result,
  // 1. preserve original deliverability token, so we can use it later if user wants to continue with provided address
  // 2. set deliverability so we can enforce appropriate address confirmation modal
  // 3. set suggested address provided in result, so that we can use it to confirm deliverable address
  useEffectWithDeepCompare(() => {
    if (result) {
      const { deliverability: addressDeliverability, address: addressSuggestion } = result;
      setOriginalAddressDeliverabilityToken(result.original_address_deliverability_token);
      setDeliverability(addressDeliverability);
      setSuggestedAddress(addressSuggestion);

      if (addressIsPerfect) {
        applySuggestedAddress();
      }
    }
  }, [addressIsPerfect, result]);

  const getDeliverabilityBasedAddressConfirmation = (closeModal) => {
    const handleContinue = () => {
      closeModal();
      applyProvidedAddress();
    };

    const handleAddressReconciliation = (e, shouldUseSuggestedAddress) => {
      closeModal();
      if (shouldUseSuggestedAddress) {
        applySuggestedAddress();
      } else {
        applyProvidedAddress();
      }
    };

    if (isDeliverableAddress) {
      return (
        <DeliverableAddressConfirmation
          providedAddress={fullAddress}
          suggestedAddress={suggestedAddress}
          onEditAddress={() => {
            closeModal();
            handleEditAddress();
          }}
          onContinue={handleAddressReconciliation}
        />
      );
    }

    return (
      <UndeliverableAddressConfirmation
        deliverability={deliverability}
        address={fullAddress}
        onEditAddress={handleEditAddress}
        onContinue={handleContinue}
      />
    );
  };

  if (error?.status === 409) {
    resetAddressError();

    return (
      <Redirect push to={`${program !== PayPrograms.Free ? `/${program.toLowerCase()}` : ''}/start`} />
    );
  }

  const showLoading = isValidating || isUpdating || isPreApproving;
  const needs = ['your legal name and shipping address so we know where to send your new Pay with GasBuddy card'];

  if (!isLoggedIn) {
    needs.unshift('your email so we can create your GasBuddy account');
  }

  const subheader = `We need ${needs.join(' and ')}.`;

  return (
    <Form
      aria-label="Shipping Address Form"
      action="/shipping-address"
      method="post"
      loading={showLoading}
      onSubmit={handleSubmit}
      {...rest}
    >
      <Constraint className={cx('topContainer')} desktop={10}>
        {!!deliverability && !addressIsPerfect && (
          <Modal
            size="md"
            forceIsShowing={!showLoading}
            content={({ close }) => (
              <Fragment>
                {getDeliverabilityBasedAddressConfirmation(close)}
              </Fragment>
            )}
          />
        )}
        <Text as="p" className={cx('subtitle')}>
          {subheader}
        </Text>
      </Constraint>
      <FlexGrid className={cx('shippingAddressForm')} container>
        {!isLoggedIn && (
          <Fragment>
            <FlexGrid.Column tablet={12} desktop={8}>
              <Input
                className={cx('formInput')}
                data-testid="emailInput"
                error={emailError}
                label="Email"
                name="email"
                onBlur={handleEmailBlur}
                onChange={handleEmailChange}
                autoComplete="email"
                type="email"
                value={email}
              />
            </FlexGrid.Column>
            <FlexGrid.Column tablet={12} desktop={4} /> {/** Empty Space */}
          </Fragment>
        )}
        <FlexGrid.Column tablet={6} desktop={4}>
          <Input
            name="first_name"
            label="Legal First Name"
            onChange={handleFirstNameChange}
            autoComplete="given-name"
            value={firstName}
            required
          />
        </FlexGrid.Column>
        <FlexGrid.Column tablet={6} desktop={4}>
          <Input
            name="last_name"
            label="Legal Last Name"
            onChange={handleLastNameChange}
            autoComplete="family-name"
            value={lastName}
            required
          />
        </FlexGrid.Column>
        <FlexGrid.Column tablet={12} desktop={8}>
          <Input
            name="line_1"
            label="Street Address"
            onChange={handleLine1Change}
            value={line1}
            autoComplete="address-line1"
            required
            className={cx('formInput')}
          />
        </FlexGrid.Column>
        <FlexGrid.Column tablet={12} desktop={4}>
          <Input
            name="line_2"
            label="Apt/Suite"
            value={line2}
            onChange={handleLine2Change}
            autoComplete="address-line2"
            className={cx('formInput')}
          />
        </FlexGrid.Column>
      </FlexGrid>
      <FlexGrid className={cx('shippingAddressForm')} container>
        <FlexGrid.Column tablet={8} desktop={5}>
          <Input
            name="locality"
            label="City"
            required
            value={locality}
            onChange={handleLocalityChange}
            autoComplete="address-level2"
            className={cx('formInput')}
          />
        </FlexGrid.Column>
        <FlexGrid.Column tablet={4} desktop={3}>
          <StatesDropdown
            name="region"
            placeholder="State"
            required
            className={cx('statesDropdown', 'formInput', { filled: region })}
            value={region}
            onChange={handleRegionChange}
            autoComplete="address-level1"
          />
        </FlexGrid.Column>
        <FlexGrid.Column tablet={6} desktop={4}>
          <PostalCodeInput
            name="postal_code"
            label="Postal Code"
            required
            countries={PayCountries}
            value={postalCode}
            onChange={handlePostalCodeChange}
            error={postalCodeError}
            className={cx('formInput')}
          />
        </FlexGrid.Column>
      </FlexGrid>
      {!!error && (
        <Text as="p" color="orange">
          {getErrorMessage(error, 'An error occurred while updating your shipping address')}
        </Text>
      )}
      <Constraint desktop={10}>
        <GoogleReCaptcha
          action="shipping"
          onVerify={handleRecaptchaVerified}
        />
        <FormActionButtons
          canSubmit={!showLoading && !!recaptchaResponse}
          primaryButtonText={primaryButtonText}
          stepName="Shipping"
        />
      </Constraint>
    </Form>
  );
}

ShippingAddressForm.propTypes = {
  address: AddressPropType,
  basePath: PropTypes.string,
  defaultPostalCode: PropTypes.string,
  email: PropTypes.string,
  error: PropTypes.shape({
    code: PropTypes.string,
    domain: PropTypes.string,
    message: PropTypes.string,
    status: PropTypes.number,
  }),
  errorFetchingLocationInfo: PropTypes.string,
  fetchLocationInfo: PropTypes.func,
  firstName: PropTypes.string,
  isFetchingLocationInfo: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isPreApproving: PropTypes.bool,
  isUpdating: PropTypes.bool,
  isValidating: PropTypes.bool,
  lastName: PropTypes.string,
  locationInfo: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
  }),
  primaryButtonText: PropTypes.string,
  program: ProgramPropType,
  resetAddressError: PropTypes.func,
  result: ShippingAddressPropType,
  saveShippingAddress: PropTypes.func,
  token: PropTypes.string,
  triggerPrescreen: PropTypes.func,
  validateShippingAddress: PropTypes.func,
};

ShippingAddressForm.defaultProps = {
  address: undefined,
  basePath: undefined,
  defaultPostalCode: undefined,
  email: '',
  error: undefined,
  errorFetchingLocationInfo: undefined,
  fetchLocationInfo: () => { },
  firstName: undefined,
  isFetchingLocationInfo: false,
  isLoggedIn: false,
  isPreApproving: false,
  isUpdating: false,
  isValidating: false,
  lastName: undefined,
  locationInfo: undefined,
  primaryButtonText: undefined,
  program: undefined,
  resetAddressError: () => { },
  result: undefined,
  saveShippingAddress: () => { },
  token: undefined,
  triggerPrescreen: () => { },
  validateShippingAddress: () => { },
};
