import { Form, Header, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { ANALYTICS_EVENTS, ANALYTICS_SCREENS } from '../../constants/analytics';
import useTracking from '../../hooks/useTracking';
import { CharityPropType } from '../../prop-types/gasback';
import CardSelector from '../CardSelector';
import GasBackTransferStep from '../GasBackTransferStep';

export default function GasBackCharitySelection({
  balance,
  charityOptions,
  consumerHost,
  history,
  recipientEmail,
  updateDonationDetails,
}) {
  const { trackEvent } = useTracking(ANALYTICS_SCREENS.PAY_REDEEM_CHARITY_SELECT);
  const [selectedCharity, setSelectedCharity] = useState();

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    trackEvent(ANALYTICS_EVENTS.PAY_REDEEM_CHARITY_SELECT_SUBMIT_CLICKED);

    updateDonationDetails({
      charityId: selectedCharity,
      donationAmount: balance,
      recipientEmail,
    });
    history.push('/gasback/transfer/charity/confirm');
  }, [balance, history, recipientEmail, selectedCharity, trackEvent, updateDonationDetails]);

  const handleCancel = useCallback(() => {
    trackEvent(ANALYTICS_EVENTS.PAY_REDEEM_CHARITY_SELECT_CANCEL_CLICKED);
  }, [trackEvent]);

  if (charityOptions.length === 1) {
    updateDonationDetails({
      charityId: charityOptions[0].id,
      donationAmount: balance,
      recipientEmail,
    });
    return (
      <Redirect to="/gasback/transfer/charity/confirm" />
    );
  }

  return (
    <GasBackTransferStep
      backLinkHref="/gasback/transfer"
      backLinkText="Choose Transfer Type"
      context={{ screen: ANALYTICS_SCREENS.PAY_REDEEM_CHARITY_SELECT }}
    >
      <Form aria-label="GasBack Charity Transfer Form" method="POST" action="/gasback/transfer/charity" onSubmit={handleSubmit}>
        <Header as="h3" snug>Donate to charity</Header>
        <Text as="p">Pick a charity and the donation amount and we&apos;ll send your donation on your behalf.</Text>
        <br />
        <CardSelector title="Pick a charity">
          {charityOptions.map(charity => (
            <CardSelector.Card
              key={charity.id}
              id={charity.id}
              text={charity.name}
              imageUrl={charity.imageUrl}
              onClick={setSelectedCharity}
              selected={charity.id === selectedCharity}
              mobile={6}
              tablet={4}
              desktop={3}
            />
          ))}
        </CardSelector>
        <br />
        <br />
        <GasBackTransferStep.Actions>
          <GasBackTransferStep.Button
            type="submit"
            primary
            fluid
            disabled={!selectedCharity}
          >
            Continue
          </GasBackTransferStep.Button>
          <GasBackTransferStep.Button
            as="a"
            link
            href={`//${consumerHost}/account/savings`}
            onClick={handleCancel}
          >
            Cancel
          </GasBackTransferStep.Button>
        </GasBackTransferStep.Actions>
      </Form>
    </GasBackTransferStep>
  );
}

GasBackCharitySelection.propTypes = {
  balance: PropTypes.string.isRequired,
  charityOptions: PropTypes.arrayOf(CharityPropType),
  consumerHost: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  recipientEmail: PropTypes.string.isRequired,
  updateDonationDetails: PropTypes.func,
};

GasBackCharitySelection.defaultProps = {
  charityOptions: [],
  consumerHost: 'www.gasbuddy.com',
  history: {
    push: () => {},
  },
  updateDonationDetails: () => {},
};
