import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import React from 'react';
import styles from './Footer.module.css';
import Media from '../Media';

const cx = classnames.bind(styles);

export const FooterText = 'We use multi-level security mechanisms including dedicated firewalls and VPN services. Data is transmitted securely through Transport Layer Security (TLS) 1.2. Information is stored using end-to-end encryption and is managed using strict data access policies and controls. Additionally, we use an encrypted token system, which does not store your bank login credentials on our system.';

export default function Footer() {
  return (
    <footer className={cx('footer')}>
      <Media>
        <Media.Aside>
          <FontAwesomeIcon
            icon={faLock}
            size="2x"
          />
        </Media.Aside>
        <Media.Body>
          <Text as="p">
            <Text bold fluid>GasBuddy takes the data and security of our customers very seriously</Text>
            {FooterText}
          </Text>
        </Media.Body>
      </Media>
    </footer>
  );
}
