import React from 'react';

function ChevronRightIcon() {
  /* eslint-disable react/no-unknown-property */
  return (
    <svg
      width="16px"
      height="28px"
      viewBox="0 0 16 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon-/-Chevron-/-Right" fill="currentColor">
          <g transform="translate(8.000000, 14.000000) rotate(90.000000) translate(-8.000000, -14.000000) translate(-6.000000, 6.000000)" id="Shape">
            <path d="M9.79161417,7.98656035 L21.5192589,-3.39372341 C22.133571,-3.99027617 22.1349996,-4.95585912 21.5206876,-5.55241188 C20.909233,-6.14896463 19.9120473,-6.14896463 19.299164,-5.55379912 L6.46004303,6.90444139 C6.15145828,7.20271777 5.99859463,7.59394543 5.99999391,7.98517295 C5.99859463,8.37640061 6.15145828,8.76762828 6.45861439,9.0672919 L19.3163075,21.5532792 C19.6234636,21.8515555 20.0249094,22 20.4277838,22 C20.8306582,22 21.2321039,21.8515555 21.5392599,21.5532792 C22.1535719,20.9567264 22.1535719,19.9911435 21.5392599,19.3945907 L9.79161417,7.98656035 Z" transform="translate(13.999994, 8.000000) rotate(90.000000) translate(-13.999994, -8.000000) " />
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-disable react/no-unknown-property */
}

export default ChevronRightIcon;
