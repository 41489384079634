import { Loader } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { ANALYTICS_SCREENS } from '../../constants/analytics';
import useTracking from '../../hooks/useTracking';
import CheckingAccountManual from '../CheckingAccountManual';
import CheckingAccountWelcome from '../CheckingAccountWelcome';

const VARIANTS = ['manual', 'automatic'];

export default function CheckingAccountForm({ isPreApproving }) {
  useTracking(ANALYTICS_SCREENS.PAY_ENROLL_BANK_START);

  const [variant, setVariant] = useState(); // 'automatic' or 'manual'

  const chooseVariant = useCallback(v => setVariant(VARIANTS.includes(v) ? v : undefined), []);
  const switchToManual = useCallback(() => chooseVariant('manual'), [chooseVariant]);

  if (isPreApproving) {
    return (<Loader size="lg" />);
  }

  switch (variant) {
    case 'manual': {
      return (<CheckingAccountManual />);
    }

    default: {
      return (<CheckingAccountWelcome switchToManual={switchToManual} />);
    }
  }
}

CheckingAccountForm.propTypes = {
  isPreApproving: PropTypes.bool,
};

CheckingAccountForm.defaultProps = {
  isPreApproving: false,
};
