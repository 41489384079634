import PropTypes from 'prop-types';
import CMSImagePropType from './cmsImage';

export default PropTypes.shape({
  logo: CMSImagePropType,
  mobileLogo: CMSImagePropType,
  name: PropTypes.string,
  namespace: PropTypes.string,
  priorityLogo: CMSImagePropType,
  useCodes: PropTypes.bool,
});
