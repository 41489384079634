import { useRef } from 'react';
import equal from 'fast-deep-equal';

export default function useMemoState(obj) {
  const ref = useRef();

  if (!equal(obj, ref.current)) {
    ref.current = obj;
  }

  return ref.current;
}
