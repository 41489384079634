import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import GasBackCharitySelection from './GasBackCharitySelection';
import { updateGasBackTransferDetails } from '../../reducers/actions';

function mapStateToProps({ auth, gasback, config }) {
  const { balance } = gasback.transfer;

  return {
    consumerHost: config.consumerHost,
    balance,
    recipientEmail: auth.email,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateDonationDetails: body => dispatch(updateGasBackTransferDetails(body)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GasBackCharitySelection));
