import { connect } from 'react-redux';
import RequireLogin from './RequireLogin';

function mapStateToProps({ auth }) {
  return {
    isLoggedIn: !!auth.account_id,
  };
}

export default connect(mapStateToProps)(RequireLogin);
