import PropTypes from 'prop-types';
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Image } from '@gasbuddy/react-components';
import ConfirmGasBackBankTransfer from '../ConfirmGasBackBankTransfer';
import VerifyGasBackBankTransfer from '../VerifyGasBackBankTransfer';
import GasBackBankTransferComplete from '../GasBackBankTransferComplete';
import ConfirmGasBackCharityTransfer from '../ConfirmGasBackCharityTransfer';
import ProcessingGasBackBankTransfer from '../ProcessingGasBackBankTransfer';
import GasBackBankTransferForm from '../GasBackBankTransferForm';
import GasBackCharitySelection from '../GasBackCharitySelection';
import GasBackCharityTransferSuccess from '../GasBackCharityTransferSuccess';
import ConfirmGasBackGiftCardDetails from '../ConfirmGasBackGiftCardDetails';
import GasBackGiftCardSelect from '../GasBackGiftCardSelect';
import GasBackTransferOptions from '../GasBackTransferOptions';
import GradientContainer from '../GradientContainer';
import GasBackGiftCardDetails from '../GasBackGiftCardDetails';
import PrivateRoute from '../PrivateRoute';
import ProcessingGasBackGiftCard from '../ProcessingGasBackGiftCard';
import GasBackGiftCardPurchaseComplete from '../GasBackGiftCardPurchaseComplete';

const removeTrailingSlash = str => str.replace(/\/$/, '');

export default function GasBackTransfer({ match }) {
  const basePath = removeTrailingSlash(match.url);

  return (
    <GradientContainer
      logo={(
        <Image src="https://static.gasbuddy.com/web/gas-buddy-logo-midnight-txt.svg" alt="GasBuddy" />
      )}
    >
      <Switch>
        <PrivateRoute
          exact
          path={basePath}
          component={GasBackTransferOptions}
        />
        <PrivateRoute
          exact
          path={`${basePath}/bank`}
          component={GasBackBankTransferForm}
        />
        <PrivateRoute
          exact
          path={`${basePath}/bank/confirm`}
          component={ConfirmGasBackBankTransfer}
        />
        <PrivateRoute
          exact
          path={`${basePath}/bank/processing`}
          component={ProcessingGasBackBankTransfer}
        />
        <PrivateRoute
          exact
          path={`${basePath}/bank/verify`}
          component={VerifyGasBackBankTransfer}
        />
        <PrivateRoute
          exact
          path={`${basePath}/bank/success`}
          component={GasBackBankTransferComplete}
        />
        <PrivateRoute
          exact
          path={`${basePath}/giftcard`}
          component={GasBackGiftCardSelect}
        />
        <PrivateRoute
          exact
          path={`${basePath}/giftcard/details`}
          component={GasBackGiftCardDetails}
        />
        <PrivateRoute
          exact
          path={`${basePath}/giftcard/confirm`}
          component={ConfirmGasBackGiftCardDetails}
        />
        <PrivateRoute
          exact
          path={`${basePath}/giftcard/processing`}
          component={ProcessingGasBackGiftCard}
        />
        <PrivateRoute
          exact
          path={`${basePath}/giftcard/success`}
          component={GasBackGiftCardPurchaseComplete}
        />
        <PrivateRoute
          exact
          path={`${basePath}/charity`}
          component={GasBackCharitySelection}
        />
        <PrivateRoute
          exact
          path={`${basePath}/charity/confirm`}
          component={ConfirmGasBackCharityTransfer}
        />
        <PrivateRoute
          exact
          path={`${basePath}/charity/success`}
          component={GasBackCharityTransferSuccess}
        />
        <Redirect to={basePath} />
      </Switch>
    </GradientContainer>
  );
}

GasBackTransfer.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

GasBackTransfer.defaultProps = {
  match: {
    url: '',
  },
};
