import { Action } from './actions';

export const defaultState = {
  isSavingLicense: false,
  licenseError: undefined,
  firstName: undefined,
  lastName: undefined,
};

export default function profileReducer(state = defaultState, action = {}) {
  const body = action.payload || {};

  switch (action.type) {
    case Action.License.LicenseSavePending:
      return {
        ...state,
        isSavingLicense: true,
        licenseError: undefined,
      };

    case Action.License.LicenseSaveCompleted:
      return {
        ...state,
        isSavingLicense: false,
      };

    case Action.License.LicenseSaveFailed:
      return {
        ...state,
        isSavingLicense: false,
        licenseError: body,
      };

    default:
      return state;
  }
}
