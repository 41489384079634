export default function getTitleFromDeliverability(deliverability) {
  switch (deliverability) {
    case 'unknown':
    case 'undeliverable':
      return 'Address Not Found';
    case 'secondary_invalid':
    case 'secondary_unit':
    case 'secondary_missing':
      return 'Unit or Floor Number';
    default:
      return undefined;
  }
}
