import { connect } from 'react-redux';
import GasBackCharityTransferSuccess from './GasBackCharityTransferSuccess';

function mapStateToProps({ config, gasback }) {
  const { consumerHost } = config;
  const gasbackTransferDetails = gasback.transfer.details || {};
  const {
    charityId,
    donationAmount,
    recipientEmail,
  } = gasbackTransferDetails;

  return {
    consumerHost,
    charityId,
    donationAmount,
    recipientEmail,
  };
}

export default connect(mapStateToProps)(GasBackCharityTransferSuccess);
