import React from 'react';
import PropTypes from 'prop-types';
import { Message } from '@gasbuddy/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';

export default function GasBackTransferError({
  error,
}) {
  return !!error && (
    <Message color="red">
      <Message.Icon><FontAwesomeIcon icon={faExclamation} /></Message.Icon>
      <Message.Header>Oops!</Message.Header>
      <Message.Content>
        {error}
      </Message.Content>
    </Message>
  );
}

GasBackTransferError.propTypes = {
  error: PropTypes.string,
};

GasBackTransferError.defaultProps = {
  error: undefined,
};
