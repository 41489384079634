import { Action } from './actions';

export const defaultState = {
  premium: {
    monthly: {},
    annual: {
    },
  },
  // TODO: Keeping this temporary; not having this breaks WelcomeForm component
  plus: {
    monthly: {},
    annual: {
    },
  },
  isUpgrading: false,
  upgraded: false,
  upgradeError: undefined,
  declinedMembership: false,
};

export default function membershipReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case Action.Ship.ShipCardCompleted:
      return {
        ...state,
        declinedMembership: !action.meta.offeringId,
      };

    case Action.Membership.UpgradePending:
      return {
        ...state,
        isUpgrading: true,
        upgradeError: undefined,
      };

    case Action.Membership.UpgradeFailed:
      return {
        ...state,
        isUpgrading: false,
        upgradeError: action.payload,
      };

    case Action.Membership.UpgradeCompleted:
      return {
        ...state,
        isUpgrading: false,
        upgraded: true,
      };

    default:
      return state;
  }
}
