import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GasBackTransferStep from './GasBackTransferStep';

function mapStateToProps({ config, gasback }) {
  const { idempotencyId, balance, details } = gasback.transfer;
  return {
    consumerHost: config.consumerHost,
    idempotencyId,
    balance,
    transferDetails: details,
  };
}

export default withRouter(connect(mapStateToProps)(GasBackTransferStep));
