const DATE_REGEX = /^\d{4}-\d\d-\d\d$/;
const THIS_YEAR = new Date().getFullYear();
const MIN_AGE = 18;
const MAX_AGE = 120;

/**
 * Tests that a date adheres to the US format yyyy-mm-dd,
 * then that it meets a minimum age (currently 18),
 * then that it is not a date in the future,
 * then that it is not too late in the past (100 years).
 *
 * @param {string} date - a date string with format yyyy-mm-dd
 */
export default function isValidBirthdate(date) {
  return DATE_REGEX.test(date)
    && Date.parse(date) - new Date().setFullYear(THIS_YEAR - MIN_AGE) < 0
    && Date.parse(date) - new Date().setFullYear(THIS_YEAR - MAX_AGE) > 0
    && Date.parse(date) - new Date().getTime() < 0;
}
