import React from 'react';
import PropTypes from 'prop-types';
import { Actions } from '@gasbuddy/react-components';

export default function FormActionButtons({
  primaryButtonText,
  loading,
  canSubmit,
}) {
  return (
    <Actions>
      <Actions.Button
        primary
        type="submit"
        loading={loading}
        disabled={!canSubmit}
        tablet={6}
        desktop={5}
      >
        {primaryButtonText}
      </Actions.Button>
    </Actions>
  );
}

FormActionButtons.propTypes = {
  primaryButtonText: PropTypes.string,
  loading: PropTypes.bool,
  canSubmit: PropTypes.bool,
};

FormActionButtons.defaultProps = {
  primaryButtonText: 'Continue',
  loading: false,
  canSubmit: false,
};
