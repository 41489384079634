import { connect } from 'react-redux';
import Summary from './LicenseSummary';

const mapStateToProps = ({ wallet }) => ({
  firstName: wallet.status?.name?.first_name,
  lastName: wallet.status?.name?.last_name,
  state: wallet.status?.shipping_address?.region,
});

const LicenseSummary = connect(mapStateToProps)(Summary);
export default LicenseSummary;
