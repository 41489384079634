import { Header, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import CardSelector from '../CardSelector';
import styles from './CardChoiceConfirmation.module.css';

const cx = classnames.bind(styles);

function Terms({
  content,
  detailsUrl,
  title,
  withTermsLink,
}) {
  return (
    <React.Fragment>
      <Text as="p" size="sm" bold>{title}</Text>
      <Text as="p" size="sm">
        {content}
        &nbsp;
        {!!detailsUrl && (
          <Text
            as="a"
            className={cx('viewDetailsLink', 'blueLink')}
            href={detailsUrl}
            target="_blank"
            bold
          >
            View more details
          </Text>
        )}
      </Text>
      {withTermsLink && (
        <React.Fragment>
          <br />
          <Text as="p">
            By clicking confirm below you agree to&nbsp;
            <Text
              as="a"
              bold
              className={cx('blueLink')}
              href="https://help.gasbuddy.com/hc/en-us/articles/360047975493"
              role="link"
              target="_blank"
            >
              GasBuddy terms & conditions
            </Text>
          </Text>
        </React.Fragment>
      )}
      <br />
    </React.Fragment>
  );
}

Terms.propTypes = {
  content: PropTypes.string.isRequired,
  detailsUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  withTermsLink: PropTypes.bool,
};

Terms.defaultProps = {
  detailsUrl: undefined,
  withTermsLink: false,
};

function CardChoiceConfirmation({
  title,
  description,
  imageUrl,
  cardVendorName,
  children,
}) {
  return (
    <React.Fragment>
      {!!title && (
        <React.Fragment>
          <Header as="h3" snug>{title}</Header>
          {!!description && (
            <Text as="p">
              {description}
            </Text>
          )}
        </React.Fragment>
      )}
      <br />
      <CardSelector>
        <CardSelector.Card
          id={cardVendorName}
          text={cardVendorName}
          imageUrl={imageUrl}
        />
      </CardSelector>
      {children}
    </React.Fragment>
  );
}

CardChoiceConfirmation.propTypes = {
  cardVendorName: PropTypes.string.isRequired,
  children: PropTypes.node,
  description: PropTypes.node,
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
};

CardChoiceConfirmation.defaultProps = {
  children: undefined,
  description: undefined,
  title: undefined,
};

CardChoiceConfirmation.Terms = Terms;

export default CardChoiceConfirmation;
