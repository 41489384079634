import { Action } from './actions';

export const defaultState = {
  isUpdating: false,
  isValidating: false,
  result: undefined,
  error: undefined,
};

export default function shippingAddressReducer(state = defaultState, action = {}) {
  const body = action.payload || {};

  switch (action.type) {
    case Action.ShippingAddress.UpdatePending:
    case Action.Reship.UpdatePending:
      return {
        ...state,
        isUpdating: true,
        result: undefined,
        error: undefined,
      };

    case Action.ShippingAddress.UpdateCompleted:
      return {
        ...state,
        isUpdating: false,
        result: body?.shippingAddress || {},
      };

    case Action.ShippingAddress.UpdateFailed:
      return {
        ...state,
        isUpdating: false,
        result: body?.response?.shippingAddress || {},
        error: {
          ...body?.response,
          status: body.status,
        },
      };

    case Action.Reship.UpdateCompleted:
      return {
        ...state,
        isUpdating: false,
      };

    case Action.Reship.UpdateFailed:
      return {
        ...state,
        isUpdating: false,
        error: action.payload,
      };

    case Action.ShippingAddress.ValidationPending:
      return {
        ...state,
        isValidating: true,
        result: undefined,
        error: undefined,
      };

    case Action.ShippingAddress.ValidationCompleted:
      return {
        ...state,
        isValidating: false,
        result: body?.shippingAddress || {},
      };

    case Action.ShippingAddress.ValidationFailed:
      return {
        ...state,
        isValidating: false,
        result: body?.response?.shippingAddress || {},
        error: {
          ...body?.response,
          status: action.payload.status,
        },
      };

    case Action.ShippingAddress.Reset:
      return {
        ...state,
        isUpdating: false,
        result: undefined,
        error: undefined,
      };

    default:
      return state;
  }
}
