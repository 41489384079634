// Min & max values are sourced from https://gasbuddy.slack.com/archives/C011RHVUZ53/p1588098699078400
const GiftCardVendors = [
  {
    displayName: 'Dominos',
    id: 'dominos',
    imageUrl: '//static.gasbuddy.com/web/pay/svg/vendors/dominos.svg',
    terms: 'Once applied to your Dominos account, the entire amount will be added to your gift card balance. Your gift card balance will be applied automatically to eligible orders during the checkout process. If you don\'t want to use your gift card balance on your order, you can unselect it as a payment method in checkout.',
    detailsUrl: 'https://www.dominos.com/en/#!/content/giftcards/',
    min: 5,
    max: 100,
  },
  {
    displayName: 'Burger King',
    id: 'burgerking',
    imageUrl: '//static.gasbuddy.com/web/pay/svg/vendors/burgerking.svg',
    terms: 'Once applied to your Burger King account, the entire amount will be added to your gift card balance. Your gift card balance will be applied automatically to eligible orders during the checkout process. If you don\'t want to use your gift card balance on your order, you can unselect it as a payment method in checkout.',
    detailsUrl: 'https://www.bk.com/crown-card-terms',
    min: 5,
    max: 1000,
  },
  {
    displayName: 'Amazon',
    id: 'amazon',
    imageUrl: '//static.gasbuddy.com/web/pay/svg/vendors/amazon.svg',
    terms: 'Amazon.com Gift Cards* never expire and can be redeemed towards millions of items. Restrictions apply, see https://www.amazon.com/gc-legal',
    detailsUrl: 'https://www.amazon.com/gc-legal',
    min: 5,
    max: 2000,
  },
  {
    displayName: 'Walmart',
    id: 'walmart',
    imageUrl: '//static.gasbuddy.com/web/pay/svg/vendors/walmart.svg',
    terms: 'For balance inquiry, call 1-888-537-5503 or go to Walmart.com/giftcards or samsclub.com. Use this card at any Walmart store or Sam’s Club in the U.S. or Puerto Rico, or on-line at Vudu, Inc., Wal-Mart.com or Samsclub.com. The balance on this card is a liability of Wal-Mart Stores Arkansas, LLC. This card cannot be redeemed for cash except where required by state law. Lost or stolen cards will not be replaced. Walmart may refuse to accept this card and to take action, including balance forfeiture, for fraud, abuse or violations of terms. Terms and conditions subject to change without notice. See Walmart.com for complete terms. Treat this card like cash.',
    detailsUrl: 'https://www.walmart.com/giftcardtermsandconditions',
    min: 5,
    max: 500,
  },
  {
    displayName: 'Home Depot',
    id: 'homedepot',
    imageUrl: '//static.gasbuddy.com/web/pay/svg/vendors/thehomedepot.svg',
    terms: 'Gift Card is valid for the purchase of merchandise/services at any The HomeDepot® store in the U.S., Canada and online atHomeDepot.com. Gift Card isnot a credit/debit card and is not redeemable for cash or credit unlessrequired by law. Gift Card cannot be applied to any credit or loan balance, Tool Rental Deposits, or for in-home purchases. To replace a lost or stolen GiftCard, visit your local store.Lost, stolen or damaged Gift Cards will not bereplaced without proof of purchase. Replacement value is the value of the GiftCard at the time it is reported lost or stolen. Gift Cardspurchased with cashwill not be replaced unless required by law. Returns for purchases made withthis Gift Card are subject to The Home Depot’s Returns Policy (details availableat any The Home Depot store) and eligible refunds will be issued in storecredit. Gift Card may be deactivated or rejected if fraud is suspected in theissuer’s sole discretion. Check your balance at any The Home Depot store oronline. Reload Gift Card value at any The Home Depot store or online atHomeDepot.com.For cross-border redemptions, Gift Card is redeemable atThe Home Depot’s applicable local currency exchange rate at the time ofredemption. Gift Card is issuedby Home Depot Incentives, Inc. © 2021Home Depot Product Authority, LLC. All rights reserved.',
    detailsUrl: 'https://www.homedepot.com/c/Gift_Cards',
    min: 5,
    max: 200,
  },
  {
    displayName: 'Lowes',
    id: 'lowes',
    imageUrl: '//static.gasbuddy.com/web/pay/svg/vendors/lowes.svg',
    terms: 'This is not a credit/debit card and has no implied warranties.This Gift Card isnot redeemable for cash unless required by law and cannot be used to makepayments on any charge account. Lowe’s® reserves the right to deactivate orreject any Gift Card issued or procured, directly or indirectly, in connectionwith fraudulent actions, unless prohibited by law. Lost or stolen Gift Cards canonly be replaced upon presentation of original sales receipt for any remainingbalance. It will be void if altered or defaced. To check your Lowe’s® Gift Cardbalance, visitLowes.com/GiftCards, call 1-800-560-7172 or see the CustomerServiceDesk in any Lowe’s® store. ©2018Lowe’s, LOWE’S and the GableMansard Design are registered trademarks of LF, LLC.',
    detailsUrl: 'https://www.lowes.com/l/shop/gift-cards',
    min: 5,
    max: 200,
  },
];

export default GiftCardVendors;

export const GiftCardVendorIds = GiftCardVendors.map(vendor => vendor.id);

export const MAX_GB_GIFT_CARD_PURCHASE = 200;
