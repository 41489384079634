import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@gasbuddy/react-components';
import formatPrice from '../../../lib/utils/formatPrice';
import GasBackTransferStep from '../GasBackTransferStep';

function GasBackBankTransferComplete({ consumerHost, email, lastFour, transferAmount }) {
  const title = `Woohoo! Your transfer of ${formatPrice(transferAmount)} has been completed!`;
  // TODO: When bank transfer flow is enabled, replace this X with a real number
  const description = 'Please allow X business days for the remaining funds to be transferred to your bank account.';

  return (
    <GasBackTransferStep
      title={title}
      subtitle={description}
      smallHeader
    >
      <Text as="p">Transfer Amount</Text>
      <Text as="p" bold>{formatPrice(transferAmount)}</Text>
      <br />
      <Text as="p">Transfer Account</Text>
      <Text as="p" bold>Account ending in *{lastFour}</Text>
      <br />
      <Text as="p">Your confirmation email will be sent to</Text>
      <Text as="p" bold>{email}</Text>
      <br />
      <GasBackTransferStep.Actions>
        <GasBackTransferStep.Button
          as="a"
          fluid
          href={`//${consumerHost}/account/savings`}
          primary
          type="submit"
        >
          Done
        </GasBackTransferStep.Button>
      </GasBackTransferStep.Actions>
    </GasBackTransferStep>
  );
}

GasBackBankTransferComplete.propTypes = {
  consumerHost: PropTypes.string,
  email: PropTypes.string.isRequired,
  lastFour: PropTypes.string.isRequired,
  transferAmount: PropTypes.number.isRequired,
};

GasBackBankTransferComplete.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
};

export default GasBackBankTransferComplete;
