import PropTypes from 'prop-types';
import { GiftCardVendorIds } from '../constants/giftCardVendors';
import { CharityIds } from '../constants/charityOptions';

export const CharityPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  urlText: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  donationDescription: PropTypes.string.isRequired,
});

export const GiftCardVendorPropType = PropTypes.oneOf(GiftCardVendorIds);

export const CharityIdPropType = PropTypes.oneOf(CharityIds);
