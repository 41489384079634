import React from 'react';
import { Image } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import styles from './Media.module.css';

const cx = classnames.bind(styles);

// https://css-tricks.com/media-object-bunch-ways/
function Media({ className, ...rest }) {
  return (
    <div className={cx('block', className)} {...rest} />
  );
}

Media.propTypes = {
  className: PropTypes.string,
};

Media.defaultProps = {
  className: undefined,
};

function MediaAside({ as: Element, className, ...rest }) {
  return (
    <Element className={cx('aside', className)} {...rest} />
  );
}

MediaAside.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  className: PropTypes.string,
};

MediaAside.defaultProps = {
  as: 'div',
  className: undefined,
};

function MediaImage(props) {
  return (
    <MediaAside as={Image} {...props} />
  );
}

function MediaBody({ className, ...rest }) {
  return (
    <div className={cx('body', className)} {...rest} />
  );
}

MediaBody.propTypes = {
  className: PropTypes.string,
};

MediaBody.defaultProps = {
  className: undefined,
};

Media.Aside = MediaAside;
Media.Image = MediaImage;
Media.Body = MediaBody;

export default Media;
