import { connect } from 'react-redux';
import PlaidLink from './PlaidLink';
import { createBankInstrument } from '../../reducers/actions';
import getProgramFromWallet from '../../../lib/utils/getProgramFromWallet';

function mapStateToProps({ config, wallet }) {
  return {
    iavProviderToken: config.iavProviderToken,
    iavEnv: config.iavEnv,
    program: getProgramFromWallet(wallet),
  };
}

const mapDispatchToProps = dispatch => ({
  createBankInstrument: body => dispatch(createBankInstrument(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaidLink);
