import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import styles from './CheckingAccountSummary.module.css';

const cx = classnames.bind(styles);

export default function CheckingAccountSummary({
  bankLogoUrl,
  checkingAccountIndicator,
}) {
  if (!!bankLogoUrl && !!checkingAccountIndicator) {
    return (
      <p className={cx('checkingSummary')}>
        <Image
          src={bankLogoUrl}
          alt="Bank"
          className={cx('bankLogo')}
        />
        <Text>{checkingAccountIndicator}</Text>
      </p>
    );
  }
  return null;
}

CheckingAccountSummary.propTypes = {
  bankLogoUrl: PropTypes.string,
  checkingAccountIndicator: PropTypes.string,
};

CheckingAccountSummary.defaultProps = {
  bankLogoUrl: undefined,
  checkingAccountIndicator: undefined,
};
