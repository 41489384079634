import mailcheck from 'mailcheck';

export default async function checkEmailAddress(email) {
  return new Promise((resolve) => {
    mailcheck.run({
      email,
      suggested({ full }) {
        resolve(full);
      },
      empty() {
        resolve();
      },
    });
  });
}
