import { Action } from './actions';

export const defaultState = {
  didConfirmMicrodeposits: false,
  isConfirmingDeposits: false,
  depositConfirmError: undefined,
  isActivatingCard: false,
  cardActivateError: undefined,
  isShippingCard: false,
  cardShipError: undefined,
};

export default function instrumentsReducer(state = defaultState, action = {}) {
  const body = action.payload || {};

  switch (action.type) {
    case Action.Microdeposits.ConfirmPending:
      return {
        ...state,
        isConfirmingDeposits: true,
        depositConfirmError: undefined,
      };

    case Action.Microdeposits.ConfirmCompleted:
      return {
        ...state,
        didConfirmMicrodeposits: true,
        isConfirmingDeposits: false,
      };

    case Action.Microdeposits.ConfirmFailed:
      return {
        ...state,
        isConfirmingDeposits: false,
        depositConfirmError: body,
      };

    case Action.Activation.ActivateCardPending:
      return {
        ...state,
        isActivatingCard: true,
        cardActivateError: undefined,
      };

    case Action.Activation.ActivateCardCompleted:
      return {
        ...state,
        isActivatingCard: false,
      };

    case Action.Activation.ActivateCardFailed:
      return {
        ...state,
        isActivatingCard: false,
        cardActivateError: body,
      };

    case Action.Ship.ShipCardPending:
      return {
        ...state,
        isShippingCard: true,
        cardShipError: undefined,
      };

    case Action.Ship.ShipCardCompleted:
      return {
        ...state,
        isShippingCard: false,
      };

    case Action.Ship.ShipCardFailed:
      return {
        ...state,
        isShippingCard: false,
        cardShipError: body,
      };

    default:
      return state;
  }
}
