import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import styles from './EnrolledInCredit.module.css';
import SavingsPage from '../SavingsPage';

const cx = classnames.bind(styles);

function EnrolledInCredit({ as: Element, className, shouldShowCreditStatus }) {
  // If you didn't enroll in the credit card, go away
  if (!shouldShowCreditStatus) {
    return (
      <SavingsPage />
    );
  }

  // Render a background and allow the Enrollment component to render modal
  return (
    <Element className={cx('mural', className)} />
  );
}

EnrolledInCredit.propTypes = {
  as: PropTypes.node,
  className: PropTypes.string,
  shouldShowCreditStatus: PropTypes.bool,
};

EnrolledInCredit.defaultProps = {
  as: 'div',
  className: undefined,
  shouldShowCreditStatus: true,
};

export default EnrolledInCredit;
