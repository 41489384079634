import { InstrumentTags } from '@gasbuddy/payment-serv-client/InstrumentTags';

const BANK_ACCOUNT_TYPES = ['checking_account', 'savings_account'];

const isBankAccount = instrument => BANK_ACCOUNT_TYPES.includes(instrument.instrument_type);
const isInGoodStanding = instrument => !instrument.tags?.includes(InstrumentTags.Nsf);

export default function getBankAccount(instruments = []) {
  return instruments.filter(isInGoodStanding).find(isBankAccount);
}
