import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, FlexGrid, Image, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import styles from './GasBackExportList.module.css';
import formatPrice from '../../../lib/utils/formatPrice';
import gasbackTransferTypes from '../../constants/gasbackTransferTypes';
import ChevronRightIcon from '../ChevronRightIcon';

const cx = classnames.bind(styles);

function GasBackExportButton({
  option,
  onClick,
  ...rest
}) {
  const handleClick = useCallback(() => {
    onClick(option);
  }, [onClick, option]);

  const { minimumBalance, maximumBalance, type: optionType } = option;

  const subtitle = option.subtitle || (minimumBalance && maximumBalance
    ? `${formatPrice(minimumBalance)} minimum - ${formatPrice(maximumBalance)} maximum`
    : `${formatPrice(option.minimumBalance)} minimum balance`
  );

  return (
    <Button
      key={optionType}
      color="white"
      className={cx('gasbackTransferButton')}
      onClick={handleClick}
      {...rest}
    >
      <FlexGrid container>
        <FlexGrid.Column mobile={2} className={cx('iconButtonContainer')}>
          <div className={cx('iconButton')}>
            <Image
              src={option.icon}
              alt={option.iconAlt}
              className={cx('exportButtonIcon')}
            />
          </div>
        </FlexGrid.Column>
        <FlexGrid.Column mobile={8} className={cx('buttonText')}>
          <Text bold>
            {option.title}
          </Text>
          <br />
          <Text>
            {subtitle}
          </Text>
        </FlexGrid.Column>
        <FlexGrid.Column mobile={2} className={cx('iconButtonContainer')}>
          <div className={cx('iconButton')}>
            <ChevronRightIcon />
          </div>
        </FlexGrid.Column>
      </FlexGrid>
    </Button>
  );
}

GasBackExportButton.propTypes = {
  option: PropTypes.shape({
    icon: PropTypes.string,
    iconAlt: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    type: PropTypes.string,
    maximumBalance: PropTypes.number,
    minimumBalance: PropTypes.number,
    path: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
};

GasBackExportButton.defaultProps = {
  onClick: () => {},
};

export default function GasBackExportList({
  balance,
  onSelectTransferOption,
}) {
  // Allow gasback transfer to only those options which are enabled
  const allowedOptions = gasbackTransferTypes.filter(o => o.enabled);
  const eligibleTransferOptions = allowedOptions.filter(o => !o.validate || (o.validate && balance >= o.minimumBalance));
  const eligibleTransferTypes = eligibleTransferOptions.map(o => o.type);
  const ineligibleTransferOptions = allowedOptions.filter(o => !eligibleTransferTypes.includes(o.type) && o.validate && balance < o.minimumBalance);

  return (
    <div className={cx('gasbackExportList')}>
      {!!eligibleTransferOptions.length && (
        <div>
          {eligibleTransferOptions.map(opt => (
            <GasBackExportButton
              key={opt.type}
              option={opt}
              onClick={onSelectTransferOption}
            />
          ))}
          <br />
        </div>
      )}
      {!!ineligibleTransferOptions.length && (
        <React.Fragment>
          <Text as="p">You don&apos;t meet the minimum balance for these transfer types just yet. Almost there!</Text>
          {ineligibleTransferOptions.map(opt => (
            <GasBackExportButton
              option={opt}
              key={opt.type}
              disabled
            />
          ))}
          <br />
          <br />
        </React.Fragment>
      )}
    </div>
  );
}

GasBackExportList.propTypes = {
  balance: PropTypes.string.isRequired,
  onSelectTransferOption: PropTypes.func,
};

GasBackExportList.defaultProps = {
  onSelectTransferOption: () => {},
};
