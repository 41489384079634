import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import styles from './ChequeHelper.module.css';

const cx = classnames.bind(styles);

export default function ChequeHelper({ focus }) {
  const chequeNumber = '0123';

  return (
    <div className={cx('check')}>
      <div className={cx('header')}>
        <div className={cx('left')}>
          <div className={cx('line')} />
          <div className={cx('line')} />
          <div className={cx('line')} />
        </div>
        <div className={cx('right')}>
          <span>{chequeNumber}</span>
        </div>
      </div>
      <div className={cx('section', 'topline')}>
        <div className={cx('left')}>
          <div className={cx('line')} />
        </div>
        <div className={cx('right')}>
          <span className={cx('currency')}>$</span><div className={cx('box')} />
        </div>
      </div>
      <div className={cx('section', 'bottomline')}>
        <div className={cx('left')}>
          <div className={cx('line')} />
        </div>
        <div className={cx('right')}>
          <span className={cx('text')}>DOLLARS</span>
        </div>
      </div>
      <div className={cx('footer')}>
        <div className={cx('line')} />
        <div className={cx('line')} />
      </div>
      <div className={cx('digits')}>
        <div className={cx('separator')} />
        <div className={cx('routing', 'segment', { focused: focus === 'routing' })}>
          <span>1230004567</span>
          <div className={cx('segmentText', 'fadeInDown')}>
            <div className={cx('bracket')} />
            <span>Routing</span>
          </div>
        </div>
        <div className={cx('separator')} />
        <div className={cx('account', 'segment', { focused: focus === 'account' })}>
          <span>12345678910</span>
          <div className={cx('segmentText', 'fadeInDown')}>
            <div className={cx('bracket')} />
            <span>Account</span>
          </div>
        </div>
        <div className={cx('separator')} />
        <span className={cx('number', 'segment')}>{chequeNumber}</span>
      </div>
    </div>
  );
}

ChequeHelper.propTypes = {
  focus: PropTypes.oneOf(['routing', 'account']),
};

ChequeHelper.defaultProps = {
  focus: undefined,
};
