import PropTypes from 'prop-types';
import AccountStatus from '../constants/accountStatus';
import AddressPropType from './address';
import InstrumentPropType from './instrument';

const MissingCardPropType = PropTypes.shape({
  reason: PropTypes.oneOf([
    'lost',
    'damaged',
    'never_received',
    'stolen',
    'unknown',
  ]),
  fees: PropTypes.shape({
    lost: PropTypes.string,
    damaged: PropTypes.string,
    stolen: PropTypes.string,
    unknown: PropTypes.string,
  }),
});

const ProgramPropType = PropTypes.shape({
  name: PropTypes.string,
  type: PropTypes.string,
  is_dynamic: PropTypes.bool,
  is_membership: PropTypes.bool,
  lyft_driver: PropTypes.bool,
  lyft_funded: PropTypes.bool,
  activated_at: PropTypes.string,
  per_gallon_discount: PropTypes.string,
  initial_per_gallon_discount: PropTypes.string,
  is_eligible_for_initial_discount: PropTypes.bool,
  transactions_required_for_initial_discount: PropTypes.number,
  initial_discount_expiration: PropTypes.string,
  unit_string: PropTypes.string,
});

const WalletStatusPropType = PropTypes.shape({
  programs: PropTypes.arrayOf(ProgramPropType),
  license: PropTypes.bool,
  shipping_address: AddressPropType,
  home_address: AddressPropType,
  missing_card: MissingCardPropType,
  instruments: PropTypes.arrayOf(InstrumentPropType),
  name: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
});

const WalletPropType = PropTypes.shape({
  account_status: PropTypes.oneOf(Object.values(AccountStatus)),
  status: WalletStatusPropType,
});

export default WalletPropType;
