const EnrollmentSteps = {
  ShippingAddress: 'shipping',
  DriversLicense: 'license',
  BankAccount: 'bank',
  Billing: 'billing',
  Review: 'review',
  Enrolled: 'enrolled',
};

export default EnrollmentSteps;
