import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Header, Text, Input, Label, Form, FlexGrid } from '@gasbuddy/react-components';
import { Redirect } from 'react-router-dom';
import GasBackTransferStep from '../GasBackTransferStep';
import GiftCardVendors from '../../constants/giftCardVendors';
import useTracking from '../../hooks/useTracking';
import { GiftCardVendorPropType } from '../../prop-types/gasback';
import { ANALYTICS_SCREENS, ANALYTICS_EVENTS } from '../../constants/analytics';

export default function GasBackGiftCardDetails({
  cardHolderFirstName,
  cardHolderLastName,
  consumerHost,
  history,
  idempotencyId,
  recipientEmail,
  transferAmount,
  updateGasBackTransferDetails,
  vendorId,
}) {
  const { trackEvent } = useTracking(ANALYTICS_SCREENS.PAY_REDEEM_GIFTCARD_DETAILS);

  const [firstName, setFirstName] = useState(cardHolderFirstName);
  const [lastName, setLastName] = useState(cardHolderLastName);

  const chosenGiftCard = useMemo(() => GiftCardVendors.find(o => o.id === vendorId), [vendorId]);

  const handleFieldChange = useCallback(({ target }) => {
    const { name, value } = target;

    if (name === 'firstName') {
      setFirstName(value);
    } else {
      setLastName(value);
    }
  }, []);

  const handleSubmit = useCallback((e) => {
    trackEvent(ANALYTICS_EVENTS.PAY_REDEEM_GIFTCARD_DETAILS_SUBMIT_CLICKED);

    updateGasBackTransferDetails(e, {
      firstName,
      lastName,
    });

    history.push('/gasback/transfer/giftcard/confirm');
  }, [firstName, history, lastName, trackEvent, updateGasBackTransferDetails]);

  const handleCancel = useCallback(() => {
    trackEvent(ANALYTICS_EVENTS.PAY_REDEEM_GIFTCARD_DETAILS_CANCEL_CLICKED);
  }, [trackEvent]);

  useEffect(() => {
    setFirstName(cardHolderFirstName);
  }, [cardHolderFirstName]);

  useEffect(() => {
    setLastName(cardHolderLastName);
  }, [cardHolderLastName]);

  if (!chosenGiftCard) {
    return (
      <Redirect to="/gasback/transfer/giftcard" />
    );
  }

  return (
    <Form method="POST" action="/gasback/transfer/giftcard" onSubmit={handleSubmit}>
      <GasBackTransferStep
        backLinkText="Choose Gift Card"
        context={{ screen: ANALYTICS_SCREENS.PAY_REDEEM_GIFTCARD_DETAILS }}
      >
        <Header as="h3" snug>What is your first and last name?</Header>
        <Text as="p">We need your first and last name in order to send you a digital gift card code.</Text>
        <br />
        <FlexGrid container>
          <FlexGrid.Column tablet={5}>
            <input type="hidden" name="idempotencyId" value={idempotencyId} />
            <input type="hidden" name="vendorId" value={vendorId} />
            <input type="hidden" name="amount" value={transferAmount} />
            <input type="hidden" name="recipientEmail" value={recipientEmail} />
            <Label htmlFor="firstName">First Name</Label>
            <Input name="firstName" value={firstName} placeholder="Enter your first name" onChange={handleFieldChange} />
            <Label htmlFor="lastName">Last Name</Label>
            <Input name="lastName" value={lastName} placeholder="Enter your last name" onChange={handleFieldChange} />
          </FlexGrid.Column>
        </FlexGrid>
        <br />
        <GasBackTransferStep.Actions>
          <GasBackTransferStep.Button
            type="submit"
            primary
            fluid
            disabled={!firstName || !lastName}
          >
            Continue
          </GasBackTransferStep.Button>
          <GasBackTransferStep.Button
            as="a"
            link
            href={`//${consumerHost}/account/savings`}
            onClick={handleCancel}
          >
            Cancel
          </GasBackTransferStep.Button>
        </GasBackTransferStep.Actions>
      </GasBackTransferStep>
    </Form>
  );
}

GasBackGiftCardDetails.propTypes = {
  cardHolderFirstName: PropTypes.string,
  cardHolderLastName: PropTypes.string,
  consumerHost: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  idempotencyId: PropTypes.string.isRequired,
  recipientEmail: PropTypes.string,
  transferAmount: PropTypes.string,
  updateGasBackTransferDetails: PropTypes.func,
  vendorId: GiftCardVendorPropType,
};

GasBackGiftCardDetails.defaultProps = {
  cardHolderFirstName: undefined,
  cardHolderLastName: undefined,
  consumerHost: 'www.gasbuddy.com',
  history: {
    push: () => {},
  },
  recipientEmail: undefined,
  transferAmount: undefined,
  updateGasBackTransferDetails: () => {},
  vendorId: undefined,
};
