import { connect } from 'react-redux';
import IntermediaryEnrollment from './IntermediaryEnrollment';

function mapStateToProps({ wallet }) {
  return {
    walletBanners: wallet.banners,
  };
}

export default connect(mapStateToProps)(IntermediaryEnrollment);
