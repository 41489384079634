import { connect } from 'react-redux';
import IavLink from './IavLink';
import { createBankInstrumentWithValidiFi, fetchIavProviderDetails } from '../../reducers/actions';
import getProgramFromWallet from '../../../lib/utils/getProgramFromWallet';

function mapStateToProps({ config, wallet }) {
  return {
    env: config.env,
    iavSessionToken: config.iavProviderToken,
    iavEnv: config.iavEnv,
    program: getProgramFromWallet(wallet),
  };
}

const mapDispatchToProps = dispatch => ({
  createBankInstrument: body => dispatch(createBankInstrumentWithValidiFi(body)),
  fetchIavProviderDetails: () => dispatch(fetchIavProviderDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IavLink);
