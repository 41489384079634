import { connect } from 'react-redux';
import GasBackTransferError from './GasBackTransferError';
import getErrorMessage from '../../../lib/utils/getErrorMessage';

function mapStateToProps({ gasback }) {
  const { error } = gasback.transfer;

  return {
    error: getErrorMessage(error, 'Error processing your GasBack transfer request. Please try again later.'),
  };
}

export default connect(mapStateToProps)(GasBackTransferError);
