import { connect } from 'react-redux';
import RenewCardFlow from './RenewCardFlow';
import { renewCard } from '../../reducers/actions';
import getErrorMessage from '../../../lib/utils/getErrorMessage';

function mapStateToProps({ payCard }) {
  const { canRenew, error, wasRenewed } = payCard;

  return {
    canRenew,
    error: getErrorMessage(error),
    wasRenewed,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    renewCard: () => {
      dispatch(renewCard());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewCardFlow);
