import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@gasbuddy/react-components';
import { withIav } from '../IavButton';

function IavLink({ children, ...rest }) {
  return (
    <Link uppercase {...rest}>
      {children}
    </Link>
  );
}

IavLink.propTypes = {
  children: PropTypes.node,
};

IavLink.defaultProps = {
  children: 'Link Checking Account',
};

export default withIav(IavLink);
