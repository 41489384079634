import { connect } from 'react-redux';
import Summary from './CheckingAccountSummary';
import getBankAccount from '../../../lib/utils/getBankAccount';

const mapStateToProps = ({ wallet }) => {
  // Technically the schema supports the ability to have checking and savings account both
  // we use the first account instrument to show bank summary
  const bankAccount = getBankAccount(wallet.status?.instruments);

  return {
    bankLogoUrl: bankAccount?.logo_url,
    checkingAccountIndicator: bankAccount?.friendly_name,
  };
};

const CheckingAccountSummary = connect(mapStateToProps)(Summary);
export default CheckingAccountSummary;
