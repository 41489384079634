import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@gasbuddy/react-components';
import formatPrice from '../../../lib/utils/formatPrice';
import GasBackTransferStep from '../GasBackTransferStep';

export default function ProcessingGasBackBankTransfer({
  consumerHost,
}) {
  return (
    <GasBackTransferStep
      title="You're almost there! Confirm the two small deposits to complete your transfer."
      subtitle={(
        <React.Fragment>
          To verify your bank account, we&apos;ll make two small deposits of {formatPrice(0.10)} or less to your account in the next 3-4 business days.
          To confirm the deposits, please check your account and verify the deposits from the <Text as="a" href={`//${consumerHost}/account/savings`} bold>My Savings</Text> screen in your GasBuddy account.
        </React.Fragment>
      )}
      smallHeader
    >
      <Text as="p" bold>Once you confirm the deposits, the full amount will be transferred to your account.</Text>
      <br />
      <GasBackTransferStep.Actions>
        <GasBackTransferStep.Button
          primary
          fluid
          as="a"
          href={`//${consumerHost}/account/savings`}
        >
          Done
        </GasBackTransferStep.Button>
      </GasBackTransferStep.Actions>
    </GasBackTransferStep>
  );
}

ProcessingGasBackBankTransfer.propTypes = {
  consumerHost: PropTypes.string,
};

ProcessingGasBackBankTransfer.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
};
