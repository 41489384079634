import { Action } from './actions';

export default function featuresReducer(state = {}, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case Action.Features.FeaturesCompleted:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}
