import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@gasbuddy/react-components';
import { stateAbbrOptions } from '../../constants/states';

export default function StatesDropdown({ onChange, ...rest }) {
  return (
    <Dropdown
      id="stateSelect"
      label="Select state"
      onChange={onChange}
      options={stateAbbrOptions}
      searchable
      {...rest}
    />
  );
}

StatesDropdown.propTypes = {
  onChange: PropTypes.func,
};

StatesDropdown.defaultProps = {
  onChange: () => {},
};
