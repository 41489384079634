import { connect } from 'react-redux';
import MembershipBilling from './MembershipBilling';
import { setOfferingId } from '../../reducers/actions';
import PayPrograms from '../../constants/payPrograms';

function mapStateToProps({ membership, progress }, { program }) {
  const {
    plus: {
      annual: annualPlusOffer,
      monthly: monthlyPlusOffer,
    },
    premium: {
      annual: annualPremiumOffer,
      monthly: monthlyPremiumOffer,
    },
  } = membership;
  let options = [];

  if (program === PayPrograms.Plus) {
    options = [
      monthlyPlusOffer,
      annualPlusOffer,
    ];
  } else if (program === PayPrograms.Premium) {
    options = [
      monthlyPremiumOffer,
      annualPremiumOffer,
    ];
  }

  return {
    options,
    offeringId: progress.offeringId || options[0]?.plan_offering_id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: ({ offeringId }) => dispatch(setOfferingId(offeringId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipBilling);
