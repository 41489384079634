import { connect } from 'react-redux';
import UuidEncoder from 'uuid-encoder';
import { goToStep, handleMastercardDecision } from '../../reducers/actions';
import Enrollment from './Enrollment';

function mapStateToProps({ auth, config, features, progress, membership, wallet }) {
  const { offeringId, partner, stepId } = progress;
  const { declinedMembership, upgraded } = membership;
  const { receivesPrescreen } = features?.features?.find(feature => feature.n === 'enroll_cc')?.p || {};

  // Create Base 36 encoder
  const encoder = new UuidEncoder('base36');

  return {
    address: wallet.status?.shipping_address,
    declinedMembership,
    email: auth.email || progress.email,
    firstName: wallet.status?.name?.first_name,
    isMobile: config.isMobile,
    isProduction: config.env === 'production',
    lastName: wallet.status?.name?.last_name,
    loyaltyId: auth?.guid && encoder.encode(auth.guid),
    offeringId,
    partner,
    shouldPrescreen: progress.shouldPrescreen && receivesPrescreen,
    shouldShowCreditStatus: progress.shouldShowStatus,
    stepId,
    upgraded,
    wallet,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    goToStep(index) {
      dispatch(goToStep(index));
    },
    onMastercardApply(body) {
      dispatch(handleMastercardDecision(body));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Enrollment);
