import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@gasbuddy/react-components';
import { withPlaid } from '../PlaidButton/PlaidButton';

function PlaidLink({ loading, ...rest }) {
  return (
    <Link uppercase {...rest} />
  );
}

PlaidLink.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
};

PlaidLink.defaultProps = {
  children: 'Link Checking Account',
  loading: false,
};

export default withPlaid(PlaidLink);
