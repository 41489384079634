/* global window document */
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import appReducer from '../common/reducers';

export default function renderEntryPoint({
  router: Router,
  rootSelector = '#container',
  initialState,
  apiMiddleware,
}) {
  const rootNode = document.querySelector(rootSelector);
  const supportsHistory = 'pushState' in window.history;

  const store = configureStore({
    reducer: appReducer,
    preloadedState: initialState,
    middleware: [apiMiddleware],
  });

  const render = (Component) => {
    hydrateRoot(rootNode, (
      <HelmetProvider>
        <BrowserRouter forceRefresh={!supportsHistory}>
          <Provider store={store}>
            <Component />
          </Provider>
        </BrowserRouter>
      </HelmetProvider>
    ));
  };

  render(Router);
}
