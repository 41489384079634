import React from 'react';
import PropTypes from 'prop-types';
import Container from '../Container';
import LoginForm from '../LoginForm';
import Panel from '../Panel';
import getQueryParamsFromLocation from '../../../lib/utils/getQueryParamsFromLocation';

function RequireLogin({
  allowGuid,
  basePath,
  children,
  isLoggedIn,
  location,
  returnPath,
}) {
  const queryParams = getQueryParamsFromLocation(location);
  const accountGuid = queryParams.u;
  const canUseGuid = allowGuid && accountGuid;

  if (!isLoggedIn && !canUseGuid) {
    return (
      <Container>
        <Panel gradient>
          <LoginForm basePath={basePath} returnPath={returnPath} />
        </Panel>
      </Container>
    );
  }

  return children;
}

RequireLogin.propTypes = {
  allowGuid: PropTypes.bool,
  basePath: PropTypes.string,
  children: PropTypes.node,
  isLoggedIn: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  returnPath: PropTypes.string,
};

RequireLogin.defaultProps = {
  allowGuid: false,
  basePath: undefined,
  children: undefined,
  isLoggedIn: false,
  location: undefined,
  returnPath: undefined,
};

export default RequireLogin;
