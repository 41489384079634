import { Constraint, Text, Actions } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React from 'react';
import CardActivationHeader from '../CardActivationHeader';

export default function CardActivationComplete({ consumerHost }) {
  const message = 'You are officially ready to start saving. Go ahead and use your card every time you\'re filling up.';
  return (
    <Constraint desktop={10}>
      <CardActivationHeader
        title="All set!"
        subtitle="Card activation complete"
      />
      <br />
      <Text style={{ fontSize: '1.1rem' }}>{message}</Text>
      <br />
      <br />
      <br />
      <Actions>
        <Actions.Button
          as="a"
          href={`https://${consumerHost}/account/savings`}
          primary
          type="submit"
          tablet={6}
          desktop={5}
        >
          Go To Savings
        </Actions.Button>
        <Actions.Button
          as="a"
          href="https://routing.gasbuddy.com/app"
          target="_blank"
          secondary
          tablet={6}
          desktop={5}
        >
          Get The App
        </Actions.Button>
      </Actions>
    </Constraint>
  );
}

CardActivationComplete.propTypes = {
  consumerHost: PropTypes.string,
};

CardActivationComplete.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
};
