const PASSWORD_REGEX = /^.{4,}$/;

/**
 * Tests that a password meets complexity requirements
 *
 * @param {string} password - an password string to test
 * @returns {boolean} - Whether the password appears to meet complexity requirements
 */
export default function isValidPassword(password) {
  return PASSWORD_REGEX.test(password);
}
