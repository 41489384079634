import { connect } from 'react-redux';
import LicenseForm from './LicenseForm';
import { saveLicense } from '../../reducers/actions';
import getErrorMessage from '../../../lib/utils/getErrorMessage';

function mapStateToProps({ wallet, profile }) {
  const { isSavingLicense, licenseError } = profile;
  return {
    wallet,
    isSaving: isSavingLicense,
    error: getErrorMessage(licenseError),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (e, body) => {
      e.preventDefault();
      dispatch(saveLicense(body));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LicenseForm);
