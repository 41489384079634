import { connect } from 'react-redux';
import ConfirmGasBackBankTransfer from './ConfirmGasBackBankTransfer';
import { transferGasBackToBankAccount } from '../../reducers/actions';
import getErrorMessage from '../../../lib/utils/getErrorMessage';

function mapStateToProps({ gasback, config }) {
  const {
    idempotencyId,
    isTransferring: transferPending,
    error,
    success: transferInitiated,
  } = gasback.transfer;

  const gasbackTransferDetails = gasback.transfer.details || {};

  const {
    routingNumber,
    accountNumber,
    amount: transferAmount,
    recipientEmail,
  } = gasbackTransferDetails;

  return {
    idempotencyId,
    consumerHost: config.consumerHost,
    routingNumber,
    accountNumber,
    transferAmount,
    recipientEmail,
    transferPending,
    error: getErrorMessage(error, 'There was an error processing the transfer request. Please try again later.'),
    transferInitiated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (e, body) => {
      e.preventDefault();
      dispatch(transferGasBackToBankAccount(body));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmGasBackBankTransfer);
