import React from 'react';
import { Container as RCContainer, FlexGrid, Image } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import styles from './Container.module.css';
import Footer from '../Footer';

const cx = classnames.bind(styles);

// TODO: When GradientContainer is fully fleshed out, we shouldn't need this component anymore
export default function Container({ children, sidebar }) {
  return (
    <RCContainer>
      <FlexGrid>
        <FlexGrid.Column tablet={10} tabletOffset={2}>
          <div className={cx('logo')}>
            <Image className={cx('logoImage')} alt="GasBuddy" src="https://static.gasbuddy.com/web/pay/svg/gb-blue-horizontal-rgb.svg" />
          </div>
        </FlexGrid.Column>
        <FlexGrid.Column className={cx('statusContainer')} tablet={3} desktop={2}>
          {sidebar}
        </FlexGrid.Column>
        <FlexGrid.Column tablet={9} desktop={10}>
          {children}
        </FlexGrid.Column>
        <FlexGrid.Column tablet={9} tabletOffset={3} desktop={8}>
          <Footer />
        </FlexGrid.Column>
      </FlexGrid>
    </RCContainer>
  );
}

Container.propTypes = {
  children: PropTypes.node,
  sidebar: PropTypes.node,
};

Container.defaultProps = {
  children: undefined,
  sidebar: undefined,
};
