import { connect } from 'react-redux';
import BankVerified from './BankVerified';
import getBankAccount from '../../../lib/utils/getBankAccount';

function mapStateToProps({ wallet, config }) {
  const { instruments } = wallet;
  const { consumerHost } = config;
  const bankAccount = getBankAccount(instruments);
  const usedIAV = bankAccount?.tags?.includes('iav');
  const subtitle = usedIAV ? 'Your Account Has Been Verified' : 'Microdeposit Confirmation Complete';

  return {
    consumerHost,
    subtitle,
  };
}

export default connect(mapStateToProps)(BankVerified);
