import { connect } from 'react-redux';
import {
  resetAddressError,
  resolveLocationFromZipCode,
  updateShippingAddress,
  validateShippingAddress,
} from '../../reducers/actions';
import Form from './ShippingAddressForm';

const mapStateToProps = ({ auth, geo, progress, shippingAddress, wallet }) => ({
  address: wallet?.status?.shipping_address,
  defaultPostalCode: progress?.postal,
  email: auth.email || progress.email,
  error: shippingAddress?.error,
  errorFetchingLocationInfo: geo.error,
  firstName: wallet.status?.name?.first_name,
  isFetchingLocationInfo: geo.isFetching,
  isLoggedIn: !!auth.account_id,
  isUpdating: !!shippingAddress?.isUpdating,
  isValidating: shippingAddress?.isValidating,
  lastName: wallet.status?.name?.last_name,
  locationInfo: geo.location,
  result: shippingAddress?.result,
});

const mapDispatchToProps = dispatch => ({
  fetchLocationInfo: zipCode => dispatch(resolveLocationFromZipCode(zipCode)),
  resetAddressError: () => dispatch(resetAddressError()),
  saveShippingAddress: (body) => {
    dispatch(updateShippingAddress(body));
  },
  validateShippingAddress: (body, analyticsContext) => {
    dispatch(validateShippingAddress(body, analyticsContext));
  },
});

const ShippingAddressForm = connect(mapStateToProps, mapDispatchToProps)(Form);
export default ShippingAddressForm;
