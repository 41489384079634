import { Action } from './actions';

const defaultState = {
  isFetching: false,
  error: undefined,
  location: undefined,
};

export default function geoReducer(state = defaultState, action = {}) {
  const { payload } = action;

  switch (action.type) {
    case Action.Geo.ResolveLocationFromZipCodePending:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };

    case Action.Geo.ResolveLocationFromZipCodeCompleted:
      return {
        ...state,
        isFetching: false,
        location: payload,
      };

    case Action.Geo.ResolveLocationFromZipCodeFailed:
      return {
        ...state,
        isFetching: false,
        error: 'Error resolving location from zipcode',
      };

    default:
      return state;
  }
}
