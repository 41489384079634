import { connect } from 'react-redux';
import EnrolledInCredit from './EnrolledInCredit';

function mapStateToProps({ progress }) {
  return {
    shouldShowCreditStatus: progress.shouldShowStatus,
  };
}

export default connect(mapStateToProps)(EnrolledInCredit);
