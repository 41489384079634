import PropTypes from 'prop-types';

const AddressPropType = PropTypes.shape({
  line_1: PropTypes.string,
  line_2: PropTypes.string,
  locality: PropTypes.string,
  region: PropTypes.string,
  postal_code: PropTypes.string,
  country: PropTypes.string,
  cross_street: PropTypes.string,
  cross_street_alias: PropTypes.string,
  address_alias: PropTypes.string,
  at_intersection: PropTypes.bool,
  nearby_street: PropTypes.string,
  description: PropTypes.string,
  deliverability_token: PropTypes.string,
});

export default AddressPropType;
