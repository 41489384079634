import { Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import formatPrice from '../../../lib/utils/formatPrice';
import { ANALYTICS_EVENTS, ANALYTICS_SCREENS } from '../../constants/analytics';
import GiftCardVendors from '../../constants/giftCardVendors';
import useTracking from '../../hooks/useTracking';
import CardChoiceConfirmation from '../CardChoiceConfirmation';
import GasBackTransferError from '../GasBackTransferError';
import GasBackTransferStep from '../GasBackTransferStep';

export default function GasBackGiftCardPurchaseComplete({
  consumerHost,
  firstName,
  lastName,
  vendorId: chosenGiftCardVendorId,
  amount,
  email,
  error,
}) {
  const { trackEvent } = useTracking(ANALYTICS_SCREENS.PAY_REDEEM_GIFTCARD_COMPLETE);

  const chosenVendor = useMemo(() => GiftCardVendors.find(vendor => vendor.id === chosenGiftCardVendorId), [chosenGiftCardVendorId]);

  const handleReset = useCallback(() => {
    trackEvent(ANALYTICS_EVENTS.PAY_REDEEM_GIFTCARD_PURCHASE_SUBMIT_CLICKED);
  }, [trackEvent]);

  if (error) {
    return (
      <GasBackTransferError />
    );
  }

  if (!chosenVendor) {
    return null;
  }

  const title = 'Woohoo! Your digital gift card code is on the way!';
  const subtitle = 'Keep an eye out for a separate email with the gift card code and instructions for use. Happy Shopping!';

  return (
    <GasBackTransferStep
      context={{ screen: ANALYTICS_SCREENS.PAY_REDEEM_GIFTCARD_COMPLETE }}
      title={title}
      subtitle={subtitle}
      smallHeader
    >
      <CardChoiceConfirmation
        imageUrl={chosenVendor.imageUrl}
        cardVendorName={chosenVendor.displayName}
      >
        <Text as="p">Gift Card Amount</Text>
        <Text as="p" bold>{formatPrice(amount, 2)}</Text>
        <br />
        <Text as="p">Your Name</Text>
        <Text as="p" bold>{firstName} {lastName}</Text>
        <br />
        <Text as="p">Your confirmation and gift code will be sent to</Text>
        <Text as="p" bold>{email}</Text>
        <br />
        <CardChoiceConfirmation.Terms
          title="Gift card details"
          content={chosenVendor.terms}
          detailsUrl={chosenVendor.detailsUrl}
        />
        <GasBackTransferStep.Actions>
          <GasBackTransferStep.Button
            primary
            fluid
            as="a"
            href={`//${consumerHost}/account/savings`}
            onClick={handleReset}
          >
            Done
          </GasBackTransferStep.Button>
        </GasBackTransferStep.Actions>
      </CardChoiceConfirmation>
    </GasBackTransferStep>
  );
}

GasBackGiftCardPurchaseComplete.propTypes = {
  consumerHost: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  vendorId: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  error: PropTypes.string,
};

GasBackGiftCardPurchaseComplete.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
  error: undefined,
};
