import charityOptions from './charityOptions';

const enabledCharities = charityOptions.filter(o => o.enabled);

const transferOptions = [
  {
    icon: '//static.gasbuddy.com/web/icons/pump-split.svg',
    iconAlt: 'Pay with GasBuddy',
    title: 'Get a GasBuddy card',
    subtitle: 'Save up to 25¢/gallon per fill-up',
    validate: false,
    path: '/',
    type: 'payWithGasBuddy',
    enabled: true,
  },
  {
    icon: '//static.gasbuddy.com/web/icons/bank-split.svg',
    iconAlt: 'Bank',
    title: 'Transfer to my bank',
    minimumBalance: 10,
    validate: true,
    path: '/gasback/transfer/bank',
    type: 'bank',
    enabled: false,
  },
  {
    icon: '//static.gasbuddy.com/web/icons/card-split.svg',
    iconAlt: 'Gift Card',
    title: 'Get a digital gift card',
    minimumBalance: 5,
    maximumBalance: 1000,
    validate: true,
    path: '/gasback/transfer/giftcard',
    type: 'giftCard',
    enabled: true,
  },
  {
    icon: undefined,
    iconAlt: 'GasBuddy',
    title: 'Send to another GasBuddy member',
    minimumBalance: 10,
    validate: true,
    path: '/gasback/transfer/family-or-friend',
    type: 'anotherGasBuddy',
    enabled: false,
  },
  {
    icon: '//static.gasbuddy.com/web/icons/heart-split.svg',
    iconAlt: 'Heart',
    title: enabledCharities.length === 1 ? `Donate to ${enabledCharities[0].name}` : 'Donate to charity',
    minimumBalance: 0.5,
    validate: true,
    path: '/gasback/transfer/charity',
    type: 'charity',
    enabled: true,
  },
];

export default transferOptions;
