import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import AuthPropType from '../../prop-types/auth';
import scrollToTop from '../../../lib/utils/scrollToTop';

export default function PrivateRoute({ auth, component: Component, redirectTo, render, ...rest }) {
  const renderIfAuth = useCallback((routerProps) => {
    if (auth.account_id) {
      scrollToTop();
      return Component ? (
        <Component {...routerProps} />
      ) : render(routerProps);
    }

    return (
      <Redirect
        to={{
          pathname: redirectTo,
          state: { from: routerProps.location },
        }}
      />
    );
  }, [auth.account_id, redirectTo, render, Component]);

  return (
    <Route {...rest} render={renderIfAuth} />
  );
}

PrivateRoute.propTypes = {
  auth: AuthPropType,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  redirectTo: PropTypes.string,
  render: PropTypes.func,
};

PrivateRoute.defaultProps = {
  auth: {},
  component: undefined,
  redirectTo: '/',
  render: undefined,
};
