import React from 'react';
import PropTypes from 'prop-types';
import StatusBanner from '../StatusBanner/StatusBanner';

export default function IntermediaryEnrollment({ walletBanners }) {
  const statusBanners = walletBanners.map(banner => (
    {
      accessory: banner.accessory,
      body: banner.items.filter(item => item.type === 'body').map(item => item.text),
      buttons: banner.items
        .filter(item => item.type.indexOf('button') >= 0)
        .map(item => ({
          ...item,
          type: item.type.slice(0, (item.type.indexOf('_'))),
        })),
      clickUrl: banner.tap_url,
      titles: banner.items.filter(item => item.type === 'title').map(item => item.text),
      type: banner.style,
    }
  ));

  return statusBanners.map(banner => (
    <StatusBanner
      key={banner.body.join(' ')}
      accessory={banner.accessory}
      body={banner.body}
      buttons={banner.buttons}
      clickUrl={banner.clickUrl}
      titles={banner.titles}
      type={banner.type}
    />
  ));
}

IntermediaryEnrollment.propTypes = {
  walletBanners: PropTypes.arrayOf(PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })),
    style: PropTypes.string,
    accessory: PropTypes.oneOf(['warning', 'error']),
  })),
};

IntermediaryEnrollment.defaultProps = {
  walletBanners: [],
};
