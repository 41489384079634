import { connect } from 'react-redux';
import getErrorMessage from '../../../lib/utils/getErrorMessage';
import {
  resetAddressError,
  reshipCard,
  resolveLocationFromZipCode,
  validateShippingAddress,
} from '../../reducers/actions';
import Form from '../ShippingAddressForm/ShippingAddressForm';

const defaultErrorMsg = 'There was an error updating shipping address, please try again later.';

const mapStateToProps = ({ auth, geo, progress, shippingAddress, wallet }) => ({
  action: '/reship',
  address: wallet?.status?.shipping_address,
  defaultPostalCode: progress?.postal,
  email: auth.email || progress.email,
  error: getErrorMessage(shippingAddress.error, defaultErrorMsg),
  errorFetchingLocationInfo: geo.error,
  firstName: wallet.status?.name?.first_name,
  isFetchingLocationInfo: geo.isFetching,
  isLoggedIn: !!auth.account_id,
  isUpdating: !!shippingAddress?.isUpdating,
  isValidating: shippingAddress?.isValidating,
  lastName: wallet.status?.name?.last_name,
  locationInfo: geo.location,
  result: shippingAddress?.result,
  primaryButtonText: 'Confirm Address',
});

const mapDispatchToProps = dispatch => ({
  fetchLocationInfo: zipcode => dispatch(resolveLocationFromZipCode(zipcode)),
  resetAddressError: () => dispatch(resetAddressError()),
  saveShippingAddress: (body) => {
    dispatch(reshipCard(body));
  },
  validateShippingAddress: (body, analyticsContext) => {
    dispatch(validateShippingAddress(body, analyticsContext));
  },
});

const ShippingAddressForm = connect(mapStateToProps, mapDispatchToProps)(Form);
export default ShippingAddressForm;
