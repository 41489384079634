import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GasBackRedeem from './GasBackRedeem';
import { redeemCodeForGasBack, resetGasBackRedemption } from '../../reducers/actions';
import getQueryParamsFromLocation from '../../../lib/utils/getQueryParamsFromLocation';

function mapStateToProps({ gasback }, { location }) {
  const { amount, error, isRedeeming, promotionId, offerId } = gasback.redeem || {};
  const { shortlink } = getQueryParamsFromLocation(location);
  return {
    amountRedeemed: amount,
    isRedeeming,
    redeemedOfferId: offerId,
    redeemedPromotionId: promotionId,
    redemptionError: error,
    shortLinkPartner: shortlink || 'n/a',
  };
}

function mapDispatchToProps(dispatch) {
  return ({
    onSubmitCode: (e, body) => {
      e.preventDefault();
      dispatch(redeemCodeForGasBack(body));
    },
    resetForm: () => dispatch(resetGasBackRedemption()),
  });
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GasBackRedeem));
