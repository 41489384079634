import React from 'react';
import { Button, Constraint, Header, Image, Text } from '@gasbuddy/react-components';
import { ANALYTICS_SCREENS } from '../../constants/analytics';
import PayPrograms from '../../constants/payPrograms';
import ProgramPropType from '../../prop-types/program';
import useTracking from '../../hooks/useTracking';

export default function UpgradeSuccess({ program }) {
  useTracking(ANALYTICS_SCREENS.PAY_PREMIUM_UPGRADED);

  const nextStepsPoints = [
    `Your current Pay with GasBuddy card is now upgraded to ${program}. There’s no need for a new card.`,
    'Your upgraded fuel discounts are available immediately.',
  ];

  if (program === PayPrograms.Premium) {
    nextStepsPoints.push('Roadside service goes into effect 72 hours from your purchase date (today).');
  }

  nextStepsPoints.push('Your monthly gallon limit begins today and restarts on the same date every month.');

  return (
    <Constraint desktop={10}>
      <Image
        alt="GasBuddy Pay Card"
        src="https://static.gasbuddy.com/web/pay/svg/card-tilted-shadow-v2.svg"
        width={80}
      />
      <Header as="h2">Success! You’re now a GasBuddy<br />{program} member!</Header>
      <Text as="p" bold>Here’s what happens next:</Text>
      <ul>
        {nextStepsPoints.map(text => (
          <li key={text}>{text}</li>
        ))}
      </ul>
      <br />
      <Button as="a" href="https://routing.gasbuddy.com/app" primary cta target="_blank">
        Download the app
      </Button>
    </Constraint>
  );
}

UpgradeSuccess.propTypes = {
  program: ProgramPropType,
};

UpgradeSuccess.defaultProps = {
  program: PayPrograms.Premium,
};
