import { Constraint, FlexGrid, Form, Input, Link, Loader, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { VALID_INTEGER_REGEX } from '../../../lib/utils/isValidInteger';
import { MINIMUM_ACCOUNT_NUMBER_LENGTH, VALID_ROUTING_NUMBER_LENGTH } from '../../constants';
import { ANALYTICS_SCREENS } from '../../constants/analytics';
import useTracking from '../../hooks/useTracking';
import ChequeHelper from '../ChequeHelper';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import IavLink from '../IavLink';
import PlaidLink from '../PlaidLink';
import styles from './CheckingAccountManual.module.css';

const cx = classnames.bind(styles);

export default function LinkAccountManually({
  iavProvider,
  bankAccountError,
  detailLoading,
  fetchBankDetails,
  isSaving,
  onSubmit,
  program,
  resetBankAccountError,
  routingNumberError,
}) {
  useTracking(ANALYTICS_SCREENS.PAY_ENROLL_BANK_MICRODEPOSIT);

  const defaultFormProperties = {
    routingNumber: '',
    accountNumber: '',
  };

  const [recaptchaResponse, setRecaptchaResponse] = useState(null);
  const [focused, setFocused] = useState();
  const [formValues, setFormValues] = React.useState(defaultFormProperties);

  const routingNumberFormatValid = formValues.routingNumber.length === VALID_ROUTING_NUMBER_LENGTH;
  const accountNumberFormatValid = formValues.accountNumber.length > MINIMUM_ACCOUNT_NUMBER_LENGTH;
  const canSubmit = routingNumberFormatValid && accountNumberFormatValid && recaptchaResponse && !routingNumberError && !bankAccountError;
  const onAccountFocus = useCallback(() => setFocused('account'), []);
  const onRoutingFocus = useCallback(() => setFocused('routing'), []);
  const onAccountBlur = useCallback(() => setFocused(focused === 'account' ? undefined : focused), [focused]);
  const onRoutingBlur = useCallback(() => {
    if (routingNumberFormatValid) {
      fetchBankDetails(formValues.routingNumber);
    }

    setFocused(focused === 'routing' ? undefined : focused);
  }, [focused, formValues.routingNumber, fetchBankDetails, routingNumberFormatValid]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    onSubmit(e, {
      'g-recaptcha-response': recaptchaResponse,
      routingNumber: formValues.routingNumber,
      accountNumber: formValues.accountNumber,
      program,
    });
  }, [formValues.routingNumber, formValues.accountNumber, onSubmit, program, recaptchaResponse]);

  const handleFieldChange = React.useCallback(({ target }) => {
    const { name, value } = target;
    resetBankAccountError();
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }, [formValues, resetBankAccountError]);


  const handleRecaptchaVerified = useCallback((response) => {
    setRecaptchaResponse(response);
  }, []);

  // NOTE: We use different Instant Account Verification providers
  // For WEX enrolment, Plaid is a default choice,
  // whereas for new enrolments using Fleetcor, ValidiFi is a default choice
  const IavLinkComponent = iavProvider === 'validifi' ? IavLink : PlaidLink;

  return (
    <Form action="post" className={cx('formContainer')} onSubmit={handleSubmit} loading={isSaving}>
      <Input className={cx('programInput')} type="hidden" name="program" value={program} />
      <FlexGrid container className={cx('checkingContainer')}>
        <FlexGrid.Column mobile={12} tablet={6}>
          <Text bold>Link your account manually</Text>
          <Text as="p">
            Enter your checking account and routing number. In 1-3 business days you will receive two&nbsp;
            <Link
              bold
              to="https://help.gasbuddy.com/hc/en-us/articles/360034169934-Linking-your-bank-account"
              target="_blank"
            >
              small deposits
            </Link>
            &nbsp;in the account you linked.
          </Text>
          <Text as="p" className={cx('returnToEnrollCopy')}>
            Return to enroll.gasbuddy.com or the &apos;Savings&apos; tab in the GasBuddy app to confirm these small deposit amounts to verify your account.
            Once your account has been verified, the small deposits will be withdrawn from your account.
          </Text>
          <IavLinkComponent bold uppercase>
            Or Link Account Instantly
          </IavLinkComponent>
          <br />
          <br />
        </FlexGrid.Column>

        <FlexGrid.Column mobile={12} tablet={6} className={cx('checkColumn')}>
          <ChequeHelper focus={focused} />
        </FlexGrid.Column>

        <FlexGrid.Column tablet={6} className={cx('formColumn', 'columnLeft')}>
          <Input
            id="routing"
            mask={VALID_INTEGER_REGEX}
            name="routingNumber"
            value={formValues.routingNumber}
            label="Routing number"
            error={routingNumberError}
            onChange={handleFieldChange}
            onFocus={onRoutingFocus}
            onBlur={onRoutingBlur}
            inputMode="numeric"
            required
            innerComponent={!!detailLoading && (
              <Loader size="sm" />
            )}
          />
        </FlexGrid.Column>
        <FlexGrid.Column tablet={6} className={cx('formColumn', 'columnRight')}>
          <Input
            id="account"
            mask={VALID_INTEGER_REGEX}
            name="accountNumber"
            value={formValues.accountNumber}
            label="Checking account number"
            onChange={handleFieldChange}
            onFocus={onAccountFocus}
            onBlur={onAccountBlur}
            inputMode="numeric"
            required
          />
        </FlexGrid.Column>
      </FlexGrid>
      <Constraint desktop={10}>
        <GoogleReCaptcha
          action="bank"
          onVerify={handleRecaptchaVerified}
        />
        {(routingNumberError || bankAccountError) && (
          <Text as="p" color="orange">
            {routingNumberError || bankAccountError}
          </Text>
        )}
        <FormActionButtons
          primaryButtonText="Submit"
          canSubmit={canSubmit}
          stepName="BankEntry"
        />
      </Constraint>
    </Form>
  );
}

LinkAccountManually.propTypes = {
  iavProvider: PropTypes.string,
  bankAccountError: PropTypes.string,
  detailLoading: PropTypes.bool,
  fetchBankDetails: PropTypes.func,
  isSaving: PropTypes.bool,
  onSubmit: PropTypes.func,
  program: PropTypes.string,
  resetBankAccountError: PropTypes.func,
  routingNumberError: PropTypes.string,
};

LinkAccountManually.defaultProps = {
  iavProvider: 'plaid',
  bankAccountError: undefined,
  detailLoading: false,
  fetchBankDetails: () => { },
  isSaving: false,
  onSubmit: () => { },
  program: undefined,
  resetBankAccountError: () => { },
  routingNumberError: undefined,
};
