import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function SavingsPage({ consumerHost }) {
  return (
    <Redirect to={`//${consumerHost}/account/savings`} />
  );
}

SavingsPage.propTypes = {
  consumerHost: PropTypes.string,
};

SavingsPage.defaultProps = {
  consumerHost: 'www.gasbuddy.com',
};
